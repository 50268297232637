import React, { useState, useEffect } from "react";
import {Box} from "@mui/material";
import Header from "../../components/Header.jsx";
import {BACKEND_BASE_URL} from "../../components/config/apiEnv.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { useNavigate } from "react-router-dom";


const AddProduct = () => {
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    const { token } = useUser();
    const [isSubmitting, setIsSubmitting] = useState(false);    
    const [formData, setFormData] = useState({
      name: '',
      serial_number: '',
      account_name: '',
      manufacturer: '',
      product_type: '',
      product_category: '',
      price: '',
      product_status: '',
      warrenty_startDate: '',
      warrenty_endDate: '',
      warrenty_period: '',
      assigned_to: '',
    });

    const headers = {
        Authorization: `Bearer ${token}`,
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
      };

    const handleSubmit = (e) => {
      setIsSubmitting(true);
      e.preventDefault();
      const requestData = {
        name: formData?.name,
        serial_number: formData?.serial_number,
        account_name: formData?.account_name,
        manufacturer: formData?.manufacturer,
        product_type: formData?.product_type,
        product_category: formData?.product_category,
        price: formData?.price,
        product_status: formData?.product_status,
        warrenty_startDate: formData?.warrenty_startDate,
        warrenty_endDate: formData?.warrenty_endDate,
        warrenty_period: formData?.warrenty_period,
        assigned_to: formData?.assigned_to,
      };

        axios
        .post(BACKEND_BASE_URL + `v1/product/product/`, requestData, { headers })
        .then((response) => {
            if (response.status === 201) {
                navigate("/product")
            setError(null);
            toast.success("Product created Successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            } else {
            toast.error("Error occured while displaying accountsfdfdsfds.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setIsSubmitting(false);
            }
        })
        .catch((error) => {
            toast.error(error.response.data?.error, {
            position: toast.POSITION.TOP_RIGHT,
            });
            setIsSubmitting(false);
        });
    };
  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Header title="Product" subtitle="welcome to you Team" />
      </Box>
      <div className="max-w-[800px] mx-auto mt-8 pb-14 p-6 bg-[#f2f0f0] rounded shadow-md">
      <h2 className="text-2xl font-semibold mb-6">Product Form</h2>
      <form onSubmit={handleSubmit}>
        {error && (
          <div style={{ color: 'red', marginTop: '8px' }}>
            {error}
          </div>
        )}
        <div className="mb-4">
          <label
            htmlFor="name"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Product Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="serial_number"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Serial Number
          </label>
          <input
            type="text"
            id="serial_number"
            name="serial_number"
            value={formData.serial_number}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="account_name"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Account Name
          </label>
          <input
            type="text"
            id="account_name"
            name="account_name"
            value={formData.account_name}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="manufacturer"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Manufacturer
          </label>
          <select
            id="manufacturer"
            name="manufacturer"
            value={formData.manufacturer}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          >
            <option value="">Select a manufacturer</option>
            <option value="Microsoft">Microsoft</option>
            <option value="Google">Google</option>
            <option value="Enjay">Enjay</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="product_type"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Product Type
          </label>
          <select
            id="product_type"
            name="product_type"
            value={formData.product_type}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          >
            <option value="">Select a type</option>
            <option value="Hardware">Hardware</option>
            <option value="Software">Software</option>
            <option value="Service">Service</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="product_category"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Product Category
          </label>
          <select
            id="product_category"
            name="product_category"
            value={formData.product_category}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          >
            <option value="">Select a category</option>
            <option value="CRM">CRM</option>
            <option value="Tally_cloud">Tally_cloud</option>
            <option value="Mobile_app">Mobile_app</option>
            <option value="Synapse">Synapse</option>
            <option value="Thin_client">Thin_client</option>
            <option value="Tiguin">Tiguin</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="price"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Price
          </label>
          <input
            type="text"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="product_status"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Product Status
          </label>
          <select
            id="product_status"
            name="product_status"
            value={formData.product_status}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          >
            <option value="">Select a status</option>
            <option value="Sold">Sold</option>
            <option value="AMC">AMC</option>
            <option value="Discontinue">Discontinue</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="warrenty_startDate"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Warranty Start Date
          </label>
          <input
            type="date"
            id="warrenty_startDate"
            name="warrenty_startDate"
            value={formData.warrenty_startDate}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="warrenty_endDate"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Warranty End Date
          </label>
          <input
            type="date"
            id="warrenty_endDate"
            name="warrenty_endDate"
            value={formData.warrenty_endDate}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="warrenty_period"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Warranty Period
          </label>
          <select
            id="warrenty_period"
            name="warrenty_period"
            value={formData.warrenty_period}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
          >
            <option value="">Select a period</option>
            <option value="6 Months">6 Months</option>
            <option value="1 Year">1 Year</option>
            <option value="2 Years">2 Years</option>
            <option value="3 Years">3 Years</option>
            <option value="Custom">Custom</option>
          </select>
        </div>
        <div className="mb-4">
          <label
            htmlFor="assigned_to"
            className="block text-gray-700 text-sm font-bold mb-2"
          >
            Assigned To
          </label>
          <input
            type="text"
            id="assigned_to"
            name="assigned_to"
            value={formData.assigned_to}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          />
        </div>
        <button
          type="submit"
          className={`bg-${error ? 'gray-300' : 'blue-500'} text-white px-4 py-2 rounded-md ${error ? 'cursor-not-allowed' : 'hover:bg-blue-600 focus:outline-none focus:bg-blue-600'}`}
          disabled={!!error && isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </form>
      </div>
    </Box>
  );
};

export default AddProduct;