import React, { createContext, useEffect, useState, useRef } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { DataGrid, GridToolbar, GridToolbarExport } from "@mui/x-data-grid";
import DataTable from "react-data-table-component";
import { tokens } from "../../theme.js";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { Link } from "react-router-dom";
import { FiFileText } from "react-icons/fi";
import { BsGraphDown } from "react-icons/bs";
import { GiNetworkBars } from "react-icons/gi";
import { GoDownload } from "react-icons/go";
import OpportunityLineChart from "./OpportunityLineChart.js";
import { toast } from "react-toastify";
import ReactApexChart from "react-apexcharts";
import { useCSN } from "../../components/CSNProvider.jsx";
import { useUser } from "../../components/UserProvider.js";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Typography,
  useTheme,
  Autocomplete,
  TextField,
  Modal,
} from "@mui/material";
import Loader from "../../components/Loader/Loader.js";


const MyContext = createContext();

const Opportunity = () => {
  const theme = useTheme();
  const { csn } = useCSN();
  const {user} = useUser()
  const colors = tokens(theme.palette.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState([]);
  const [opportunityData, SetOpportunityData] = useState([]);
  const [opportunityFilteredData, SetOpportunityFilteredData] = useState([]);
  const [loading, SetLoading] = useState(true);
  const [selectedOpportunity, SetselectedOpportunity] = useState(null);
  const [opportunityDataGraph, SetOpportunityDataGraph] = useState([]);
  const [opportunityAccountDataSecondGraph, SetOpportunityDataSecondGraph] = useState([]);
  const [opportunityBarChart, SetOpportunityBarChart] = useState([]);
  const [expiringcounts, SetExpiringOpportunityCounts] = useState();
  const [searchBranch, setSearchBranch] = useState(null);
  const [branches, setBranches] = useState([]);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [tab, setTab] = useState(1);
  const [filterText, setFilterText] = useState("");

  const { token } = useUser();

  const filteredData = opportunityData?.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(filterText.toLowerCase())
    )
  );
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleOpenModel = (oppn) => {
    setOpen(true);
    axios
      .get(BACKEND_BASE_URL + `v1/api/get_opportunity/${oppn}`, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          SetselectedOpportunity(response?.data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleTabChange = (val) => {
    setTab(val);
  };

  useEffect(() => {
    if (token) {
      GetExportOpportunity();
    }
  }, [csn, token]);

  const GetExportOpportunity = () => {
    setIsLoading(true)
    SetLoading(true)
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_export_opportunities/${
      csn ? `${csn}` : ""
    }`;
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetOpportunityBarChart(response.data.modified_data);
          SetOpportunityDataSecondGraph(response.data.account_name_counts);
          SetOpportunityDataGraph(response.data.retention_health_counts);
          SetExpiringOpportunityCounts(response.data.expiring_count);
          SetLoading(false);
          setIsLoading(false);
          SetOpportunityData(response.data.opportunity_data);
          SetOpportunityFilteredData(response.data.opportunity_data);
          setLastUpdated(response?.data?.last_updated)
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying opportunity.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setIsLoading(false);
        toast.error("Error occured while export Opportunity.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    if (token) {
      GetAllBranch();
    }
  }, [token]);

  const GetAllBranch = () => {
    axios
      .get(BACKEND_BASE_URL + `v1/api/get_all_branch/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data?.Branch.map((item) => {
            const name = item.branch_name;
            const value = item.id;
            return {
              label: name,
              value: value,
            };
          });
          setBranches(data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  const getRowId = (row) => row.opportunity_number;

  const columns = [
    {
      field: "opportunity_number",
      headerName: "oppn#",
      width: 150,
      renderCell: (params, index) => (
        <div>
          <button
            onClick={() => handleOpenModel(params?.row.opportunity_number)}
            className="action-button bg-white text-black px-3 py-1 rounded"
          >
            {params.value}
          </button>
        </div>
      ),
    },

    { field: "status", headerName: "Status", width: 100 },
    { field: "subscription_end_date", headerName: "Sub end date", width: 150 },
    { field: "total_quantity", headerName: "Quantity", width: 100 },
    { field: "contract_number", headerName: "Contract Number", width: 150 },
    {
      field: "ews_retention_health",
      headerName: "Rentention Health",
      width: 100,
    },
    { field: "reseller_name", headerName: "Reseller", width: 200 },
    { field: "account_name", headerName: "Account", width: 200 },
    ...(user.user_type !== "Client"
      ? [
    {
      field: "bd_person_name",
      headerName: "BD Person Name",
      width: 160,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    {
      field: "renewal_person_name",
      headerName: "Renewal Person Name",
      width: 160,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    }, ] : [] ),
    {
      field: "contract_manager_email",
      headerName: "Contract Msg Email",
      width: 220,
    },
  ];


  const secondgraphdata = Object.values(opportunityAccountDataSecondGraph);
  const secondgraphlabel = Object.keys(opportunityAccountDataSecondGraph);
  const AccountDataChart = {
    series: secondgraphdata,
    options: {
      chart: {
        width: 500,
        type: "pie",
      },
      labels: secondgraphlabel,
      responsive: [
        {
          breakpoint: 1140,
          options: {
            legend: {
              position: "bottom", 
            },
          },
        },
      ],
    },
  };
  

  const values = Object.values(opportunityDataGraph);
  const names = ["Very High", "Medium", "High", "Low", "Undefined"];

  const graphdata = [];
  
  for (let i = 0; i < values.length; i++) {
    graphdata.push({ name: names[i], value: values[i] });
  }
  
  const ChartData = {
    series: graphdata.map((item) => item.value),
    options: {
      chart: {
        width: 380,
        type: "pie",
        events: {
          click(event, chartContext, config) {
            if (
              config.config.series &&
              config.config.series[config.seriesIndex] &&
              config.config.series[config.seriesIndex].data &&
              config.config.series[config.seriesIndex].data[
                config.dataPointIndex
              ]
            ) {
              const value =
                config.config.series[config.seriesIndex].data[
                  config.dataPointIndex
                ];
              const dataPoint =
                config.config.series[config.seriesIndex].data[
                  config.dataPointIndex
                ];

              if (dataPoint.label) {
                const label = dataPoint.label;
              } else {
                console.log("Label for the clicked segment is not available.");
              }
            } else {
              console.log("Data for the clicked segment is not available.");
            }
          },
        },
      },
      labels: graphdata.map((item) => item.name), 
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };
  console.log("🚀 ~ Opportunity ~ ChartData:", ChartData)

  const categories = opportunityBarChart?.map((item) => item.label) || [];
  const dataValues = opportunityBarChart?.map((item) => item.value) || [];

  const LineChartData = {
    series: [
      {
        name: "Desktops",
        data: dataValues,
      },
    ],
    options: {
      chart: {
        type: "line",
        width: "100%",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: categories,
      },
    },
  };

  const handleLinkClick = (params) => {
    SetLoading(true);
    axios
      .get(
        BACKEND_BASE_URL + `v1/api/get_export_opportunities?${params}=true`,
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          SetOpportunityBarChart(response.data.modified_data);
          SetOpportunityDataSecondGraph(response.data.account_name_counts);
          SetOpportunityDataGraph(response.data.retention_health_counts);
          SetLoading(false);
          SetOpportunityData(response.data.opportunity_data);
          SetOpportunityFilteredData(response.data.opportunity_data);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying opportunity.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        toast.error("Error occured while export Opportunity.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const chartRef = useRef();

  const handleChartClick = (e, chartContext) => {
    if (chartContext) {
      const { series, dataPointIndex } = chartContext.config;
      const clickedData = series[dataPointIndex];
      console.log("Clicked Data:", clickedData);
    }
  };

  const handleBranchFilter = (branch) => {

    if (branch?.label) {
      const filteredResults = opportunityFilteredData.filter((item) => {
        const name = item["branch"];
        return name === branch.label;
      });
      SetOpportunityData(filteredResults);
    } else {
      GetExportOpportunity();
    }
  };

  const formatLastUpdated = (timestamp) => {
    if (!timestamp) return 'Not Available';
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const formattedDate = formatLastUpdated(lastUpdated);

  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  return (
    <>
      <MyContext.Provider value={{ opportunityBarChart }}>
        <OpportunityLineChart />
      </MyContext.Provider>
      <Box m="20px">
        <Box display="">
          <Box>
            <Header
              title="Opportunity Dashboard"
              subtitle="Use of PWS Oppportunity Export API data to show details below"
            />
          </Box>
          <Box className="flex items-start gap-3 justify-end">
          <div className="relative group">
            <span className="text-gray-600">Last Updated</span>
              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-max px-4 py-2 bg-black text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                {formattedDate}
              </div>
          </div>
              <button
                onClick={() => GetExportOpportunity()}
                className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] w-[100px] text-center, text-lg"
              >
                All
              </button>
              <Box
                display="flex"
                marginBottom={2}
                justifyContent="end"
                alignItems="center"
              >
                {branches ? (
                  <Autocomplete
                    value={searchBranch}
                    onChange={(event, newValue) => {
                      setSearchBranch(newValue);
                      handleBranchFilter(newValue);
                    }}
                    options={branches}
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select a Branch"
                        variant="outlined"
                      />
                    )}
                  />
                ) : (
                  // <CircularProgress />
                  <Autocomplete
                    value={null} 
                    disabled 
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select a Branch"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <Typography variant="body2" color="textSecondary">
                                Loading...
                              </Typography>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </Box>
            
          </Box>
        </Box>
        <Box>
          <>
            <div className="flex gap-[61px]">
              <div
                className="p-3 bg-[#ff6a6a40] min-h-[70px] rounded-[3px] w-[25%] cursor-pointer"
                onClick={() => handleLinkClick("week")}
              >
                <a className="text-[#b31807b5] text-[16px] hover:decoration-[hsl(242deg_88.4%_45.3%_/_76%)]">
                  Oppn.expiring by this week -{" "}
                  {expiringcounts?.expiring_this_week_count}
                </a>
              </div>
              <div
                className="p-3 bg-[#f2bd20a9] min-h-[70px] rounded-[3px] w-[25%] cursor-pointer"
                onClick={() => handleLinkClick("month")}
              >
                <a className="text-[#916308] text-[16px] hover:decoration-[hsl(242deg_88.4%_45.3%_/_76%)]">
                  Oppn.expiring by this month -{" "}
                  {expiringcounts?.expiring_this_month_count}
                </a>
              </div>
              <div
                className="p-3 bg-[#2178f161] min-h-[70px] rounded-[3px] w-[25%] cursor-pointer"
                onClick={() => handleLinkClick("next_month")}
              >
                <a className="text-[#3b6dc4] text-[16px] hover:decoration-[hsl(242deg_88.4%_45.3%_/_76%)]">
                  Oppn.expiring in next month -{" "}
                  {expiringcounts?.expiring_next_month_count}
                </a>
              </div>
              <div
                className="p-3 bg-[#17c34529] min-h-[70px] rounded-[3px] w-[25%] cursor-pointer"
                onClick={() => handleLinkClick("next_three_month")}
              >
                <a className="text-[#05340ab0] text-[16px] hover:decoration-[hsl(242deg_88.4%_45.3%_/_76%)]">
                  Oppn.expiring today till next 3 months -{" "}
                  {expiringcounts?.expiring_next_3_months_count}
                </a>
              </div>
            </div>

            
            <Grid container sx={{ marginBottom: 3 }} spacing={3}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    border: "0.8px solid",
                    marginTop: 3,
                    borderColor: colors.grey[900],
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors.primary[400],
                      borderBottom: "0.8px solid",
                      borderColor: colors.grey[900],
                      padding: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "600" }}
                      component="h5"
                    >
                      All User Product Seat data
                    </Typography>
                  </Box>
                  <div className="p-[15px] ">
                    <div className="flex justify-end gap-2">
                      <FiFileText
                        size={12}
                        className="text-[#9797a5] w-[15px] h-[15px]"
                      />
                      <BsGraphDown
                        size={12}
                        className="text-[#9797a5] w-[15px] h-[15px]"
                      />
                      <GiNetworkBars
                        size={12}
                        className="text-[#9797a5] w-[15px] h-[15px]"
                      />
                      <GoDownload
                        size={12}
                        className="text-[#9797a5] w-[15px] h-[15px]"
                      />
                    </div>

                    <div>
                      <Box height="250px" m="-20px 0 0 0">
                        <Box id="chart">
                          {loading ? (
                            <p>Loading data...</p>
                          ) : (
                            <ReactApexChart
                              options={LineChartData?.options}
                              series={LineChartData?.series}
                              type="line"
                              height={250}
                            />
                          )}
                        </Box>
                      </Box>
                    </div>
                  </div>
                </Box>
              </Grid>
              <Grid item xs={12} xl={6}>
                <Box
                  sx={{
                    border: "0.8px solid",

                    borderColor: colors.grey[900],
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors.primary[400],
                      borderBottom: "0.8px solid",
                      borderColor: colors.grey[900],
                      padding: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "600" }}
                      component="h5"
                    >
                      Oppen. by Retention health
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      height: "320px",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    id="chart"
                  >
                    {loading ? (
                      <p>Loading data...</p>
                    ) : (
                      <ReactApexChart
                        options={ChartData?.options}
                        series={ChartData?.series}
                        type="pie"
                        width={380}
                      />
                    )}
                  </Box>

                </Box>
              </Grid>
              <Grid item xs={12} xl={6}>
                <Box
                  sx={{
                    border: "0.8px solid",

                    borderColor: colors.grey[900],
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: colors.primary[400],
                      borderBottom: "0.8px solid",
                      borderColor: colors.grey[900],
                      padding: 2,
                    }}
                  >
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: "600" }}
                      component="h5"
                    >
                      Oppen. by Account name
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      height: "320px",
                      alignItems: "center",
                      justifyContent: "center",
                      overflow: 'auto'
                    }}
                    id="chart"
                  >
                    {loading ? (
                      <p>Loading data...</p>
                    ) : (
                      <ReactApexChart
                        options={AccountDataChart?.options}
                        series={AccountDataChart?.series}
                        type="pie"
                        width={750}
                        height={500}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </>
        </Box>
        <Box
          m="8px 0 0 0"
          width="100%"
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <input
            type="text"
            placeholder="Search by Name"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          {loading ? (
            <p>Loading data...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <DataGrid
              rows={opportunityData}
              columns={columns}
              getRowId={getRowId}
              components={{
                Toolbar: () => (
                  <div>
                    <GridToolbar
                    csvOptions={{
                      fileName: `oppn_trisita_${user?.first_name} ${user?.last_name}_${currDate}_${currTime}`,
                      fields: ['opportunity_number', 'status', 'subscription_end_date', 'total_quantity', 'contract_number', 'ews_retention_health', 'reseller_name', 'account_name', 'user_assign', 'renewal_person', 'branch', 'contract_manager_email'],
                    }} />
                  </div>
                )
              }}
              checkboxSelection
              disableRowSelectionOnClick
            />
          )}
        </Box>
      </Box>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{ backgroundColor: "red" }}
      >
        {/* <div className="absolute -translate-x-2/4 bg-white -translate-y-2/4 w-[500] rounded-lg shadow-[24] p-4 left-2/4 top-[18%]"> */}
        <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[500px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
          <div className="h-full">
            <div className="text-black flex border-b border-gray-300">
              <Link
                to={"#"}
                className={`${
                  tab === 1
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(1)}
              >
                End Customer
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 2
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(2)}
              >
                Reseller
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 3
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(3)}
              >
                Distributor
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 4
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(4)}
              >
                Contract
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 5
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(5)}
              >
                Line Items
              </Link>
            </div>
            <div className="mt-5"></div>
            <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">
              {tab === 1 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    End Customer Detiails
                  </h1>
                  {selectedOpportunity ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]">Opportunity#</div>
                          <div className="whitespace-pre">
                            {selectedOpportunity?.message[0].opportunity_number}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">CSN</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].accounts
                                .end_customer.account.csn
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity.message[0].accounts
                                .end_customer.account.name
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Address Line 1</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity.message[0].accounts
                                .end_customer.account.address_line_1
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Address Line 2</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity.message[0].accounts
                                .end_customer.account.address_line_2
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">City</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity.message[0].accounts
                                .end_customer.account.city
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">State</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity.message[0].accounts
                                .end_customer.account.state
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Country</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity.message[0].accounts
                                .end_customer.account.country
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Postal</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity.message[0].accounts
                                .end_customer.account.postal
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Contract Manager Details
                  </h1>
                  {selectedOpportunity ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]">First Name</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].accounts
                                .end_customer.contract_manager.first
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Last Name</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].accounts
                                .end_customer.contract_manager.last
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Email</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].accounts
                                .end_customer.contract_manager.email
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Phone</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].accounts
                                .end_customer.contract_manager.phone
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 2 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Reseller Details
                  </h1>
                  {selectedOpportunity ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]"> Opportunity#</div>
                          <div className="whitespace-pre">
                            {selectedOpportunity?.message[0].opportunity_number}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">CSN</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].accounts.reseller
                                .csn
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].accounts.reseller
                                .name
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 3 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Distributor Details
                  </h1>
                  {selectedOpportunity ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]"> Opportunity#</div>
                          <div className="whitespace-pre">
                            {selectedOpportunity?.message[0].opportunity_number}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">CSN</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].accounts.sold_to
                                .csn
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].accounts.sold_to
                                .name
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 4 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Contract Details
                  </h1>
                  {selectedOpportunity ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]"> Opportunity#</div>
                          <div className="whitespace-pre">
                            {selectedOpportunity?.message[0].opportunity_number}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Contract#</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].contract
                                .contract_number
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Type</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].contract
                                .contract_type
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Term</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].contract
                                .contract_term
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Duration</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].contract
                                .contract_duration
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Retention Health</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0]
                                .ews_retention_health
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Sub end date</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0]
                                .subscription_end_date
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Total Quantity</div>
                          <div className="whitespace-pre">
                            {selectedOpportunity?.message[0].total_quantity}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">
                            Total Renewed Quantity
                          </div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0]
                                .total_renewed_quantity
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 5 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    {" "}
                    Line Items 1 Details{" "}
                  </h1>
                  {selectedOpportunity ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]"> Serial#</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].line_items[0]
                                .serial_number
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Renewal SKU</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].line_items[0]
                                .renewal_sku
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">SKU Description</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].line_items[0]
                                .sku_description
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Quantity</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].line_items[0]
                                .quantity
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Seats</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].line_items[0]
                                .seats
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Renewed Quantity</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].line_items[0]
                                .renewed_quantity
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Deployment</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].line_items[0]
                                .deployment
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Status</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].line_items[0]
                                .status
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Asset Status</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].line_items[0]
                                .asset_status
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Support Program</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].line_items[0]
                                .support_program
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Program Eligibility</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].line_items[0]
                                .program_eligibility
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Renew</div>
                          <div className="whitespace-pre">
                            {
                              selectedOpportunity?.message[0].line_items[0]
                                .renew
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
            </div>
            <div className="border-t">
              <div className="pt-4 text-right">
                <button
                  className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                  onClick={() => {
                    setOpen(false);
                    SetselectedOpportunity(null);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      {
        isLoading && <Loader />
      }
    </>
  );
};

export default Opportunity;
export { MyContext };
