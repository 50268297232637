import React, { useEffect, useState, useRef } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { Autocomplete, Box, Input, InputBase, TextField, Typography, useTheme, Modal} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from "axios";
import { useCSN } from "../../components/CSNProvider.jsx";
import { toast } from "react-toastify";
import ReactApexChart from 'react-apexcharts';
import CircularProgress from '@mui/material/CircularProgress';
import { Link } from "react-router-dom";


import {
  MenuItem,
  Select,
} from "@mui/material";
import { useUser } from "../../components/UserProvider.js";
import Loader from "../../components/Loader/Loader.js";
import ExportToExcel from "../../components/ExportToExcel.js";

const InsightMatricsV2 = () => {
  const {csn} = useCSN()
  const [customerData, SetCustomerData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState([]);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(1);  
  const [selectedInsightContract, SetselectedInsightContract] = useState(null);
  const [DoubleFilter, setDoubleFilter] = useState([]);

  const [subcustomerTableData, SetCustomerTableData] = useState([]);
  const [originalCustomerData, setOriginalCustomerData] = useState([]);

  const [subcustomerAllData, SetCustomerAllData] = useState([]);
  const [searchedData, setSearchedData] = useState([]);
  const [branchfilteredData, setBranchFilteredData] = useState([]);
  
  
  const [productlinecount, SetProductLineCount] = useState();
  const [productlineproratedcount, SetProductLineProratedCount] = useState();
  
  
  const [riskcategory, SetRiskCategory] = useState();
  const [loading, SetLoading] = useState(true);

  const [filteredResultss, setFilteredResults] = useState([]);
  
  const [error, setError] = useState(null);
  const [accountsname, setAccountsName] = useState(null)
  const [tableData, setTableData] = useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [risKColor, setRisKColor] = useState(null);
  const [status, setStatus] = React.useState("All Status");
  const [selectedValue, setSelectedValue] = useState([]);
  const [searchBranch, setSearchBranch] = useState(null);
  const [selectedSubscription, setSelectedSubscription] = useState(null);

  const [branches, setBranches] = useState([]);


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  const {token, user} = useUser()

  const headers = {
    "Authorization": `Bearer ${token}`
	}
  const [filteredProductLineCount, setFilteredProductLineCount] = useState({});

  const handleOpenModel = (insight_id ,contract_id) => {
    setOpen(true);
    axios.get(BACKEND_BASE_URL + `v1/api/get_insight_metrics_contract/${insight_id}/${contract_id}`, {headers})
      .then((response) => {
        if (response.status === 200) {
          SetselectedInsightContract(response?.data);
          setSelectedSubscription(response?.data[0])
          SetLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }else {
            toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    if (token){
    GetAllBranch();
    }
  }, [token]);


  const GetAllBranch = () => {
    setStatus("All Status");
    axios.get(BACKEND_BASE_URL + `v1/api/get_all_branch/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data?.Branch.map(item => {
            const name = item.branch_name;
            const value = item.id;
            return {
              label: name,
              value: value,
            };
          });
          setBranches(data)
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    if (risKColor) {
      if (risKColor === "Unknown"){
        const filteredData2 = originalCustomerData.filter(item => {
          return item['riskCategory'] === "";
        });
      SetCustomerData(filteredData2);
      SetCustomerTableData(filteredData2)
      setFilteredResults(filteredData2)
      }
      else{
        const filteredData2 = originalCustomerData.filter(item => {
        return item['riskCategory'] === risKColor;
      });
      SetCustomerData(filteredData2);
      SetCustomerTableData(filteredData2)
      setFilteredResults(filteredData2)
      }
    }
  }, [risKColor]);
  
  
  useEffect(() => {
  
    const counts = filteredResultss?.reduce((accumulator, item) => {
      const { productLineCode, riskCategory, seatsPurchased, usersAssigned, seatsInUse } = item;
  
  
      if (!accumulator[productLineCode]) {
        accumulator[productLineCode] = {
          seats_purchased_count: 0,
          users_assigned_count: 0,
          seats_in_use_count: 0,
        };
      }
  
      if (usersAssigned !== null && seatsInUse !== null) {
        accumulator[productLineCode].seats_purchased_count += parseInt(seatsPurchased) || 0;
        accumulator[productLineCode].users_assigned_count += parseInt(usersAssigned) || 0;
        accumulator[productLineCode].seats_in_use_count += parseInt(seatsInUse) || 0;
      }

  
      return accumulator;
    }, {});


    SetProductLineCount(counts);


    const prorated = filteredResultss?.reduce((accumulator, item) => {
      const { productLineCode, seatsPurchased, usersAssigned, seatsInUse, usersAssignedProRated, seatsInUseProRated, assignmentRate, utilisationRate} = item;
      if (!accumulator[productLineCode]) {
        accumulator[productLineCode] = {
          seats_purchased_count: 0,
          users_assigned_prorated: 0,
          seats_in_use_prorated: 0,
        };
      }
    
      if (usersAssigned === null || seatsInUse === null) {
        const calculatedUsersAssigned = (parseInt(assignmentRate) * parseInt(seatsPurchased)) / 100;
        const calculatedseatsInUse = (parseInt(utilisationRate) * parseInt(seatsPurchased)) / 100;
        accumulator[productLineCode].seats_purchased_count += parseInt(seatsPurchased);
        accumulator[productLineCode].users_assigned_prorated += calculatedUsersAssigned || 0;
        accumulator[productLineCode].seats_in_use_prorated +=calculatedseatsInUse || 0 ;
      }
  
      return accumulator;
    }, {});

    const prorated_chart_data = Object.fromEntries(
      Object.entries(prorated).filter(([productLineCode, data]) =>
      parseInt(data.users_assigned_prorated) !== 0 || parseInt( data.seats_in_use_prorated) !== 0
      )
    );

      
    Object.keys(prorated_chart_data).forEach(productLineCode => {
      prorated_chart_data[productLineCode].users_assigned_prorated = parseFloat(prorated_chart_data[productLineCode].users_assigned_prorated)?.toFixed(2);
      prorated_chart_data[productLineCode].seats_in_use_prorated = parseFloat(prorated_chart_data[productLineCode].seats_in_use_prorated).toFixed(2);
    });
    
    SetProductLineProratedCount(prorated_chart_data);

  }, [filteredResultss]);
  
  const categories = productlinecount ? Object.keys(productlinecount) : [];

  const options = {
    chart: {
      height: 350,
      type: 'bar',
    },
    xaxis: {
      labels: {
      },
      categories: categories,
    },
    yaxis: {
      title: {
        text: 'Count',
      },
    },
    colors: ['#007BFF', '#28A745', '#FFC107'],
    plotOptions: {
      bar: {
        borderRadius: 0,
        columnWidth: '80%',
      },
    },
  };

  const series = [
    {
      name: 'Seats Purchased',
      data: categories.map(category => (productlinecount[category] ? productlinecount[category].seats_purchased_count : 0)),
    },
    {
      name: 'User Assigned',
      data: categories.map(category => (productlinecount[category] ? productlinecount[category].users_assigned_count : 0)),
    },
    {
      name: 'Seats In Use',
      data: categories.map(category => (productlinecount[category] ? productlinecount[category].seats_in_use_count : 0)),
    },
  ];

  const prorated_categories = productlineproratedcount ? Object.keys(productlineproratedcount) : [];

  const prorated_options = {
    chart: {
      height: 350,
      type: 'bar',
    },
    xaxis: {
      labels: {
        rotate: -45,
      },
      categories: prorated_categories,
    },
    yaxis: {
      title: {
        text: 'Count',
      },
    },
    colors: ['#007BFF', '#28A745', '#FFC107'],
    plotOptions: {
      bar: {
        borderRadius: 0,
        columnWidth: '80%',
      },
    },
  };

  const prorated_series = [
    {
      name: 'Seats Purchased',
      data: prorated_categories.map(category => (productlineproratedcount[category] ? productlineproratedcount[category].seats_purchased_count : 0)),
    },
    {
      name: 'User Assigned ProRated',
      data: prorated_categories.map(category => (productlineproratedcount[category] ? productlineproratedcount[category].users_assigned_prorated : 0)),
    },
    {
      name: 'Seats In Use ProRated',
      data: prorated_categories.map(category => (productlineproratedcount[category] ? productlineproratedcount[category].seats_in_use_prorated : 0)),
    },
  ];

  const risk_category = riskcategory ? Object.keys(riskcategory) : [];

  const risk_series = [
    {
      name: "contracts",
      data: riskcategory ? Object.entries(riskcategory).map(([category, value]) => value) : [],
    },
  ];

  const handleBarClick = (selectedColor) => {
    let updatedColor = selectedColor;
    if (selectedColor === "Unknown") {
      updatedColor = "";
    }

    setRisKColor(selectedColor)
  }
  
  const risk_options = {
    chart: {
      type: "bar",
      height: 350,
      width: "100%",
      events: {
        click(event, chartContext, config) {
            const selectedColor = risk_category[config.dataPointIndex];
            let updatedColor = selectedColor;
            if (updatedColor) {
              handleBarClick(updatedColor)
            }
            else{
              console.log("no click data")
            }
        }
      },
      
    },

    xaxis: {
      categories: risk_category,
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    dataLabels: {
      position: "top",
    },
  };

  useEffect(() => {
    const riskCounts = {
      Green: 0,
      Yellow: 0,
      Red: 0,
      Unknown: 0,
    };

    
      const Green = originalCustomerData?.filter((item) => {
        return item['riskCategory'] === "Green";
      }); 
      const Yellow = originalCustomerData?.filter((item) => {
        return item['riskCategory'] === "Yellow";
      });
      const Red = originalCustomerData?.filter((item) => {
        return item['riskCategory'] === "Red";
      });
      const Unknown = originalCustomerData?.filter((item) => {
        return item['riskCategory'] === "";
      });
      const onboardall = {};
      onboardall["Green"] = Green || [];
      onboardall["Yellow"] = Yellow || [];
      onboardall["Red"] = Red || [];
      onboardall["Unknown"] = Unknown || [];
      setTableData(Green)


    const counts = originalCustomerData?.reduce((accumulator, item) => {
      const { productLineCode, riskCategory, seatsPurchased, usersAssigned, seatsInUse } = item;
  
      if (riskCategory === "Green") {
        riskCounts.Green++;
      } else if (riskCategory === "Yellow") {
        riskCounts.Yellow++;
      } else if (riskCategory === "Red") {
        riskCounts.Red++;
      } else {
        riskCounts.Unknown++;
      }
  
      if (!accumulator[productLineCode]) {
        accumulator[productLineCode] = {
          seats_purchased_count: 0,
          users_assigned_count: 0,
          seats_in_use_count: 0,
        };
      }
  
      if (usersAssigned !== null && seatsInUse !== null) {
        accumulator[productLineCode].seats_purchased_count += parseInt(seatsPurchased) || 0;
        accumulator[productLineCode].users_assigned_count += parseInt(usersAssigned) || 0;
        accumulator[productLineCode].seats_in_use_count += parseInt(seatsInUse) || 0;
      }

  
      return accumulator;
    }, {});

    SetRiskCategory(riskCounts);
    SetProductLineCount(counts);


    const prorated = originalCustomerData?.reduce((accumulator, item) => {
      const { productLineCode, seatsPurchased, usersAssigned, seatsInUse, usersAssignedProRated, seatsInUseProRated, assignmentRate, utilisationRate } = item;
  
      if (!accumulator[productLineCode]) {
        accumulator[productLineCode] = {
          seats_purchased_count: 0,
          users_assigned_prorated: 0,
          seats_in_use_prorated: 0,
        };
      }
    
      if (usersAssigned === null || seatsInUse === null) {
        const calculatedUsersAssigned = (parseFloat(assignmentRate) * parseInt(seatsPurchased)) / 100;
        const calculatedseatsInUse = (parseFloat(utilisationRate) * parseInt(seatsPurchased)) / 100;
        accumulator[productLineCode].seats_purchased_count += parseInt(seatsPurchased);
        accumulator[productLineCode].users_assigned_prorated += calculatedUsersAssigned || 0;
        accumulator[productLineCode].seats_in_use_prorated +=calculatedseatsInUse || 0 ;
      }
  
      return accumulator;
    }, {});


    const prorated_chart_data = Object.fromEntries(
      Object.entries(prorated).filter(([productLineCode, data]) =>
        data.users_assigned_prorated !== 0 || data.seats_in_use_prorated !== 0
      )
    );


    Object.keys(prorated_chart_data).forEach(productLineCode => {
      prorated_chart_data[productLineCode].users_assigned_prorated = parseFloat(prorated_chart_data[productLineCode].users_assigned_prorated)?.toFixed(2);
      prorated_chart_data[productLineCode].seats_in_use_prorated = parseFloat(prorated_chart_data[productLineCode].seats_in_use_prorated).toFixed(2);
    });
    SetProductLineProratedCount(prorated_chart_data);

  }, [originalCustomerData]);


  useEffect(() => {
    if (token){
    handleClear()
		getAllCustomer();
  }
	}, [csn, token ]);

  const getAllCustomer = (page) => {
    setSearchTerm("")
    setStatus("All Status");
    setSelectedValue([]);
    setSearchBranch(null);
    SetLoading(true)
    setIsLoading(true)
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_insights_metrics_v2_customer/${csn ? `${csn}` : ''}`;
		axios
    .get(apiUrl, { headers })
    .then((response) => {
        if(response.status === 200) {
          SetCustomerData(response.data.response_data);
          SetCustomerAllData(response?.data?.response_data);
          SetCustomerTableData(response?.data.response_data);
          setOriginalCustomerData(response.data.response_data)
          setFilteredResults(response.data.response_data)
          setSearchedData(response.data.response_data)
          setBranchFilteredData(response.data.response_data)
          setLastUpdated(response?.data?.last_updated)
          setDoubleFilter(null);
          SetLoading(false);
          setIsLoading(false)
          setError(null);

          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          toast.error(response.data.Response.error.message, {
            position: toast.POSITION.TOP_RIGHT
          })
        }
      }).catch((error) => {
        SetCustomerData([]);
        SetCustomerAllData([]);
        SetCustomerTableData([]);
        setOriginalCustomerData([]);
        setSearchedData([]);
        setIsLoading(false)
        setError(error.response.data.Response ? error.response.data.Response.error.message : error.response.data.error);
        SetLoading(false);
        toast.error(error.response.data.Response ? error.response.data.Response.error.message : error.response.data.error, {
          position: toast.POSITION.TOP_RIGHT
        })
      });
	}

  const getAccountsData = () => { 
    axios.get(BACKEND_BASE_URL + `v1/api/get_export_accounts/`, {headers})
    .then((response) => {
      if (response.status === 200) {
        const data = response.data.accounts.map(item => {
          const name = item.name;
          const csn = item.csn;
          return {
            label: name,
            csn: csn,
          };
        });
        const uniqueLabels = new Set();

        const uniqueData = data.filter(item => {
          if (!uniqueLabels.has(item.label)) {
            uniqueLabels.add(item.label);
            return true;
          }
          return false;
        });
        setAccountsName(uniqueData)
      }
    })
   };
  useEffect(() => {
    if (token){
    getAccountsData()
    }
  }, [token])  

  const getRowId = (row) => row.id;
  const columns = [
    
    { field: "id", headerName: "Serial No.", width: 120,
    renderCell: (params, index) => (
      (
        <div>
          <button
            onClick={() => handleOpenModel(params?.row.id, params?.row.contractNumber)}
            className="action-button bg-white text-black px-3 py-1 rounded"
          >
            {params.value}
          </button>
        </div>
      )
    ),},
    // { field: "contractNumber", headerName: "Contract#", width: 150},
    { field: "customerCSN", headerName: "customerCSN", width: 150},
    ...(user.user_type !== "Client"
      ? [
    // { field: "bd_person", headerName: "BD Person Name", width: 200 },
    // { field: "renewal_person", headerName: "Renewal Person Name", width: 200 }, 
    {
      field: "bd_person",
      headerName: "BD Person Name",
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    {
      field: "renewal_person",
      headerName: "Renewal Person Name",
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    ]: []),
    { field: "Account", headerName: "Account", width: 200,
      renderCell: (params) => {
        const { value: Account } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {Account?.length > maxChars ? Account : Account?.slice(0, maxChars)}
          </div>
        );
        },
    },
    { field: "AccountStatus", headerName: "Autodesk Account Status", width: 120 },
    { field: "contract_status", headerName: "Trisita Account Status", width: 120 },
    { field: "subs_end_date", headerName: "Subs End Date", width: 120 },
    {
      field: "branch",
      headerName: "Branch",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    { field: "productLineCode", headerName: "productLineCode" , width: 200},
    { field: "seatsPurchased", headerName: "seatsPurchased" , width: 200},
    { field: "usersAssigned", headerName: "usersAssigned", width: 200},
    { field: "seatsInUse", headerName: "seatsInUse", width: 200},
    // { field: "usersAssignedProRated", headerName: "usersAssignedProRated", width: 200},
    // { field: "seatsInUseProRated", headerName: "seatsInUseProRated", width: 200},
    { field: "assignmentRate", headerName: "assignmentRate", width: 200},
    { field: "utilisationRate", headerName: "utilisationRate", width: 200},
    { field: "usageRate", headerName: "usageRate", width: 200},
    { field: "riskCategory", headerName: "riskCategory", width: 200},
    { field: "engagementCategory", headerName: "engagementCategory", width: 200},
    { field: "tenantId", headerName: "tenantId", width: 200},
  ];


  const handleSearch = (values) => {
    console.log("🚀 ~ handleSearch ~ values:", values)
    setSearchTerm(values);

    // if (value) {
    //   const filteredResults =  subcustomerAllData.filter((item) => {
    //     const name = item['Account'];
    //     return name && name.toLowerCase().includes(value.label.toLowerCase());
    //   });
    if (values.length > 0) {
      const filteredResults = subcustomerAllData?.filter((item) => {
        const name = item["Account"];
        return values.some(value => name && name.toLowerCase().includes(value.label.toLowerCase()));
      });
      console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ filteredResults ~ filteredResults:", filteredResults)

      SetCustomerTableData(filteredResults);
      setOriginalCustomerData(filteredResults);
      setSearchedData(filteredResults);
      setBranchFilteredData(filteredResults)
      setDoubleFilter(filteredResults);
      setStatus("All Status")
      
      const productLineCodesSet = new Set(filteredResults.map((item) => item['productLineCode']));
      const productLineCodes = Array.from(productLineCodesSet);
      setFilteredResults(productLineCodes);
    }
    else {
      setStatus("All Status")
      getAllCustomer()
      setDoubleFilter(null);
    }
  };

  const handleBranchFilter = (branch) => {

    if (branch?.label) {
      const filteredResults =  searchedData.filter((item) => {
        const name = item["branch"];
          return name === branch.label;
      });
      SetCustomerTableData(filteredResults);
      setOriginalCustomerData(filteredResults);
      setBranchFilteredData(filteredResults)
      setStatus("All Status")

      const productLineCodesSet = new Set(filteredResults.map((item) => item['productLineCode']));
      const productLineCodes = Array.from(productLineCodesSet);
      setFilteredResults(productLineCodes);

    }
    else {
      setStatus("All Status")
      handleSearch(searchTerm);
    }
    
  };

  const filterData = (data) => {
    return data.filter((row) => {
      return Object.values(row).some(
        (value) => {
          if(searchTerm?.label){
            return value && value.toString().toLowerCase().includes(searchTerm?.label?.toLowerCase())
          }else{
            return value
          }
        }
        );
      });
    };


  const filteredData = filterData(subcustomerTableData);


  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setStatus(selectedValue);
  
    if (selectedValue) {
  
      if (selectedValue === 'All Status') {
        handleBranchFilter(searchBranch);
      }
      else{
        const filteredResults =  branchfilteredData?.filter((item) => (item['contract_status'] === selectedValue))
        SetCustomerTableData(filteredResults);
        setOriginalCustomerData(filteredResults);
        
        const productLineCodesSet = new Set(filteredResults.map((item) => item['productLineCode']));
        const productLineCodes = Array.from(productLineCodesSet);
        setFilteredResults(productLineCodes);
      }
  
    } else {
      getAllCustomer();
    }
  };


  const handleClear = () => {
    setSelectedValue(null);
  };

  const handleTabChange = (val) => {
    setTab(val);
  };

  const formatLastUpdated = (timestamp) => {
    if (!timestamp) return 'Not Available';
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const formattedDate = formatLastUpdated(lastUpdated);


  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  const processedData = filteredData?.map((item) => ({
    ...item,
    bd_person: item.bd_person_name ? item.bd_person_name.join(', ') : '',
    renewal_person: item.renewal_person_name ? item.renewal_person_name.join(', ') : '',
  }));
  console.log("🚀 ~ processedData ~ processedData:", processedData)

  return (
    <>
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="User Insight Metrics"  />
      </Box>
      <div className="text-right mb-5 mt-5 gap-3  flex justify-end">
          <div className="relative group">
            <span className="text-gray-600">Last Updated</span>
              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-max px-4 py-2 bg-black text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                {formattedDate}
              </div>
          </div>
      <button
            onClick={() => getAllCustomer()}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] w-[100px] text-center, text-lg"
          >
            All 
          </button>
      <Box  display="flex" marginBottom={2} justifyContent="end" alignItems="center">
            {branches ?  (
              <Autocomplete
                value={searchBranch}
                onChange={(event, newValue) => {
                  setSearchBranch(newValue);
                  handleBranchFilter(newValue);
                }}
                options={branches}
                autoSelect
                getOptionLabel={(option) => option.label}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select a Branch" variant="outlined" />
                )}
              />
            ) :(
              // <CircularProgress />
              <Autocomplete
                value={null} 
                disabled
                autoSelect
                getOptionLabel={(option) => option.label}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Branch"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          <Typography variant="body2" color="textSecondary">
                            Loading...
                          </Typography>
                        </>
                      ),
                    }}
                  />
                )}
              />
            
            )
          }
      </Box>

      <Box
          sx={{ ml:  { md:2}  ,width:'200px' }}>
            <Select
              value={status}
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleChange}
              sx={{ width: "100%", height: "52px" }}
              SelectDisplayProps={{
                style: { paddingTop: 8, paddingBottom: 8 },
              }}
            >
              <MenuItem value={"All Status"}>All Status</MenuItem>
              <MenuItem value={"Active"}>Active</MenuItem>
              <MenuItem value={"Expired"}>Expired</MenuItem>
            </Select>
          </Box>
      
      {/* <Box  display="flex" marginBottom={2} justifyContent="end" alignItems="center">
      {accountsname ?  (
        <Autocomplete
          value={selectedValue}
          onChange={(event, newValue) => {
            setSelectedValue(newValue);
            handleSearch(newValue);
          }}
          options={accountsname}
          autoSelect
          getOptionLabel={(option) => option.label}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Select an Account" variant="outlined" />
          )}
        />
        ) :(
          // <CircularProgress />
          <Autocomplete
            value={null}
            disabled
            autoSelect
            getOptionLabel={(option) => option.label}
            sx={{ width: 300 }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select an Account"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {params.InputProps.endAdornment}
                      <Typography variant="body2" color="textSecondary">
                        Loading...
                      </Typography>
                    </>
                  ),
                }}
              />
            )}
          />
        
        )
    }
      </Box> */}

        <Box sx={{ ml: { md: 2 }, minWidth: "200px" }}>
                {accountsname ? (
                  <Autocomplete
                  value={selectedValue} // Assuming selectedValues is an array for multi-select
                  onChange={(event, newValues) => {
                    setSelectedValue(newValues);
                    handleSearch(newValues); // Assuming handleSearch handles multi-select values
                  }}
                  options={accountsname}
                  autoSelect
                  multiple // Add multiple prop for multi-select
                  getOptionLabel={(option) => `${option.label} (${option.csn})`}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select an Account"
                      variant="outlined"
                    />
                  )}
                />
                ) : (
                  // <CircularProgress />
                  <Autocomplete
                    value={null}
                    disabled
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an Account"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <Typography variant="body2" color="textSecondary">
                                Loading...
                              </Typography>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </Box>
      </div>
      <Box sx={{border:'0.8px solid' , borderColor:colors.grey[900]}}>
        <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
          <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Data of direct seat metrics i.e. 1:1 association between product pool and agreement (without prorated)
          </Typography>
        </Box>
        <div id="chart">
        {productlinecount ? (
          <ReactApexChart
          options={options}
          series={series}
          type={options.chart.type}
          height={options.chart.height}
        />
        ) : (
            <p>Loading data...</p>
        )}
        
        </div>
      </Box>

      <Box sx={{border:'0.8px solid' , borderColor:colors.grey[900]}}>
        <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
          <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Data of direct seat metrics i.e. 1:1 association between product pool and agreement (prorated)
          </Typography>
        </Box>
        <div id="chart">
        {productlineproratedcount ? (
          <ReactApexChart
          options={prorated_options}
          series={prorated_series}
          type={prorated_options.chart.type}
          height={prorated_options.chart.height}
        />
        ) : (
            <p>Loading data...</p>
        )}
        
        </div>
      </Box>
      <Box sx={{border:'0.8px solid' , marginTop:3 , borderColor:colors.grey[900]}}>
        <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
          <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Retention Risk shows summary of the renewal risk for the subscription's contract 
          </Typography>
        </Box>
        <div id="chart">
        {riskcategory ? (
          <ReactApexChart
          options={risk_options}
          series={risk_series}
          type="bar"
          height={350}
        />
        ) : (
          <p>Loading data...</p>
        )}        
        </div>
      </Box>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
        <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Table Data
          </Typography>
        </Box>
          {/* <Box  display="flex" marginBottom={2} justifyContent="end" alignItems="center">
        <input
        type="text"
        placeholder="Search..."
        value={searchTerm?.label ? searchTerm?.label : ""}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="max-w-[340px] w-full h-[52px] border px-3 mt-5"
      />
      </Box> */}
        {loading ? (
          <p>Loading data...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <DataGrid
            rows={processedData}
            columns={columns}
            getRowId={getRowId}
            components={{
              Toolbar: () => (
                <div>
                  <GridToolbar
                  csvOptions={{
                    fileName: `insight_trisita_${user.first_name} ${user.last_name}_${currDate}_${currTime}`,
                    fields: ['id', 'contractNumber', 'customerCSN', 'user_assign', 'renewal_person', 'Account', 'AccountStatus', 'contract_status', 'branch', 'productLineCode', 'seatsPurchased', 'usersAssigned', 'seatsInUse', 'assignmentRate', 'utilisationRate', 'usageRate', 'riskCategory', 'engagementCategory', 'tenantId'],    
                  }} />
                  <ExportToExcel
                      data={processedData}
                      columns={columns}
                      fileName={`insight_trisita_${user.first_name} ${user.last_name}_${currDate}_${currTime}`}
                    />
                </div>
              )
            }}
            checkboxSelection 
            disableRowSelectionOnClick
            
          />
        )}
      </Box>
    </Box>
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ backgroundColor: "red" }}
    >
      <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[500px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
      <div className="h-full">
      {selectedInsightContract?.length === 0 ? (
        <>No Data</>
      ): (
        <>
        <div className="h-10 mb-4">
          {selectedInsightContract?.map((subscription, index) => (
            <button
              key={subscription.id}
              className={`px-3 py-2 mr-2 border rounded ${
                (index === 0 && !selectedSubscription) || (selectedSubscription && selectedSubscription.id === subscription.id) ? 'bg-gray-300' : ''
              }`}
              onClick={() => setSelectedSubscription(subscription)}
            >
              {subscription.id}
            </button>
          ))}
        </div>
          {selectedSubscription && (
            <>
            <div className="text-black flex border-b border-gray-300 h-10">
                <Link
                  to={"#"}
                  className={`${
                    tab === 1
                      ? "border border-b-0 text-black bg-white mb-[-2px]"
                      : "border-b text-blue-500"
                  } p-2 border-gray-300 flex-grow text-center`}
                  onClick={() => handleTabChange(1)}
                >
                  End Customer
                </Link>
                <Link
                  to={"#"}
                  className={`${
                    tab === 2
                      ? "border border-b-0 text-black bg-white mb-[-2px]"
                      : "border-b text-blue-500"
                  } p-2 border-gray-300 flex-grow text-center`}
                  onClick={() => handleTabChange(2)}
                >
                  Reseller
                </Link>
                <Link
                  to={"#"}
                  className={`${
                    tab === 3
                      ? "border border-b-0 text-black bg-white mb-[-2px]"
                      : "border-b text-blue-500"
                  } p-2 border-gray-300 flex-grow text-center`}
                  onClick={() => handleTabChange(3)}
                >
                  Distributor
                </Link>
                <Link
                  to={"#"}
                  className={`${
                    tab === 4
                      ? "border border-b-0 text-black bg-white mb-[-2px]"
                      : "border-b text-blue-500"
                  } p-2 border-gray-300 flex-grow text-center`}
                  onClick={() => handleTabChange(4)}
                >
                  Contract
                </Link>
                <Link
                  to={"#"}
                  className={`${
                    tab === 5
                      ? "border border-b-0 text-black bg-white mb-[-2px]"
                      : "border-b text-blue-500"
                  } p-2 border-gray-300 flex-grow text-center`}
                  onClick={() => handleTabChange(5)}
                >
                  Line Items
                </Link>
              </div>
              <div className="p-4 my-4 h-[calc(100%_-_174px)] overflow-auto">
                {tab === 1 && (
                  <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                  End Customer Details
                </h1>
                {selectedSubscription ? (
                  <>
                  <div className="w-full">
                            <div className="flex my-4">
                              <div className="w-[200px]">Subscription#</div>
                              <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                            </div>
                            <div className="flex my-4">
                              <div className="w-[200px]">CSN</div>
                              <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.endCustomerCsn}</div>
                            </div>
                            <div className="flex my-4">
                            <div className="w-[200px]">Name</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.name}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Address Line 1</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.address1}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Address Line 2</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.address2}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">City</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.city}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">State</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.stateProvince}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Country</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.country}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Postal</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.postalCode}</div>
                          </div>
                         </div>
                          </>
                          ) : (
                            <p>Loading data...</p>
                          )}
                        <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                          Contract Manager Details
                        </h1>
                        {selectedSubscription ? (
                        <>
                        <div className="w-full">
                            <div className="flex my-4">
                            <div className="w-[200px]">First Name</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_contractManager.first}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Last Name</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_contractManager.last}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Email</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_contractManager.email}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Phone</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_contractManager.phone}</div>
                          </div>
                        </div>
                          </>
                          ) : (
                            <p>Loading data...</p>
                          )}
                  </>
                )}
                {tab === 2 && (
                  <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                          Reseller Details
                        </h1>
                        {selectedSubscription ? (
                        <>
                        <div className="w-full">
                          <div className="flex my-4">
                            <div className="w-[200px]"> Opportunity#</div>
                            <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                          </div>
                            {/* <div className="flex my-4">
                            <div className="w-[200px]"> Opportunity#</div>
                            <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                          </div> */}
                          <div className="flex my-4">
                            <div className="w-[200px]">CSN</div>
                            <div className="whitespace-pre">{selectedSubscription?.accounts_reseller.csn}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Name</div>
                            <div className="whitespace-pre">{selectedSubscription?.accounts_reseller.name}</div>
                          </div>
                        </div>
                          </>
                          ) : (
                            <p>Loading data...</p>
                          )}
                  </>
                 
                )}
                {tab === 3 && (
                  <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                          Distributor Details
                        </h1>
                        {selectedSubscription ? (
                        <>
                        <div className="w-full">
                            <div className="flex my-4">
                            <div className="w-[200px]"> Subscription#</div>
                            <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">CSN</div>
                            <div className="whitespace-pre">{selectedSubscription?.accounts_soldTo.csn}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Name</div>
                            <div className="whitespace-pre">{selectedSubscription?.accounts_soldTo.name}</div>
                          </div>
                        </div>
                          </>
                          ) : (
                            <p>Loading data...</p>
                          )}
                  </>
                )}
                {tab === 4 && (
                  <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                          Contract Details
                        </h1>
                        {selectedSubscription ? (
                        <>
                        <div className="w-full">
                            <div className="flex my-4">
                            <div className="w-[200px]"> Serial No#</div>
                            <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Contract#</div>
                            <div className="whitespace-pre">{selectedSubscription?.contract.contract_number}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Type</div>
                            <div className="whitespace-pre">{selectedSubscription?.contract.subType}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Product Line Name</div>
                            <div className="whitespace-pre">{selectedSubscription?.productLine}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Term</div>
                            <div className="whitespace-pre">{selectedSubscription?.contract.contract_term}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Duration</div>
                            <div className="whitespace-pre">{selectedSubscription?.contract.contract}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Retention Health</div>
                            <div className="whitespace-pre">{selectedSubscription?.ews_retentionHealth}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Sub start date</div>
                            <div className="whitespace-pre">{selectedSubscription?.startDate}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Sub end date</div>
                            <div className="whitespace-pre">{selectedSubscription?.endDate}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Quantity</div>
                            <div className="whitespace-pre">{selectedSubscription?.quantity}</div>
                          </div>
                        </div>
                          </>
                          ) : (
                            <p>Loading data...</p>
                          )}
                  </>
                )}
                {tab === 5 && (
                  <>
                    <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black"> Line Items 1 Details </h1>
                    {selectedSubscription ? (
                        <>
                        <div className="w-1/2">
                        <div className="flex my-4">
                            <div className="w-[200px]"> Serial#</div>
                            <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Quantity</div>
                            <div className="whitespace-pre">{selectedSubscription?.quantity}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Seats</div>
                            <div className="whitespace-pre">{selectedSubscription?.seats}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Deployment</div>
                            <div className="whitespace-pre">{selectedSubscription?.deployment}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Status</div>
                            <div className="whitespace-pre">{selectedSubscription?.subscriptionStatus}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Asset Status</div>
                            <div className="whitespace-pre">{selectedSubscription?.productStatus}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Support Program</div>
                            <div className="whitespace-pre">{""}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Program Eligibility</div>
                            <div className="whitespace-pre">{""}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]"> Renew</div>
                            <div className="whitespace-pre">{""}</div>
                          </div>
                        </div>
                        </>
                          ) : (
                            <p>Loading data...</p>
                          )}
  
                  </>
                )}
              </div>
            </>
          )}
        </>
       )}
        <div className="border-t">
          <div className="pt-4 text-right">
            <button
              className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
              onClick={() => {
                setOpen(false);
                setSelectedSubscription(null);
              }}
            >
              Close
            </button>
          </div>
        </div>
       </div>
      </div>
    </Modal>
      {
        isLoading && <Loader />
      }
    </>
  );
};

export default InsightMatricsV2;
