import React, { useState, useEffect } from "react";
import {Box} from "@mui/material";
import Header from "../../components/Header.jsx";
import {BACKEND_BASE_URL} from "../../components/config/apiEnv.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext.js";


const ChangePassword = () => {
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    const { token } = useUser();
    const {HandleLogout} = useAuth();


    const [passwords, SetPasswords] = useState({
        old_password: "",
        new_password: "",
        confirm_password:""
    });

    const headers = {
        Authorization: `Bearer ${token}`,
    };

    useEffect(() => {
        if (
        (passwords.new_password !== passwords.confirm_password &&
            passwords.confirm_password.length > 0) ||
        (passwords.confirm_password !== passwords.new_password &&
            passwords.new_password.length > 0)
        ) {
        setError("New password and confirm new password must match.");
        } else {
        setError(null);
        }
    }, [passwords.new_password, passwords.confirm_password]);

    const handleChange = (e) => {
    const { name, value } = e.target;
    SetPasswords((prevPasswords) => ({
        ...prevPasswords,
        [name]: value,
    }));
    };

    const handleSubmit = (e) => {
        const requestData = {
            old_password: passwords?.old_password,
            new_password: passwords?.new_password,
          };

        axios
        .post(BACKEND_BASE_URL + `v1/change_password`, requestData, { headers })
        .then((response) => {
            if (response.status === 200) {
                HandleLogout()
                navigate("/")
                toast.success(response.data?.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            setError(null);
            toast.success("User Updated Successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            } else {
            toast.error("Error occured while displaying accountsfdfdsfds.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            }
        })
        .catch((error) => {
            toast.error(error.response.data?.error, {
            position: toast.POSITION.TOP_RIGHT,
            });
        });
    };
  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Header title="Change Password" subtitle="welcome to you Team" />
      </Box>
      <div className="max-w-[800px] mx-auto mt-8 pb-14 p-6 bg-[#f2f0f0] rounded shadow-md">
        <h2 className="text-2xl font-semibold mb-6">Change Password Form</h2>
        <form onSubmit={handleSubmit}>
        {error && (
            <div style={{ color: 'red', marginTop: '8px' }}>
                {error}
            </div>
            )}
          <div className="mb-4">
            <label
              htmlFor="old_password"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Old Password 
            </label>
            <input
              type="password"
              id="old_password"
              name="old_password"
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="new_password"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
             New Password
            </label>
            <input
              type="password"
              id="new_password"
              name="new_password"
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="confirm_password"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
             Confirm New Password
            </label>
            <input
              type="password"
              id="confirm_password"
              name="confirm_password"
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <button
            type="button"

            className={`bg-${error ? 'gray-300' : 'blue-500'} text-white px-4 py-2 rounded-md ${error ? 'cursor-not-allowed' : 'hover:bg-blue-600 focus:outline-none focus:bg-blue-600'}`}
            disabled={!!error}
            onClick={handleSubmit}
          >
            Set Password
          </button>
        </form>
      </div>
    </Box>
  );
};

export default ChangePassword;
