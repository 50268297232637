import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { useUser } from "../../components/UserProvider.js";

const Login = () => {
  const navigate = useNavigate();
  const valuedata = { email: "", password: "" };
  const [formvalue, setFormvalue] = useState(valuedata);
  const [formError, setFromError] = useState({});

  const { HandleUser, HandleToken } = useUser();

  const handelChange = (e) => {
    const { name, value } = e.target;
    setFormvalue({ ...formvalue, [name]: value });
    setFromError({ ...formError, [name]: "" });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    toast.info("Login in Progress.", {
      position: toast.POSITION.TOP_RIGHT,
    });
    axios
      .post(BACKEND_BASE_URL + "v1/login/", formvalue)
      .then((response) => {
        if (response.status === 200) {
          HandleUser(response.data.user_serializer);
          HandleToken(response.data.access);
          navigate("/");
          toast.success("Login Successfully.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response?.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <div className="relative flex flex-col justify-center overflow-hidden min-h-screen">
      <div className="bg-[url('/public/construction.jpg')] bg-cover bg-no-repeat h-[100vh]"></div>
      <div className="w-full p-6 m-auto bg-white bg-opacity-[0.5] rounded-md shadow-md lg:max-w-xl md:max-w-lg sm:max-w-md absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <h1 className="text-3xl font-semibold text-center text-yellow-800 underline" >
          Welcome to Trisita
        </h1>
        <form className="mt-6" onSubmit={handleSubmit}>
          <div className="mb-2">
            <label
              htmlFor="email"
              className="block text-sm font-bold text-yellow-800"
            >
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formvalue.email}
              onChange={handelChange}
              fieldError={formError?.email}
              required
              className="block w-full px-4 py-2 mt-2 text-yellow-800 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mb-2">
            <label
              htmlFor="password"
              className="block text-sm font-bold text-yellow-800"
            >
              Password
            </label>
            <input
              type="password"
              name="password"
              value={formvalue.password}
              onChange={handelChange}
              fieldError={formError?.password}
              required
              className="block w-full px-4 py-2 mt-2 text-yellow-800 bg-white border rounded-md focus:border-purple-400 focus:ring-purple-300 focus:outline-none focus:ring focus:ring-opacity-40"
            />
          </div>
          <div className="mt-6">
            <button className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-yellow-800 rounded-md hover:bg-yellow-900 focus:outline-none focus:bg-yellow-900">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
