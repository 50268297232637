import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import {
  Autocomplete,
  Box,
  Grid,
  Modal,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useCSN } from "../../components/CSNProvider.jsx";
import ReactApexChart from "react-apexcharts";
import { MdClose } from "react-icons/md";
import { useUser } from "../../components/UserProvider.js";
import { useNavigate } from "react-router-dom";

const ActiveAccounts = () => {
  const { csn } = useCSN();
  const navigate = useNavigate()
  const [loading, SetLoading] = useState(true);
  const [popupLoading, SetPopupLoading] = useState(true);

  const [error, setError] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [accountData, SetAccountData] = useState([]);
  const [selectedAccount, SetselectedAccount] = useState(null);
  const [selectedAssignAccount, SetselectedAssignAccount] = useState(null);
  const [allUser, SetAllUser] = useState(null);
  const [allBranch, SetAllBranch] = useState(null);
  
  const [insightSummary, SetInsightSummary] = useState(null);
  const [contracts, SetContracts] = useState(null);
  const [endCustomerAccounts, SetEndCustomerAccounts] = useState(null);
  const [allaccountdata, SetAllAccountData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openassign, setOpenAssign] = useState(false);

  const [tab, setTab] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [searchBranch, setSearchBranch] = useState(null);
  const [branches, setBranches] = useState([]);
  const [userType, setUserType] = useState(null);

  const {token} = useUser()
  
  useEffect(() => {
    if (token){
    const user = localStorage.getItem("user");
    if (user) {
      const userData = JSON.parse(user);
      setUserType(userData.user_type);
    }
  }
  }, [token]);
  
  const headers = {
    Authorization: `Bearer ${token}`,
  };


  useEffect(() => {
    if (token){
    GetAllUser();
    GetAllBranch();
    // eslint-disable-next-line
    }
  }, [token]);

  const GetAllUser = () => {
    axios.get(BACKEND_BASE_URL + `v1/get_user/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetAllUser(response?.data.User);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const GetAllBranch = () => {
    axios.get(BACKEND_BASE_URL + `v1/api/get_all_branch/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetAllBranch(response?.data?.Branch);
          const data = response?.data?.Branch.map(item => {
            const name = item.branch_name;
            const value = item.id;
            return {
              label: name,
              value: value,
            };
          });
          const uniqueLabels = new Set();

          setBranches(data)
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying  sdasDSDASF accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying  DSFDSFDS F accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  useEffect(() => {
    if (token){
    ExportAccounts();
    // eslint-disable-next-line
    }
  }, [csn, token]);

  const ExportAccounts = () => {
    SetLoading(true)
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_export_accounts/${csn ? `${csn}` : ''}`;
    axios.get(apiUrl, { headers })
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data.accounts.filter(account => account.contract_status === "Active");
          
          SetAccountData(data);
          SetAllAccountData(data);
          SetLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  const handleOpenModel = (account_id) => {
    setOpen(true);
    SetPopupLoading(true);
    const defaultCsn = csn || "5102086717";
    axios
      .get(
        BACKEND_BASE_URL + `v1/api/get_account/${defaultCsn}/${account_id}`,
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          SetselectedAccount(response?.data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });

    axios
      .get(BACKEND_BASE_URL + `v1/api/get_insights_metrics_csn/${account_id}`, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          SetInsightSummary(response?.data?.Response);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });

    axios
      .get(BACKEND_BASE_URL + `v1/api/get_contracts/${account_id}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetContracts(response?.data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });

    axios
      .get(BACKEND_BASE_URL + `v1/api/get_endcustomer_account/${account_id}`, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          SetEndCustomerAccounts(response?.data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });

    SetPopupLoading(false);
    setOpen(true);
  };

  const handleAssignModel = (account_id) => {
    setOpenAssign(true);
    axios.get(BACKEND_BASE_URL + `v1/api/account/${account_id}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetselectedAssignAccount(response?.data);
          setSelectedUser(response?.data?.user_assign)
          setSelectedBranch(response?.data?.branch)
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  const handleTabChange = (val) => {
    setTab(val);
  };

  const getRowId = (row) => row.id;

  const columns = [
    {
      field: "csn",
      headerName: "CSN",
      width: 150,
      renderCell: (params, index) => (
        <div>
          <button
            onClick={() => handleOpenModel(params?.row.id)}
            className="action-button bg-white text-black px-3 py-1 rounded"
          >
            {params.value}
          </button>
        </div>
      ),
    },

    {
      field: "user_assign_firstname",
      headerName: "BD Person Name",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },

    {
      field: "name",
      headerName: "Account Name",
      width: 160,
      renderCell: (params) => {
        const { value: name } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {name?.length > maxChars ? name : name?.slice(0, maxChars)}
          </div>
        );
      },
    },
    { field: "industryGroup", headerName: "Industry", width: 100 },
    {
      field: "industrySegment",
      headerName: "Segment",
      width: 160,
      renderCell: (params) => {
        const { value: industrySegment } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {industrySegment?.length > maxChars
              ? industrySegment
              : industrySegment?.slice(0, maxChars)}
          </div>
        );
      },
    },
    {
      field: "industrySubSegment",
      headerName: "Sub Segment",
      width: 160,
      renderCell: (params) => {
        const { value: industrySubSegment } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {industrySubSegment?.length > maxChars
              ? industrySubSegment
              : industrySubSegment?.slice(0, maxChars)}
          </div>
        );
      },
    },
    {
      field: "address1",
      headerName: "Address",
      width: 160,
      renderCell: (params) => {
        const { value: address1 } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {address1?.length > maxChars
              ? address1
              : address1?.slice(0, maxChars)}
          </div>
        );
      },
    },
    { field: "city", headerName: "City", width: 150 },
    {
      field: "contract_manager[0].email",
      headerName: "Email",
      width: 150,
      renderCell: (params) => (
        <div>
          {params.row.contract_manager.length > 0
            ? params.row.contract_manager[0].email
            : "N/A"}
        </div>
      ),
    },
    {
      field: "contract_manager[0].phone",
      headerName: "Contact No",
      width: 150,
      renderCell: (params) => (
        <div>
          {params.row.contract_manager.length > 0
            ? params.row.contract_manager[0].phone
            : "N/A"}
        </div>
      ),
    },
    { field: "status", headerName: "Autodesk Account Status", width: 120 },
    { field: "contract_status", headerName: "Trisita Account Status", width: 120 },
    { field: "buyingReadinessScore", headerName: "Rediness Score", width: 130 }, 
    {
      field: "renewal_person_firstname",
      headerName: "Renewal Person Name",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    }, 
    {
      field: "branch",
      headerName: "Branch",
      width: 100,
      renderCell: (params) => {
        const branch = params.value;
        return (
          <div>
            {branch ? (
              <span>{branch.branch_name || "Unnamed Branch"}</span> // Adjust this line to display the correct property
            ) : (
              <span style={{ color: "red" }}>Undefined</span>
            )}
          </div>
        );
      },
    },
    ...(userType && userType === "Superadmin"
    ? [{
      field: "",
      headerName: "Action",
      width: 150,
      renderCell: (params, index) => (
        <div className="flex items-center w-full justify-center">
          <button
            onClick={() => handleAssignModel(params?.row.id)}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded"
          >
            Assign
          </button>
        </div>
      ),
    }] :
    []),
  ];

  const contract_getRowId = (row) => row.id;
  const contract_columns = [
    { field: "id", headerName: "ID" },
    { field: "contract_number", headerName: "Contract Number" },
    { field: "contract_startDate", headerName: "Start Date" },
    { field: "contract_endDate", headerName: "End Date" },
    { field: "contract_term", headerName: "Term" },
    { field: "billingBehavior", headerName: "billingBehavior" },
    { field: "subType", headerName: "subType" },
    { field: "contract_duration", headerName: "contract_duration" },
  ];

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value) {
      const filteredData = filterData(accountData);
      SetAccountData(filteredData);
    } else {
      ExportAccounts();
    }
  };

  const handleBranchFilter = (branch) => {

    if (branch?.label) {
      const filteredResults =  allaccountdata.filter((item) => {
        const name = item["branch"];
          return name === branch.label;
      });
      SetAccountData(filteredResults);
    }
    else {
      ExportAccounts();
    }
    
  };

  const filterData = (data) => {
    return data.filter((row) => {
      return Object.values(row).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  const insight_getRowId = (row) => row.contractNumber;
  const insight_columns = [
    { field: "contractNumber", headerName: "Contract#" },
    { field: "engagementCategory", headerName: "risk Cat" },
    { field: "premiumFlag", headerName: "Preminum Flag" },
    { field: "productLineCode", headerName: "PLC" },
    { field: "riskCategory", headerName: "Risk Cat" },
    { field: "seatsInUseProRated", headerName: "seatsInUseProRated" },
    { field: "seatsPurchased", headerName: "seatsPurchased" },
    { field: "tenantId", headerName: "tenantId" },
    { field: "usageRate", headerName: "usageRate" },
    { field: "usersAssignedProRated", headerName: "usersAssignedProRated" },
  ];

  const HandleAllocate = (account_id) => {
    const requestBody = {
      "user_assign":selectedUser?.id || null,
      "branch":selectedBranch?.id || null
    };
    axios.put(BACKEND_BASE_URL + `v1/api/account/${account_id}`, requestBody, { headers })
      .then((response) => {
        if (response.status === 200) {
          setError(null);
          toast.success("Account Allocated", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });

    ExportAccounts()
    setOpenAssign(false)
    
  };

  return (
    <>
      <Box m="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Header
            title="Active Account Information"
          />
        </Box>
        <Box sx={{display:"flex" , gap:'10px' , justifyContent:'end'}}>
        <Box display="flex" marginBottom={2} justifyContent="end" alignItems="center">
          <button
            onClick={() => navigate('/inactive_account')}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] text-center text-lg"
            style={{ whiteSpace: 'nowrap' }}
          >
            Inactive Account
          </button>       
        </Box>

        <Box  display="flex" marginBottom={2} justifyContent="end" alignItems="center">
            {branches ?  (
              <Autocomplete
                value={searchBranch}
                onChange={(event, newValue) => {
                  setSearchBranch(newValue);
                  handleBranchFilter(newValue);
                }}
                options={branches}
                autoSelect
                getOptionLabel={(option) => option.label}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select a Branch" variant="outlined" />
                )}
              />
            ) :(
              // <CircularProgress />
              <Autocomplete
                value={null}
                disabled
                autoSelect
                getOptionLabel={(option) => option.label}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Branch"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          <Typography variant="body2" color="textSecondary">
                            Loading...
                          </Typography>
                        </>
                      ),
                    }}
                  />
                )}
              />
            
            )
          }
        </Box>
    
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            className="max-w-[340px] w-full h-[52px] border px-3 outline-none"
          />
      </Box>
        <Box
          m="8px 0 0 0"
          width="100%"
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          {loading ? (
            <p>Loading data...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <DataGrid
              rows={accountData}
              columns={columns}
              getRowId={getRowId}
              components={{ Toolbar: GridToolbar }}
              checkboxSelection 
              disableRowSelectionOnClick                                                               
            />
          )}
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{ backgroundColor: "red" }}
      >
        <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[1150px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
          <div className="h-full">
            <div className="text-black flex border-b border-gray-300">
              <Link
                to={"#"}
                className={`${
                  tab === 1
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "text-blue-500"
                } p-2 border-gray-300 text-center`}
                onClick={() => handleTabChange(1)}
              >
                End Customer
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 2
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "text-blue-500"
                } p-2 border-gray-300 text-center`}
                onClick={() => handleTabChange(2)}
              >
                Details
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 3
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "text-blue-500"
                } p-2 border-gray-300 text-center`}
                onClick={() => handleTabChange(3)}
              >
                Contracts
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 4
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "text-blue-500"
                } p-2 border-gray-300 text-center`}
                onClick={() => handleTabChange(4)}
              >
                Insight Summary
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 5
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "text-blue-500"
                } p-2 border-gray-300 text-center`}
                onClick={() => handleTabChange(5)}
              >
                Usage Summary
              </Link>
            </div>
            <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">
              {tab === 1 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    End Customer Details
                  </h1>
                  {endCustomerAccounts ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]">CSN</div>
                          <div className="whitespace-pre">
                            {
                              endCustomerAccounts?.endcustomer[0]
                                ?.endCustomerCsn
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.name}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Address Line 1</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.address1}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Address Line 2</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.address2}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">City</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.city}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">State</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.stateProvince}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Country</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.country}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Postal</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.postalCode}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Contract Manager Details
                  </h1>
                  {endCustomerAccounts ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]">First Name</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.contract_manager[0]?.first}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Last Name</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.contract_manager[0]?.last}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Email</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.contract_manager[0]?.email}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Phone</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.contract_manager[0]?.phone}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 2 && (
                <>
                  {/* <div className="text-black text-center my-0">Details</div> */}
                  {selectedAccount ? (
                    <>
                      <div className="flex">
                        {/* left  */}
                        <div className="w-1/2">
                          <div className="flex my-4">
                            <div className="w-[200px]">Name</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.name}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Address1</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.address1}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">City</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.name}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">ADK main contact</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.autodeskMainContact}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Flex Flag</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.flexCustomerFlag}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Flex Flag?</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.flexCustomerFlag}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Industry</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.industry}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Industry Segment</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.industrySegment}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Named Account</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.isNamedAccount}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Phone Number</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.phoneNumber}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Sales Region</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.salesRegion}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Victim CSN</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.victimCsn}
                            </div>
                          </div>
                        </div>

                        {/* right */}
                        <div className="w-1/2">
                          <div className="flex my-4">
                            <div className="w-[200px]">Total Contracts </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.totalNumberOfActiveContracts}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Address2 </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.address2}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Country/Geo </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.countryCode}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Contact email </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.autodeskMainContactEmail}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Individual Flag </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.individualFlag}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Individual Flag </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.individualFlag}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Industry Group </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.industryGroup}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Sub Segment</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.industrySubSegment}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Parent Account CSN</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.parentIsNamedAccount}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Postal</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.postal}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Status</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.status}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <p><span>Name : {selectedAccount?.name}</span> <span>Total Contracts : {selectedAccount?.totalNumberOfActiveContracts}</span></p>
                      <p><span>Address1 : {selectedAccount?.address1}</span> <span>Address2 : {selectedAccount?.address2}</span></p>
                      <p><span>City : {selectedAccount?.name}</span> <span>Country/Geo : {selectedAccount?.countryCode} {selectedAccount?.geo}</span></p>
                      <p><span>ADK main contact : {selectedAccount?.autodeskMainContact}</span> <span>Contact email : {selectedAccount?.autodeskMainContactEmail}</span></p>
                      <p><span>Flex Flag : {selectedAccount?.flexCustomerFlag}</span> <span>Individual Flag : {selectedAccount?.individualFlag}</span></p>
                      <p><span>Flex Flag? : {selectedAccount?.flexCustomerFlag}</span> <span>Individual Flag : {selectedAccount?.individualFlag}</span></p>
                      <p><span>Industry : {selectedAccount?.industry}</span> <span>Industry Group : {selectedAccount?.industryGroup}</span></p>
                      <p><span>Industry Segment : {selectedAccount?.industrySegment}</span> <span>Sub Segment : {selectedAccount?.industrySubSegment}</span></p>
                      <p><span>Named Account : {selectedAccount?.isNamedAccount}</span> <span>Parent Account CSN : {selectedAccount?.parentIsNamedAccount}</span></p>
                      <p><span>Phone Number : {selectedAccount?.phoneNumber}</span> <span>Postal : {selectedAccount?.postal}</span></p>
                      <p><span>Sales Region : {selectedAccount?.salesRegion}</span> <span>Status : {selectedAccount?.status}</span></p>
                      <p><span>Victim CSN : {selectedAccount?.victimCsn}</span></p> */}
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 3 && (
                <>
                  {popupLoading ? (
                    <p>Loading data...</p>
                  ) : (
                    <DataGrid
                      rows={contracts}
                      columns={contract_columns}
                      getRowId={contract_getRowId}
                      components={{ Toolbar: GridToolbar }}
                    />
                  )}
                </>
              )}
              {tab === 4 && (
                <>
                  {popupLoading ? (
                    <p>Loading data...</p>
                  ) : (
                    <DataGrid
                      rows={insightSummary}
                      columns={insight_columns}
                      getRowId={insight_getRowId}
                      components={{ Toolbar: GridToolbar }}
                    />
                  )}
                </>
              )}
              {tab === 5 && (
                <>
                  <Link to="/get_usage">
                    {" "}
                    <h3 className=" text-center text-[blue] font-bold">Click Here</h3>
                    <hr className="my-3 h-0.5 bg-black"/>
                  </Link>
                  <div className="text-black text-center my-0 ">
                    To go Get Usage Page
                  </div>
                </>
              )}
            </div>
            <div className="border-t">
              <div className="pt-4 text-right">
                <button
                  className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                  onClick={() => {
                    setOpen(false);
                    SetselectedAccount(null);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={openassign}
        onClose={() => setOpenAssign(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{ backgroundColor: "red" }}
      >
        <div className="relative -translate-x-2/4 bg-white h-[300px] max-w-[600px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
          <div className="absolute top-2 right-3 cursor-pointer">
            <MdClose
              className="cursor-pointer text-xl"
              onClick={() => {
                setOpenAssign(false);
                SetselectedAssignAccount(null);
              }}
            />
          </div>
          <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
            Allocate User and Branch
          </h3>
              <Box sx={{paddingY:'30px'}}>

            <Grid container spacing={2}>
              <Grid item sx={12} sm={3}>
                <Typography sx={{fontSize:'18px' , fontWeight:600}}>User :</Typography>
              </Grid>
              <Grid item sx={12} sm={9}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allUser}
                value={selectedUser}
                getOptionLabel={(option) => option.first_name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select User" variant="outlined" />
                )}
                onChange={(event, newValue) => {
                  setSelectedUser(newValue);
                }}
              />
              </Grid>
              <Grid item sx={12} sm={3}>
                <Typography sx={{fontSize:'18px' , fontWeight:600}}>Branch :</Typography>
              </Grid>
              <Grid item sx={12} sm={9}>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={allBranch}
                  getOptionLabel={(option) => option.branch_name}
                  value={selectedBranch}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select City" variant="outlined" />
                  )}
                  onChange={(event, newValue) => {
                    setSelectedBranch(newValue);
                  }}
                />
              </Grid>
            </Grid>
            <div className="max-w-[447px] w-full text-right mt-4">
              <button
                    className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded "
                    onClick={() => HandleAllocate(selectedAssignAccount?.id)}
                  >
                    Allocate
                  </button>
            </div>

      
              </Box>
              
        </div>
      </Modal>
    </>
  );
};

export default ActiveAccounts;
