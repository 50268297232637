import React, { useState } from "react";
import { useContext, createContext } from "react";
import { ColorModeContext, tokens } from "../../theme";
import { useTheme, InputBase, FormControl, Select, FormHelperText, Tooltip, Box, Avatar, Menu, MenuItem, ListItemIcon, Divider, IconButton, Typography} from "@mui/material";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useProSidebar } from "react-pro-sidebar";
import InsightMatrics from "../Insight_Matrics/InsightMatrics";
import { useCSN } from "../../components/CSNProvider";
import { useAuth } from "../../components/AuthContext";

import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useNavigate } from "react-router-dom";
import { useYear } from "../../components/YearProvider";

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const colorMode = useContext(ColorModeContext);
  const [csn, setCSN] = useState("");
  const [year, setYear] = useState("");
  const [selectedValue, SetselectedValue] = useState("");
  

  const {HandleCSN} = useCSN()
  const {HandleYear} = useYear()
  const {HandleLogout} = useAuth(); 
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    SetselectedValue(event.target.value);
    Logout()
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelect = (event) => {
    setCSN(event.target.value);
    HandleCSN(event.target.value)
  };

  const handleSelectYear = (event) => {
    setYear(event.target.value);
    HandleYear(event.target.value)
  };


  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogoutAndClose = () => {    
    handleClose();
    HandleLogout();
    navigate("/")
  };

  const { toggleSidebar, broken, rtl } = useProSidebar();
  return (
    <>
    <Box display="flex" alignItems={'center'} justifyContent="space-between" p={2}>
      <Box display="flex">
        {broken && !rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          p={0.2}
          borderRadius={1}
        >
          {/* <InputBase sx={{ ml: 1, flex: 1 }} placeholder="Search" />
          <IconButton type="button">
            <SearchIcon />
          </IconButton> */}
        </Box>
      </Box>
      <Box display="flex" alignItems={'center'}>
      <FormControl sx={{marginRight:2, minWidth: 120 }}>
          <Select
            value={year}
            
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={handleSelectYear}
          >
            {/* <MenuItem value="" disabled>
              <em>Select CSN</em>
            </MenuItem> */}
            <MenuItem value={""}>All Year</MenuItem>
            <MenuItem value={"ADSK FY 2025"}>ADSK FY 2025</MenuItem>
            <MenuItem value={"ADSK FY 2024"}>ADSK FY 2024</MenuItem>
            <MenuItem value={"ADSK FY 2023"}>ADSK FY 2023</MenuItem>
          </Select>
        </FormControl>
        <FormControl sx={{marginRight:2, minWidth: 120 }}>
          <Select
            value={csn}
            
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            onChange={handleSelect}
          >
            {/* <MenuItem value="" disabled>
              <em>Select CSN</em>
            </MenuItem> */}
            <MenuItem value={""}>All CSN</MenuItem>
            <MenuItem value={"5102086717"}>5102086717</MenuItem>
            <MenuItem value={"5117963549"}>5117963549</MenuItem>
            <MenuItem value={"1234567890"}>1234567890</MenuItem>
          </Select>
        </FormControl>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            
           <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton>
          <NotificationsOutlinedIcon />
        </IconButton>
        <IconButton>
          <SettingsOutlinedIcon />
        </IconButton>
        
        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
          <PersonOutlinedIcon />
            {/* <Avatar sx={{ width: 32, height: 32 }}>M</Avatar> */}
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleClose}>
          <Avatar /> Profile
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <Avatar /> My account
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <PersonAdd fontSize="small" />
          </ListItemIcon>
          Add another account
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={handleLogoutAndClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>


        {broken && rtl && (
          <IconButton
            sx={{ margin: "0 6 0 2" }}
            onClick={() => toggleSidebar()}
          >
            <MenuOutlinedIcon />
          </IconButton>
        )}
      </Box>
    </Box>
    </>
  );
};

export default Topbar;
