import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import {
  Autocomplete,
  Box,
  Grid,
  Modal,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
  Button,
  Tooltip
} from "@mui/material";
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { Link, Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCSN } from "../../components/CSNProvider.jsx";
import ReactApexChart from "react-apexcharts";
import { MdClose } from "react-icons/md";
import { useUser } from "../../components/UserProvider.js";
import Loader from "../../components/Loader/Loader.js";
import { useNavigate } from "react-router-dom";


const ThirdPartyAccount = () => {
  const { csn } = useCSN();
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);
  const [popupLoading, SetPopupLoading] = useState(true);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedRenewalPeople, setSelectedRenewalPeople] = useState([]);

  const [error, setError] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [accountData, SetAccountData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState([]);
  const [selectedAccount, SetselectedAccount] = useState(null);
  const [selectedAssignAccount, SetselectedAssignAccount] = useState(null);
  const [allUser, SetAllUser] = useState(null);
  const [allBranch, SetAllBranch] = useState(null);
  
  
  const [insightSummary, SetInsightSummary] = useState(null);
  const [contracts, SetContracts] = useState(null);
  const [endCustomerAccounts, SetEndCustomerAccounts] = useState(null);
  const [status, setStatus] = React.useState("All Status");
  const [allaccountdata, SetAllAccountData] = useState([]);
  const [filterAccountData, SetFilterAccountData] = useState([]);

  const [linechartData, setLineChartData] = useState();
  const [linechartname, setLineChartName] = useState("Industry");
  const [open, setOpen] = useState(false);
  const [openassign, setOpenAssign] = useState(false);

  const [tab, setTab] = useState(1);
  const [isActive, setIsActive] = useState("All");

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedrenewal, setRenewalPerson] = useState(null);
  
  const [searchBranch, setSearchBranch] = useState(null);
  const [branches, setBranches] = useState([]);
  const [topCities, setTopCities] = useState([]);
  const [byIndustry, setIndustryGroup] = useState([]);
  const [bySegment, setIndustrySegment] = useState([]);
  const [bySubSegment, setIndustrySubSegment] = useState([]);
  const [totalactiveAccounts, setActiveAccounts] = useState([]);
  const [totalinactiveAccounts, setInactiveAccounts] = useState([]);
  const [industryGroupsAccounts, setIndustryGroupsAccounts] = useState({});
  const [reddinessRangeAccounts, setReddinessRangeAccounts] = useState([]);
  const [userType, setUserType] = useState(null);

  const {token, user} = useUser()

  const transformApiResponse = (data) => {
    return data.map(account => ({
      "id": account.id,
      "csn": account?.csn ?? null,
      "email": account?.contract_manager[0]?.email ?? null,
      "phone": account?.contract_manager[0]?.phone ?? null,
      "name": account?.name ?? "",
      "industryGroup": account?.industryGroup ?? null,
      "industrySegment": account?.industrySegment ?? null,
      "industrySubSegment": account?.industrySubSegment ?? null,
      "address1": account?.address1 ?? null,
      "city": account?.city ?? null,
      "status": account?.status ?? null,
      "contract_status": account?.contract_status ?? null,
      "buyingReadinessScore": account?.buyingReadinessScore ?? null,
      "branch":account?.branch_name ?? null,
      "branch_object":account?.branch ?? null,
      "user_assign":account?.user_assign_firstname ?? null,
      "renewal_person":account?.renewal_person_firstname ?? null,
      "associated_account":account?.associated_account ?? null,
      // "renewal_person_object":account?.renewal_person ?? null,
      // "user_assign_object":account?.user_assign ?? null,
    }));
};
  
  useEffect(() => {
    if (token){
    const user = localStorage.getItem("user");
    if (user) {
      const userData = JSON.parse(user);
      setUserType(userData.user_type);
    }
  }
  }, [token]);
  
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  
  useEffect(() => {
    if (token){
    GetAllUser();
    GetAllBranch();
    // eslint-disable-next-line
    }
  }, [token]);

  const GetAllUser = () => {
    setStatus("All Status");
    axios.get(BACKEND_BASE_URL + `v1/get_user/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetAllUser(response?.data.User);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        
        setIsLoading(false)
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  const GetAllBranch = () => {
    setStatus("All Status");
    axios.get(BACKEND_BASE_URL + `v1/api/get_all_branch/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetAllBranch(response?.data?.Branch);
          const data = response?.data?.Branch.map(item => {
            const name = item.branch_name;
            const value = item.id;
            return {
              label: name,
              value: value,
            };
          });
          const uniqueLabels = new Set();
          setBranches(data)
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying  sdasDSDASF accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        
        toast.error("Error occured while displaying  DSFDSFDS F accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  useEffect(() => {
    if (token){
    ExportAccounts();
    // eslint-disable-next-line
    }
  }, [csn, token]);

  useEffect(() => {
    handleLinkClick(byIndustry, "Industry");
    // eslint-disable-next-line
  }, [byIndustry]);

  const ExportAccounts = () => {
    console.log("___________________done ");
    setIsLoading(true)
    setStatus("All Status");
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_third_party_accounts/${csn ? `${csn}` : ''}`;
    axios.get(apiUrl, { headers })
      .then((response) => {
        if (response.status === 200) {
          const transformedData = transformApiResponse(response?.data.accounts);
          SetAccountData(transformedData);
          SetAllAccountData(transformedData);
          SetFilterAccountData(transformedData);
          setIsLoading(false)
          setError(null);
          setSearchBranch(null)
          setLastUpdated(response?.data?.last_updated)
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setIsLoading(false)
        setError(error?.response.data.Response.message);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    ManageChartsData();
    // eslint-disable-next-line
  }, [accountData]);

  const ManageChartsData = () => {
    let industryGroups = {};
    let industrySegments = {};
    let industrySubSegments = {};
    const scoreRanges = {
      NA: [],
      25: [],
      50: [],
      75: [],
      100: [],
    };

    accountData?.forEach((account) => {
      // Process industry group counts
      const industryGroup = account.industryGroup;
      if (industryGroup) {
        if (industryGroups[industryGroup]) {
          industryGroups[industryGroup].total += 1;
          if (account.contract_status === "Active") {
            industryGroups[industryGroup].active += 1;
            industryGroups[industryGroup].activeAccounts.push(account);
          } else {
            industryGroups[industryGroup].inactive += 1;
            industryGroups[industryGroup].inactiveAccounts.push(account);
          }
        } else {
          industryGroups[industryGroup] = {
            total: 1,
            active: account.contract_status === "Active" ? 1 : 0,
            inactive: account.contract_status !== "Active" ? 1 : 0,
            activeAccounts:
              account.contract_status === "Active" ? [account] : [],
            inactiveAccounts:
              account.contract_status !== "Active" ? [account] : [],
          };
        }
      }

      // Process segment group counts
      const industrySegment = account.industrySegment;
      if (industrySegment) {
        if (industrySegments[industrySegment]) {
          industrySegments[industrySegment].total += 1;
          if (account.contract_status === "Active") {
            industrySegments[industrySegment].active += 1;
            industrySegments[industrySegment].activeAccounts.push(account);
          } else {
            industrySegments[industrySegment].inactive += 1;
            industrySegments[industrySegment].inactiveAccounts.push(account);
          }
        } else {
          industrySegments[industrySegment] = {
            total: 1,
            active: account.contract_status === "Active" ? 1 : 0,
            inactive: account.contract_status !== "Active" ? 1 : 0,
            activeAccounts:
              account.contract_status === "Active" ? [account] : [],
            inactiveAccounts:
              account.contract_status !== "Active" ? [account] : [],
          };
        }
      }

      // Process sub segment group counts
      const industrySubSegment = account.industrySubSegment;
      if (industrySubSegment) {
        if (industrySubSegments[industrySubSegment]) {
          industrySubSegments[industrySubSegment].total += 1;
          if (account.contract_status === "Active") {
            industrySubSegments[industrySubSegment].active += 1;
            industrySubSegments[industrySubSegment].activeAccounts.push(
              account
            );
          } else {
            industrySubSegments[industrySubSegment].inactive += 1;
            industrySubSegments[industrySubSegment].inactiveAccounts.push(
              account
            );
          }
        } else {
          industrySubSegments[industrySubSegment] = {
            total: 1,
            active: account.contract_status === "Active" ? 1 : 0,
            inactive: account.contract_status !== "Active" ? 1 : 0,
            activeAccounts:
              account.contract_status === "Active" ? [account] : [],
            inactiveAccounts:
              account.contract_status !== "Active" ? [account] : [],
          };
        }
      }
      // reddiness accounts
      const score = account.buyingReadinessScore;
      if (score === "") {
        scoreRanges.NA = [...scoreRanges.NA, account];
      } else {
        const scoreValue = Math.round(parseFloat(score));
        if (scoreValue <= 25) {
          scoreRanges[25] = [...scoreRanges[25], account];
        } else if (scoreValue <= 50) {
          scoreRanges[50] = [...scoreRanges[50], account];
        } else if (scoreValue <= 75) {
          scoreRanges[75] = [...scoreRanges[75], account];
        } else if (scoreValue <= 100) {
          scoreRanges[100] = [...scoreRanges[100], account];
        }
      }
    });

    const activeAccounts = accountData.filter(
      (account) => account.contract_status === "Active"
    );
    const inactiveAccounts = accountData.filter(
      (account) => account.contract_status === "Expired"
    );
    setActiveAccounts(activeAccounts);
    setInactiveAccounts(inactiveAccounts);

    setReddinessRangeAccounts(scoreRanges);
    setIndustryGroup(industryGroups);
    setIndustrySegment(industrySegments);
    setIndustrySubSegment(industrySubSegments);
  };

  useEffect(() => {
    ManageAllAcountsData();
    // eslint-disable-next-line
  }, [allaccountdata]);

  const ManageAllAcountsData = (cityfun) => {
    let topindustryGroups = {};
    const scoreRanges = {
      NA: [],
      25: [],
      50: [],
      75: [],
      100: [],
    };
    const countcity = {};
    const top4ActiveIndustryGroups = {};
    allaccountdata?.forEach((account) => {
      const industryGroup = account.industryGroup || 'Null';
      const city2 = account.city;
      if (cityfun) {
        if (city2 && city2 === cityfun) {
          if (industryGroup) {
            if (topindustryGroups[industryGroup]) {
              topindustryGroups[industryGroup].total += 1;
              if (account.contract_status === "Active") {
                topindustryGroups[industryGroup].active += 1;
                topindustryGroups[industryGroup].activeAccounts.push(account);
              } else {
                topindustryGroups[industryGroup].inactive += 1;
                topindustryGroups[industryGroup].inactiveAccounts.push(account);
              }

              const industryGroupsOrder = [
                "AEC",
                "MFG",
                "M&E",
                "EDU",
                "OTH",
                "Unknown",
                "Null"
              ];
              const formattedTopIndustryGroups = {};

              industryGroupsOrder.forEach((industryGroup) => {
                const industryGroupData = topindustryGroups[industryGroup];
                if (industryGroupData) {
                  formattedTopIndustryGroups[industryGroup] =
                    industryGroupData || [];
                }
              });

              topindustryGroups = formattedTopIndustryGroups;
            } else {
              topindustryGroups[industryGroup] = {
                total: 1,
                active: account.contract_status === "Active" ? 1 : 0,
                inactive: account.contract_status === "Expired" ? 1 : 0,
                activeAccounts:
                  account.contract_status === "Active" ? [account] : [],
                inactiveAccounts:
                  account.contract_status === "Expired" ? [account] : [],
              };
            }
          }
        }
      } else {
        if (industryGroup) {
          if (topindustryGroups[industryGroup]) {
            topindustryGroups[industryGroup].total += 1;
            if (account.contract_status === "Active") {
              topindustryGroups[industryGroup].active += 1;
              topindustryGroups[industryGroup].activeAccounts.push(account);
            } else {
              topindustryGroups[industryGroup].inactive += 1;
              topindustryGroups[industryGroup].inactiveAccounts.push(account);
            }

          } else {
            topindustryGroups[industryGroup] = {
              total: 1,
              active: account.contract_status === "Active" ? 1 : 0,
              inactive: account.contract_status === "Expired" ? 1 : 0,
              activeAccounts:
                account.contract_status === "Active" ? [account] : [],
              inactiveAccounts:
                account.contract_status === "Expired" ? [account] : [],
            };
          }
        }
      }

      const city = account.city;
      const contract_status = account.contract_status;

      if (!countcity[city]) {
        countcity[city] = { Active: 0, Expired: 0 };
      }

      countcity[city][contract_status] += 1;

      const score = account.buyingReadinessScore;
      if (score === "") {
        scoreRanges.NA = [...scoreRanges.NA, account];
      } else {
        const scoreValue = Math.round(parseFloat(score));
        if (scoreValue <= 25) {
          scoreRanges[25] = [...scoreRanges[25], account];
        } else if (scoreValue <= 50) {
          scoreRanges[50] = [...scoreRanges[50], account];
        } else if (scoreValue <= 75) {
          scoreRanges[75] = [...scoreRanges[75], account];
        } else if (scoreValue <= 100) {
          scoreRanges[100] = [...scoreRanges[100], account];
        }
      }
    });

    setReddinessRangeAccounts(scoreRanges);
    const activeAccounts = allaccountdata.filter(
      (account) => account.contract_status === "Active"
    );
    const inactiveAccounts = allaccountdata.filter(
      (account) => account.contract_status === "Expired"
    );
    setActiveAccounts(activeAccounts);
    setInactiveAccounts(inactiveAccounts);

    const sortedIndustryGroups = Object.entries(topindustryGroups).sort(
      (a, b) => b[1].total - a[1].total
    );

    const top4Groups = sortedIndustryGroups.slice(0);

    top4Groups.sort((a, b) => {
      const order = ["AEC", "MFG", "M&E", "EDU", "OTH", "Unknown", "Null"];

      const indexA = order.indexOf(a[0]);
      const indexB = order.indexOf(b[0]);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      if (indexA !== -1) {
        return -1;
      }

      if (indexB !== -1) {
        return 1;
      }

      return 0;
    });

    top4Groups.forEach(([group]) => {
      const activeAccounts = accountData?.filter(
        (account) =>
          (account.industryGroup === group || (group === "Null" && account.industryGroup === null)) &&
          account.contract_status === "Active"
      );
      const inactiveAccounts = accountData?.filter(
        (account) =>
          (account.industryGroup === group || (group === "Null" && account.industryGroup === null)) &&
          account.contract_status !== "Active"
      );
      top4ActiveIndustryGroups[group] = {
        activeAccounts,
        inactiveAccounts,
      };
    });


    const sortedCities = Object.entries(countcity).sort(
      (a, b) => b[1].Active + b[1].Expired - (a[1].Active + a[1].Expired)
    );

    const topCities = sortedCities.slice(0, 12).map(([city, counts]) => ({
      city,
      counts,
    }));

    setTopCities(topCities);
    
    if (cityfun) {
      setIndustryGroupsAccounts(topindustryGroups); 
    } else {
      setIndustryGroupsAccounts(top4ActiveIndustryGroups);
    }
  };


  useEffect(() => {
    ManageDataForStatus();
    // eslint-disable-next-line
  }, [accountData]);


  const ManageDataForStatus = (cityfun) => {
    let topindustryGroups = {};
    const scoreRanges = {
      NA: [],
      25: [],
      50: [],
      75: [],
      100: [],
    };
    const countcity = {};
    const top4ActiveIndustryGroups = {};
    accountData?.forEach((account) => {
      const industryGroup = account.industryGroup || "Null";
      const city2 = account.city;
      if (cityfun) {
        if (city2 && city2 === cityfun) {
          if (industryGroup) {
            if (topindustryGroups[industryGroup]) {
              topindustryGroups[industryGroup].total += 1;
              if (account.contract_status === "Active") {
                topindustryGroups[industryGroup].active += 1;
                topindustryGroups[industryGroup].activeAccounts.push(account);
              } else {
                topindustryGroups[industryGroup].inactive += 1;
                topindustryGroups[industryGroup].inactiveAccounts.push(account);
              }

              const industryGroupsOrder = [
                "AEC",
                "MFG",
                "M&E",
                "EDU",
                "OTH",
                "Unknown",
                "Null",
              ];
              const formattedTopIndustryGroups = {};

              industryGroupsOrder.forEach((industryGroup) => {
                const industryGroupData = topindustryGroups[industryGroup];
                if (industryGroupData) {
                  formattedTopIndustryGroups[industryGroup] =
                    industryGroupData || [];
                }
              });

              topindustryGroups = formattedTopIndustryGroups;
            } else {
              topindustryGroups[industryGroup] = {
                total: 1,
                active: account.contract_status === "Active" ? 1 : 0,
                inactive: account.contract_status === "Expired" ? 1 : 0,
                activeAccounts:
                  account.contract_status === "Active" ? [account] : [],
                inactiveAccounts:
                  account.contract_status === "Expired" ? [account] : [],
              };
            }
          }
        }
      } else {
        if (industryGroup) {
          if (topindustryGroups[industryGroup]) {
            topindustryGroups[industryGroup].total += 1;
            if (account.contract_status === "Active") {
              topindustryGroups[industryGroup].active += 1;
              topindustryGroups[industryGroup].activeAccounts.push(account);
            } else {
              topindustryGroups[industryGroup].inactive += 1;
              topindustryGroups[industryGroup].inactiveAccounts.push(account);
            }

          } else {
            topindustryGroups[industryGroup] = {
              total: 1,
              active: account.contract_status === "Active" ? 1 : 0,
              inactive: account.contract_status === "Expired" ? 1 : 0,
              activeAccounts:
                account.contract_status === "Active" ? [account] : [],
              inactiveAccounts:
                account.contract_status === "Expired" ? [account] : [],
            };
          }
        }
      }

      //Top 12 Cities
      const city = account.city;
      const contract_status = account.contract_status;

      if (!countcity[city]) {
        countcity[city] = { Active: 0, Expired: 0 };
      }

      countcity[city][contract_status] += 1;

      // reddiness accounts
      const score = account.buyingReadinessScore;
      if (score === "") {
        scoreRanges.NA = [...scoreRanges.NA, account];
      } else {
        const scoreValue = Math.round(parseFloat(score));
        if (scoreValue <= 25) {
          scoreRanges[25] = [...scoreRanges[25], account];
        } else if (scoreValue <= 50) {
          scoreRanges[50] = [...scoreRanges[50], account];
        } else if (scoreValue <= 75) {
          scoreRanges[75] = [...scoreRanges[75], account];
        } else if (scoreValue <= 100) {
          scoreRanges[100] = [...scoreRanges[100], account];
        }
      }
    });

    setReddinessRangeAccounts(scoreRanges);
    const activeAccounts = accountData.filter(
      (account) => account.contract_status === "Active"
    );
    const inactiveAccounts = accountData.filter(
      (account) => account.contract_status === "Expired"
    );
    setActiveAccounts(activeAccounts);
    setInactiveAccounts(inactiveAccounts);

    const sortedIndustryGroups = Object.entries(topindustryGroups).sort(
      (a, b) => b[1].total - a[1].total
    );

    const top4Groups = sortedIndustryGroups.slice(0);

    top4Groups.sort((a, b) => {
      const order = ["AEC", "MFG", "M&E", "EDU", "OTH", "Unknown", "Null"];

      const indexA = order.indexOf(a[0]);
      const indexB = order.indexOf(b[0]);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      if (indexA !== -1) {
        return -1;
      }

      if (indexB !== -1) {
        return 1;
      }

      return 0;
    });

    top4Groups.forEach(([group]) => {
      const activeAccounts = accountData?.filter(
        (account) =>
          (account.industryGroup === group || (group === "Null" && account.industryGroup === null)) &&
          account.contract_status === "Active"
      );
      const inactiveAccounts = accountData?.filter(
        (account) =>
          (account.industryGroup === group || (group === "Null" && account.industryGroup === null)) &&
          account.contract_status !== "Active"
      );
      top4ActiveIndustryGroups[group] = {
        activeAccounts,
        inactiveAccounts,
      };
    });

    if (cityfun) {
      setIndustryGroupsAccounts(topindustryGroups); // filter
    } else {
      setIndustryGroupsAccounts(top4ActiveIndustryGroups); //default
    }
  };


  const handleOpenModel = (account_id) => {
    setOpen(true);
    SetPopupLoading(true);
    const defaultCsn = csn || "5102086717";
    axios
      .get(
        BACKEND_BASE_URL + `v1/api/get_account/${defaultCsn}/${account_id}`,
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          SetselectedAccount(response?.data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    axios
      .get(BACKEND_BASE_URL + `v1/api/get_insights_metrics_csn/${account_id}`, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          SetInsightSummary(response?.data?.Response);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    axios
      .get(BACKEND_BASE_URL + `v1/api/get_contracts/${account_id}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetContracts(response?.data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    axios
      .get(BACKEND_BASE_URL + `v1/api/get_endcustomer_account/${account_id}`, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          SetEndCustomerAccounts(response?.data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    SetPopupLoading(false);
    setOpen(true);
  };

  const handleAssignModel = (account_id) => {
    setOpenAssign(true);
    axios.get(BACKEND_BASE_URL + `v1/api/account/${account_id}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          setSelectedUsers(response.data.user_assign || []);  // Set default value as array
          setSelectedRenewalPeople(response.data.renewal_person || []);  // Set default value as array
          setSelectedBranch(response.data.branch || null);  // Set default value

          SetselectedAssignAccount(response.data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occurred while fetching account data.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        setError(error?.response?.data?.message);
        toast.error("Error occurred while fetching account data.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleTabChange = (val) => {
    setTab(val);
  };

  const getRowId = (row) => row.id;

  const columns = [
    {
      field: "csn",
      headerName: "CSN",
      width: 150,
      renderCell: (params, index) => (
        <div>
          <button
            onClick={() => handleOpenModel(params?.row.id)}
            className="action-button bg-white text-black px-3 py-1 rounded"
          >
            {params.value}
          </button>
        </div>
      ),
    },
    {
      field: "user_assign",
      headerName: "BD Person Name",
      width: 160,
      renderCell: (params) => (
        <div>
          {params.value && params.value.length > 0 ? (
            params.value.join(", ")
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    {
      field: "name",
      headerName: "Account Name",
      width: 160,
      // renderCell: (params) => {
      //   const { value: name } = params;
      //   const maxChars = 20;

      //   return (
      //     <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
      //       {name?.length > maxChars ? name : name?.slice(0, maxChars)}
      //     </div>
      //   );
      // },
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
          <button
            className="text-red-600"
            onClick={() => navigate(`/update_account/${params.id}`)}
          >
            <span className="table-cell-truncate">{params.value}</span>
          </button>
        </Tooltip>
      ),
    },
    {
      field: "associated_account",
      headerName: "Accociated Account",
      width: 160,
      renderCell: (params) => (
        <Tooltip title={params.value && params.value.length > 0 ? (
          params.value.join(", ")
        ) : (
          <span style={{ color: "red" }}>Undefined</span>
        )}>
        <div>
          {params.value && params.value.length > 0 ? (
            params.value.join(", ")
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
        </Tooltip>

      ),
    },
    { field: "industryGroup", headerName: "Industry", width: 100 },
    {
      field: "industrySegment",
      headerName: "Segment",
      width: 160,
      renderCell: (params) => {
        const { value: industrySegment } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {industrySegment?.length > maxChars
              ? industrySegment
              : industrySegment?.slice(0, maxChars)}
          </div>
        );
      },
    },
    {
      field: "industrySubSegment",
      headerName: "Sub Segment",
      width: 160,
      renderCell: (params) => {
        const { value: industrySubSegment } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {industrySubSegment?.length > maxChars
              ? industrySubSegment
              : industrySubSegment?.slice(0, maxChars)}
          </div>
        );
      },
    },
    {
      field: "address1",
      headerName: "Address",
      width: 160,
      renderCell: (params) => {
        const { value: address1 } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {address1?.length > maxChars
              ? address1
              : address1?.slice(0, maxChars)}
          </div>
        );
      },
    },
    { field: "city", headerName: "City", width: 150 },
    { field: "email", headerName: "Email", width: 150 },
    { field: "phone", headerName: "Contact No", width: 150 },
    { field: "status", headerName: "Autodesk Account Status", width: 120 },
    { field: "contract_status", headerName: "Trisita Account Status", width: 120 },
    { field: "buyingReadinessScore", headerName: "Rediness Score", width: 130 },  

    {
      field: "renewal_person",
      headerName: "Renewal Person Name",
      width: 160,
      renderCell: (params) => (
        <div>
          {params.value && params.value.length > 0 ? (
            params.value.join(", ")
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },

    {
      field: "branch",
      headerName: "Branch",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },

    ...(userType && userType === "Superadmin"
    ? [{
      field: "",
      headerName: "Action",
      width: 150,
      renderCell: (params, index) => (
        <div className="flex items-center w-full justify-center">
          <button
            onClick={() => handleAssignModel(params?.row.id)}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded"
          >
            Assign
          </button>
        </div>
      ),
    }] :
    []),
  ];

  const contract_getRowId = (row) => row.id;
  const contract_columns = [
    { field: "id", headerName: "ID" },
    { field: "contract_number", headerName: "Contract Number" },
    { field: "contract_startDate", headerName: "Start Date" },
    { field: "contract_endDate", headerName: "End Date" },
    { field: "contract_term", headerName: "Term" },
    { field: "billingBehavior", headerName: "billingBehavior" },
    { field: "subType", headerName: "subType" },
    { field: "contract_duration", headerName: "contract_duration" },
  ];

  const labels = Object.keys(reddinessRangeAccounts);
  const seriesData = Object.values(reddinessRangeAccounts).map(
    (arr) => arr.length
  );

  const ChartData = {
    series: seriesData,
    options: {
      chart: {
        type: "donut",
        events: {
          click(event, chartContext, config) {
            
          },
        },
      },
      colors: ["#5372b9", "#90be82", "#fdc66d", "#fa7870", "#6eb8d8"],
      labels: labels,
      legend: {
        show: true,
        position: "right",
      },
      dataLabels: {
        enabled: true,
        formatter: function (val, opts) {
          return `score < ${opts.w.globals.labels[opts.seriesIndex]}`;
        },
        offsetY: -20,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
    },
  };

  const chartDataList = topCities.map((cityData) => {
    const cityName = cityData.city;
    
    const cityStats = cityData.counts;
    return {
      city: cityName,
      active: cityStats.Active,
      expired: cityStats.Expired,
    };
  });

  const barChartData = {
    series: [
      {
        name: "Active",
        data: chartDataList.map((item) => item.active),
      },
      {
        name: "Expired",
        data: chartDataList.map((item) => item.expired),
      },
    ],
    options: {
      colors: ["#5372b9", "#90be82", "#9C27B0"],
      chart: {
        events: {
          click(event, chartContext, config) {
            if (config.seriesIndex >= 0 && config.dataPointIndex >= 0) {
              const xKey_city = chartDataList[config.dataPointIndex].city;
              const ydata = config.config.series[config.seriesIndex].name;
              HandleCityChartData(xKey_city, ydata);
            }
          },
        },
        type: "bar",
        width: "100%",
        stacked: true,
        toolbar: {
          show: true,
        },
        zoom: {
          enabled: true,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          borderRadius: 1,
          dataLabels: {
            total: {
              enabled: true,
              style: {
                fontSize: "13px",
                fontWeight: 900,
              },
            },
          },
        },
      },
      xaxis: {
        categories: chartDataList.map((item) => item.city),
      },
      legend: {
        position: "right",
        offsetY: 40,
      },
      fill: {
        opacity: 1,
      },
    },
  };

  const handleLinkClick = (data, name) => {
    const chartData = [];
    for (const label in data) {
      if (data.hasOwnProperty(label)) {
        const main_value = data[label];
        const value = main_value.active + main_value.inactive;
        chartData.push({ label, value });
      }
    }
    setLineChartName(name);
    setLineChartData(chartData);
  };

  const HandleCityChartData = (city, status) => {
    let selected_city;
    if (city==="null"){
      selected_city = null;
    }
    else{
      selected_city = city
    }
    const filteredAccounts = allaccountdata.filter(
      (account) => account.contract_status === status && account.city === selected_city
    );
    const filteredAccountsAllStatus = allaccountdata.filter(
      (account) => account.city === city
    );
    SetAccountData(filteredAccounts);
    SetFilterAccountData(filteredAccountsAllStatus)
    ManageAllAcountsData(city);
    setStatus(status);
  };

  const LineChartData = {
    series: [
      {
        name: "Accounts",
        data: linechartData?.map((item) => item.value),
      },
    ],
    options: {
      chart: {
        type: "line",
        width: "100%",
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "straight",
      },
      title: {
        text: "",
        align: "left",
      },
      grid: {
        row: {
          colors: ["#f3f3f3", "transparent"],
          opacity: 0.5,
        },
      },
      xaxis: {
        categories: linechartData?.map((item) => item.label),
      },
    },
  };

  const handleTopIndustryClick = (account, status, industry) => {
    setIsActive(industry + status);
    if (status === "active") {
      SetAccountData(account.activeAccounts);
    } else if (status === "inactive") {
      SetAccountData(account.inactiveAccounts);
    } else {
      let totalaccounts = [];
      account?.activeAccounts.map((item) => totalaccounts.push(item));
      account?.inactiveAccounts.map((item) => totalaccounts.push(item));
      SetAccountData(totalaccounts);
    }
  };

  const handleAllAccountsClick = (account, status, selected) => {
    setIsActive(selected);
    if (status === "active") {
      SetAccountData(totalactiveAccounts);
    } else if (status === "inactive") {
      SetAccountData(totalinactiveAccounts);
    } else {
      SetAccountData(account);
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value) {
      const filteredData = filterData(accountData);
      SetAccountData(filteredData);
    } else {
      ExportAccounts();
    }
  };


  const handleBranchFilter = (branch) => {

    if (branch?.label) {
      const filteredResults =  allaccountdata.filter((item) => {
        const name = item["branch"];
          return name === branch.label;
      });
      SetAccountData(filteredResults);
      SetFilterAccountData(filteredResults)
      setStatus("All Status")

    }
    else {
      setStatus("All Status")
      ExportAccounts();
    }
    
  };

  const filterData = (data) => {
    return data.filter((row) => {
      return Object.values(row).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  const insight_getRowId = (row) => row.contractNumber;
  const insight_columns = [
    { field: "contractNumber", headerName: "Contract#" },
    { field: "engagementCategory", headerName: "risk Cat" },
    { field: "premiumFlag", headerName: "Preminum Flag" },
    { field: "productLineCode", headerName: "PLC" },
    { field: "riskCategory", headerName: "Risk Cat" },
    { field: "seatsInUseProRated", headerName: "seatsInUseProRated" },
    { field: "seatsPurchased", headerName: "seatsPurchased" },
    { field: "tenantId", headerName: "tenantId" },
    { field: "usageRate", headerName: "usageRate" },
    { field: "usersAssignedProRated", headerName: "usersAssignedProRated" },
  ];

  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setStatus(selectedValue);
    if (selectedValue) {
      if (selectedValue === "All Status") {
        SetAccountData(filterAccountData);
      } else {
        const filteredResults = filterAccountData.filter((item) => {
          const subscriptionStatus = item["contract_status"];
          return subscriptionStatus === selectedValue;
        });

        SetAccountData(filteredResults);
      }
    } else {
      ExportAccounts();
    }
  };

  const HandleAllocate = (account_id) => {
    const requestBody = {
      "user_assign": selectedUsers.map(user => user.id),
      "branch": selectedBranch?.id || null,
      "renewal_person": selectedRenewalPeople.map(user => user.id),
    };

    axios.put(BACKEND_BASE_URL + `v1/api/account/${account_id}`, requestBody, { headers })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Account Allocated", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occurred while allocating accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error("Error occurred while allocating accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    setOpenAssign(false);
    ExportAccounts();
  };

  const formatLastUpdated = (timestamp) => {
    if (!timestamp) return 'Not Available';
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const formattedDate = formatLastUpdated(lastUpdated);

  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  return (
    <>
      <Box m="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Header
            title="Account Information of the end customers"
            subtitle="List of all Autodesk End Customers. The boxes below shows all combined industry details."
          />
        </Box>
        <Box sx={{display:"flex" , gap:'10px' , justifyContent:'end'}}>
        <div className="relative group">
            <span className="text-gray-600">Last Updated</span>
              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-max px-4 py-2 bg-black text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                {formattedDate}
              </div>
          </div>
          
        <Box  display="flex" marginBottom={2} justifyContent="end" alignItems="center">
          <button
            onClick={() => ExportAccounts()}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] w-[100px] text-center, text-lg"
          >
            All 
          </button>       
        </Box>

        <Box  display="flex" marginBottom={2} justifyContent="end" alignItems="center">
            {branches ?  (
              <Autocomplete
                value={searchBranch}
                onChange={(event, newValue) => {
                  setSearchBranch(newValue);
                  handleBranchFilter(newValue);
                }}
                options={branches}
                autoSelect
                getOptionLabel={(option) => option.label}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select a Branch" variant="outlined" />
                )}
              />
            ) :(
              // <CircularProgress />
              <Autocomplete
                value={null} 
                disabled 
                autoSelect
                getOptionLabel={(option) => option.label}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select a Branch"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {params.InputProps.endAdornment}
                          <Typography variant="body2" color="textSecondary">
                            Loading...
                          </Typography>
                        </>
                      ),
                    }}
                  />
                )}
              />
            
            )
          }
      </Box>
        
          <Box
            sx={{
              
              height: "52px",
              width: 100,
              minWidth: "200px",
            }}
          >
            <Select
              value={status}
              inputProps={{ "aria-label": "Without label" }}
              onChange={handleChange}
              sx={{ width: "100%", height: "52px" }}
              SelectDisplayProps={{
                style: { paddingTop: 8, paddingBottom: 8 },
              }}
              disabled={isLoading}
            >
              <MenuItem value={"All Status"}>All Status</MenuItem>
              <MenuItem value={"Active"}>Active</MenuItem>
              <MenuItem value={"Expired"}>Expired</MenuItem>
            </Select>
          </Box>
          <input
            type="text"
            placeholder="Search..."
            value={searchTerm}
            onChange={(e) => handleSearch(e.target.value)}
            className="max-w-[340px] w-full h-[52px] border px-3 outline-none"
          />
        </Box>
        <Box>
          <Box className="flex">
            <Box
              item
              xs={6}
              md={2}
              className="flex-grow"
              sx={{
                borderLeft: "2px solid",
                background: colors.grey[900],
                padding: 2,
                textAlign: "center",
                borderColor: colors.blueAccent[400],
              }}
            >
              <Typography
                variant="h4"
                sx={{ fontWeight: "600", color: colors.grey[300] }}
                component="h4"
              >
                All
              </Typography>
              <Box sx={{ textAlign: "center", marginTop: 1 }}>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600" }}
                  component="h6"
                  onClick={() =>
                    handleAllAccountsClick(allaccountdata, "active", "isactive")
                  }
                >
                  <span
                    style={{
                      color:
                        isActive === "isactive" ? "#0000FF" : colors.grey[300],
                      cursor: "pointer",
                    }}
                    className="mr-[2px]"
                  >
                    {totalactiveAccounts?.length}
                  </span>
                  active account
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600" }}
                  component="h6"
                  onClick={() =>
                    handleAllAccountsClick(
                      allaccountdata,
                      "inactive",
                      "isinactive"
                    )
                  }
                >
                  <span
                    style={{
                      color:
                        isActive === "isinactive"
                          ? "#0000FF"
                          : colors.grey[300],
                      cursor: "pointer",
                    }}
                    className="mr-[2px]"
                  >
                    {totalinactiveAccounts?.length}
                  </span>{" "}
                  inactive account
                </Typography>
                <Typography
                  variant="h6"
                  sx={{ fontWeight: "600", cursor: "pointer" }}
                  component="h6"
                  onClick={() =>
                    handleAllAccountsClick(allaccountdata, "total", "All")
                  }
                >
                  <span
                    style={{
                      color: isActive === "All" ? "#0000FF" : colors.grey[300],
                      cursor: "pointer",
                    }}
                    className="mr-[2px]"
                  >
                    {totalactiveAccounts?.length +
                      totalinactiveAccounts?.length}
                  </span>{" "}
                  total accounts
                </Typography>
              </Box>
            </Box>

            {Object.entries(industryGroupsAccounts).map(([industry, data]) => (
              <Box
                item
                xs={6}
                md={2}
                key={industry}
                className="flex-grow "
                sx={{
                  borderLeft: "2px solid",
                  background: colors.grey[900],
                  padding: 2,
                  textAlign: "center",
                  borderColor: colors.blueAccent[400],
                }}
              >
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "600", color: colors.grey[300] }}
                  component="h4"
                >
                  {industry}
                </Typography>
                <Box sx={{ textAlign: "center", marginTop: 1 }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "600" }}
                    component="h6"
                    onClick={() =>
                      handleTopIndustryClick(data, "active", industry)
                    }
                  >
                    <span
                      style={{
                        color:
                          isActive === industry + "active"
                            ? "#0000FF"
                            : colors.grey[300],
                        cursor: "pointer",
                      }}
                      className="mr-[2px]"
                    >
                      {data.activeAccounts.length}{" "}
                    </span>
                    active account
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "600" }}
                    component="h6"
                    onClick={() =>
                      handleTopIndustryClick(data, "inactive", industry)
                    }
                  >
                    <span
                      style={{
                        color:
                          isActive === industry + "inactive"
                            ? "#0000FF"
                            : colors.grey[300],
                        cursor: "pointer",
                      }}
                      className="mr-[2px]"
                    >
                      {data.inactiveAccounts.length}{" "}
                    </span>
                    inactive account
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "600" }}
                    component="h6"
                    onClick={() =>
                      handleTopIndustryClick(data, "total", industry)
                    }
                  >
                    <span
                      style={{
                        color:
                          isActive === industry + "total"
                            ? "#0000FF"
                            : colors.grey[300],
                        cursor: "pointer",
                      }}
                      className="mr-[2px]"
                    >
                      {data.activeAccounts.length +
                        data.inactiveAccounts.length}{" "}
                    </span>
                    total accounts
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          m="8px 0 0 0"
          width="100%"
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          {/* <Button variant="contained" onClick={downloadCSV} style={{ padding: '5px', margin: '10px' }}>
      Download CSV</Button> */}
          {isLoading ? (
            <p>Loading data...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <DataGrid
              rows={accountData}
              columns={columns}
              getRowId={getRowId}
              components={{
                Toolbar: () => (
                  <div>
                    <GridToolbar
                    csvOptions={{
                      fileName: `account_trisita_${user.first_name} ${user.last_name}_${currDate}_${currTime}`,
                      fields: ['csn', 'user_assign', 'name', 'industryGroup', 'industrySegment', 'industrySubSegment', 'address1', 'city', 'email', 'phone', 'status', 'contract_status', 'buyingReadinessScore', 'renewal_person', 'branch'],
                    }} />
                  </div>
                )
              }}
              checkboxSelection 
              disableRowSelectionOnClick                                                               
            />
          )}
        </Box>
        <Grid container sx={{ marginTop: 3 }} spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                border: "0.8px solid",

                borderColor: colors.grey[900],
              }}
            >
              <Box
                sx={{
                  backgroundColor: colors.primary[400],
                  borderBottom: "0.8px solid",
                  borderColor: colors.grey[900],
                  padding: 2,
                  display: "flex",
                  alignItems: "end",
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600" }}
                  component="h5"
                >
                  Trend of number of accounts by {linechartname}
                </Typography>
                <button
                  className={`!ml-[25%] text-blue-500 ${
                    linechartname === "Industry" ? "font-bold" : ""
                  }`}
                  onClick={() => handleLinkClick(byIndustry, "Industry")}
                >
                  By Industry group
                </button>
                <button
                  className={`!ml-[15px] text-blue-500 ${
                    linechartname === "Industry Segment" ? "font-bold" : ""
                  }`}
                  onClick={() => handleLinkClick(bySegment, "Industry Segment")}
                >
                  By Segment
                </button>
                <button
                  className={`!ml-[15px] text-blue-500 ${
                    linechartname === "Industry Sub-Segment" ? "font-bold" : ""
                  }`}
                  onClick={() =>
                    handleLinkClick(bySubSegment, "Industry Sub-Segment")
                  }
                >
                  By Sub Segment
                </button>
              </Box>
              <Box
                id="chart"
              >
                {isLoading ? (
                  <p>Loading data...</p>
                  ) : (
                    <ReactApexChart
                      options={LineChartData.options}
                      series={LineChartData.series}
                      type="line"
                      height={350}
                    />
                  )}
                
              </Box>

            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                border: "0.8px solid",

                borderColor: colors.grey[900],
              }}
            >
              <Box
                sx={{
                  backgroundColor: colors.primary[400],
                  borderBottom: "0.8px solid",
                  borderColor: colors.grey[900],
                  padding: 2,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600" }}
                  component="h5"
                >
                  Show by rediness scores
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  height: "365px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                id="chart"
              >
                {isLoading ? (
                  <p>Loading data...</p>
                  ) : (
                    <ReactApexChart
                      options={ChartData.options}
                      series={ChartData.series}
                      type="donut"
                      width={380}
                    />
                  )}
                
              </Box>

              {/* <ReactApexChart options={options} series={options.series} type={options.chart.type} height={options.chart.height} /> */}
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                border: "0.8px solid",

                borderColor: colors.grey[900],
              }}
            >
              <Box
                sx={{
                  backgroundColor: colors.primary[400],
                  borderBottom: "0.8px solid",
                  borderColor: colors.grey[900],
                  padding: 2,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600" }}
                  component="h5"
                >
                  Top 12 cities by number of account trend showing between
                  active and inactive
                </Typography>
              </Box>
              <Box
                id="chart"
              >
                {isLoading ? (
                  <p>Loading data...</p>
                  ) : (
                    <ReactApexChart
                      options={barChartData.options}
                      series={barChartData.series}
                      type="bar"
                      height={350}
                      events={{
                        dataPointSelection: (event, chartContext, config) => {
                        },
                      }}
                    />
                  )}

                
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{ backgroundColor: "red" }}
      >
        <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[1150px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
          <div className="h-full">
            <div className="text-black flex border-b border-gray-300">
              <Link
                to={"#"}
                className={`${
                  tab === 1
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "text-blue-500"
                } p-2 border-gray-300 text-center`}
                onClick={() => handleTabChange(1)}
              >
                End Customer
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 2
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "text-blue-500"
                } p-2 border-gray-300 text-center`}
                onClick={() => handleTabChange(2)}
              >
                Details
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 3
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "text-blue-500"
                } p-2 border-gray-300 text-center`}
                onClick={() => handleTabChange(3)}
              >
                Contracts
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 4
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "text-blue-500"
                } p-2 border-gray-300 text-center`}
                onClick={() => handleTabChange(4)}
              >
                Insight Summary
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 5
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "text-blue-500"
                } p-2 border-gray-300 text-center`}
                onClick={() => handleTabChange(5)}
              >
                Usage Summary
              </Link>
            </div>
            <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">
              {tab === 1 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    End Customer Details
                  </h1>
                  {endCustomerAccounts ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]">CSN</div>
                          <div className="whitespace-pre">
                            {
                              endCustomerAccounts?.endcustomer[0]
                                ?.endCustomerCsn
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.name}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Address Line 1</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.address1}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Address Line 2</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.address2}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">City</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.city}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">State</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.stateProvince}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Country</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.country}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Postal</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.endcustomer[0]?.postalCode}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Contract Manager Details
                  </h1>
                  {endCustomerAccounts ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]">First Name</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.contract_manager[0]?.first}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Last Name</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.contract_manager[0]?.last}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Email</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.contract_manager[0]?.email}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Phone</div>
                          <div className="whitespace-pre">
                            {endCustomerAccounts?.contract_manager[0]?.phone}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 2 && (
                <>
                  {/* <div className="text-black text-center my-0">Details</div> */}
                  {selectedAccount ? (
                    <>
                      <div className="flex">
                        {/* left  */}
                        <div className="w-1/2">
                          <div className="flex my-4">
                            <div className="w-[200px]">Name</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.name}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Address1</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.address1}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">City</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.name}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">ADK main contact</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.autodeskMainContact}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Flex Flag</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.flexCustomerFlag}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Flex Flag?</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.flexCustomerFlag}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Industry</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.industry}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Industry Segment</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.industrySegment}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Named Account</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.isNamedAccount}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Phone Number</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.phoneNumber}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Sales Region</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.salesRegion}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Victim CSN</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.victimCsn}
                            </div>
                          </div>
                        </div>

                        {/* right */}
                        <div className="w-1/2">
                          <div className="flex my-4">
                            <div className="w-[200px]">Total Contracts </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.totalNumberOfActiveContracts}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Address2 </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.address2}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Country/Geo </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.countryCode}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Contact email </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.autodeskMainContactEmail}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Individual Flag </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.individualFlag}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Individual Flag </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.individualFlag}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Industry Group </div>
                            <div className="whitespace-pre">
                              {selectedAccount?.industryGroup}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Sub Segment</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.industrySubSegment}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Parent Account CSN</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.parentIsNamedAccount}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Postal</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.postal}
                            </div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">Status</div>
                            <div className="whitespace-pre">
                              {selectedAccount?.status}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <p><span>Name : {selectedAccount?.name}</span> <span>Total Contracts : {selectedAccount?.totalNumberOfActiveContracts}</span></p>
                      <p><span>Address1 : {selectedAccount?.address1}</span> <span>Address2 : {selectedAccount?.address2}</span></p>
                      <p><span>City : {selectedAccount?.name}</span> <span>Country/Geo : {selectedAccount?.countryCode} {selectedAccount?.geo}</span></p>
                      <p><span>ADK main contact : {selectedAccount?.autodeskMainContact}</span> <span>Contact email : {selectedAccount?.autodeskMainContactEmail}</span></p>
                      <p><span>Flex Flag : {selectedAccount?.flexCustomerFlag}</span> <span>Individual Flag : {selectedAccount?.individualFlag}</span></p>
                      <p><span>Flex Flag? : {selectedAccount?.flexCustomerFlag}</span> <span>Individual Flag : {selectedAccount?.individualFlag}</span></p>
                      <p><span>Industry : {selectedAccount?.industry}</span> <span>Industry Group : {selectedAccount?.industryGroup}</span></p>
                      <p><span>Industry Segment : {selectedAccount?.industrySegment}</span> <span>Sub Segment : {selectedAccount?.industrySubSegment}</span></p>
                      <p><span>Named Account : {selectedAccount?.isNamedAccount}</span> <span>Parent Account CSN : {selectedAccount?.parentIsNamedAccount}</span></p>
                      <p><span>Phone Number : {selectedAccount?.phoneNumber}</span> <span>Postal : {selectedAccount?.postal}</span></p>
                      <p><span>Sales Region : {selectedAccount?.salesRegion}</span> <span>Status : {selectedAccount?.status}</span></p>
                      <p><span>Victim CSN : {selectedAccount?.victimCsn}</span></p> */}
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 3 && (
                <>
                  {popupLoading ? (
                    <p>Loading data...</p>
                  ) : (
                    <DataGrid
                      rows={contracts}
                      columns={contract_columns}
                      getRowId={contract_getRowId}
                      components={{ Toolbar: GridToolbar }}
                    />
                  )}
                </>
              )}
              {tab === 4 && (
                <>
                  {popupLoading ? (
                    <p>Loading data...</p>
                  ) : (
                    <DataGrid
                      rows={insightSummary}
                      columns={insight_columns}
                      getRowId={insight_getRowId}
                      components={{ Toolbar: GridToolbar }}
                    />
                  )}
                </>
              )}
              {tab === 5 && (
                <>
                  <Link to="/get_usage">
                    {" "}
                    <h3 className=" text-center text-[blue] font-bold">Click Here</h3>
                    <hr className="my-3 h-0.5 bg-black"/>
                  </Link>
                  <div className="text-black text-center my-0 ">
                    To go Get Usage Page
                  </div>
                </>
              )}
            </div>
            <div className="border-t">
              <div className="pt-4 text-right">
                <button
                  className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                  onClick={() => {
                    setOpen(false);
                    SetselectedAccount(null);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
      open={openassign}
      onClose={() => setOpenAssign(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ backgroundColor: "red" }}
    >
      <div className="relative -translate-x-2/4 bg-white h-[350px] max-w-[600px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
        <div className="absolute top-2 right-3 cursor-pointer">
          <MdClose
            className="cursor-pointer text-xl"
            onClick={() => {
              setOpenAssign(false);
              SetselectedAssignAccount(null);
            }}
          />
        </div>
        <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
          Allocate User and Branch
        </h3>
        <Box sx={{ paddingY: '30px' }}>
          <Grid container spacing={2}>
            <Grid item sx={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>BD Person :</Typography>
            </Grid>
            <Grid item sx={12} sm={9}>
              <Autocomplete
                disablePortal
                multiple  // Allow multiple selections
                id="combo-box-demo"
                options={allUser}
                value={selectedUsers}
                getOptionLabel={(option) => option.first_name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select User" variant="outlined" />
                )}
                onChange={(event, newValue) => {
                  setSelectedUsers(newValue);
                }}
              />
            </Grid>

            <Grid item sx={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Branch :</Typography>
            </Grid>
            <Grid item sx={12} sm={9}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={allBranch}
                getOptionLabel={(option) => option.branch_name}
                value={selectedBranch}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select City" variant="outlined" />
                )}
                onChange={(event, newValue) => {
                  setSelectedBranch(newValue);
                }}
              />
            </Grid>

            <Grid item sx={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Renewal Person :</Typography>
            </Grid>
            <Grid item sx={12} sm={9}>
              <Autocomplete
                disablePortal
                multiple  // Allow multiple selections
                id="combo-box-demo"
                options={allUser}
                value={selectedRenewalPeople}
                getOptionLabel={(option) => option.first_name}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select Renewal Person" variant="outlined" />
                )}
                onChange={(event, newValue) => {
                  setSelectedRenewalPeople(newValue);
                }}
              />
            </Grid>
          </Grid>
          <div className="max-w-[447px] w-full text-right mt-4">
            <Button
              className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
              onClick={() => HandleAllocate(selectedAssignAccount?.id)}
            >
              Allocate
            </Button>
          </div>
        </Box>
      </div>
    </Modal>
      {
        isLoading && <Loader />
      }
    </>
  );
};

export default ThirdPartyAccount;
