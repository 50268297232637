import React, { useState, useContext, createContext } from "react";

const CSNContext = createContext();

export const CSNProvider = ({ children }) => {
  const [csn, setCSN] = useState("");

  const HandleCSN = (csn) => {
    setCSN(csn)
  } 

  return (
    <CSNContext.Provider value={{ csn, HandleCSN }}>
      {children}
    </CSNContext.Provider>
  );
};

export const useCSN = () => {
  return useContext(CSNContext);
};