import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { useNavigate } from "react-router-dom";

const Quotation = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [quotationData, setQuotationData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const { token, user } = useUser();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    console.log("___________________dfsdsdfds");
    if (token) {
      getQuotationData();
    }
  }, [token]);

  const getQuotationData = () => {
    console.log("__________________log");
    axios
      .get(BACKEND_BASE_URL + `v1/product/quotation/`, { headers })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response)
        if (response.status === 200) {
          setQuotationData(response?.data?.quotation);
          setLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occurred while displaying quotations.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        setLoading(false);
        toast.error("Error occurred while displaying quotations.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getRowId = (row) => row.id;

  const columns = [
    { field: "quotation_date", headerName: "Quotation Date", width: 150 },
    { field: "quotation_no", headerName: "Quotation No", width: 150 },
    { field: "name", headerName: "Name", width: 150 },
    { field: "general_total", headerName: "General Total", width: 150 },
    { field: "sales_stage", headerName: "Sales Stage", width: 150 },
    { field: "opportunity", headerName: "Opportunity", width: 150 },
    { field: "account", headerName: "Account", width: 150 },
    { field: "billing_contact", headerName: "Billing Contact", width: 150 },
    { field: "valid_until", headerName: "Valid Until", width: 150 },
    { field: "updated_at", headerName: "Updated At", width: 150 },
    { field: "updated_by", headerName: "Updated By", width: 150 },
    { field: "created_at", headerName: "Created At", width: 150 },
    { field: "created_by", headerName: "Created By", width: 150 },
  ];

  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  const handleSearch = (value) => {
    setSearchTerm(value);

    if (value) {
      const filteredData = filterData(quotationData);
      setQuotationData(filteredData);
    } else {
      getQuotationData();
    }
  };

  const filterData = (data) => {
    return data.filter((row) => {
      return Object.values(row).some(
        (value) =>
          value &&
          value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      );
    });
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Quotation Master" subtitle="Manage your quotations" />
      </Box>
      <Box sx={{ display: "flex", gap: '10px', justifyContent: 'end' }}>
        <Box display="flex" marginBottom={2} justifyContent="end" alignItems="center">
          <button
            onClick={() => navigate('/add_quotation')}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] text-center text-lg"
            style={{ whiteSpace: 'nowrap' }}
          >
            Add Quotation
          </button>
        </Box>
        <input
          type="text"
          placeholder="Search..."
          value={searchTerm}
          onChange={(e) => handleSearch(e.target.value)}
          className="max-w-[340px] w-full h-[52px] border px-3 outline-none"
        />
      </Box>

      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          rows={quotationData}
          columns={columns}
          getRowId={getRowId}
          components={{
            Toolbar: () => (
              <div>
                <GridToolbar
                  csvOptions={{
                    fileName: `quotation_master_${user.first_name}_${user.last_name}_${currDate}_${currTime}`,
                    fields: ["quotation_date", "quotation_no", "name", "general_total", "sales_stage", "opportunity", "account", "billing_contact", "valid_until", "created_at", "updated_at"],
                  }}
                />
              </div>
            )
          }}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default Quotation;
