import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import { mockDataTeam } from "../../data/mockData.js";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme, MenuItem, FormControl, Select, InputLabel } from "@mui/material";


const OrderLoadingPO = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()


  const {token, user} = useUser()
  const [orderType, setOrderType] = useState("");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
    navigate('/add_order_loading_po', { state: { orderType: event.target.value } });
  };

  useEffect(() => {
    if (token){
    }
  }, [token]);


  const getRowId = (row) => row.id;

  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Order Loading PO" subtitle="we are in the same team" />
      </Box>
      <Box sx={{display:"flex"  , gap:'10px' , justifyContent:'end'}}>
      <Box display="flex" marginBottom={2} justifyContent="end" alignItems="center">
          <button
            onClick={() => navigate('/order_loading_po_list')}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] text-center text-lg"
            style={{ whiteSpace: 'nowrap' }}
          >
            Order Loading PO History
          </button>       
        </Box>
        </Box>
        <Box display="flex" alignItems="center" gap="20px" mb="20px">
        <Typography>Select a type of Order</Typography>
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Order Type</InputLabel>
          <Select
            value={orderType}
            onChange={handleOrderTypeChange}
            label="Order Type"
          >
            <MenuItem value="EndUser">EndUser</MenuItem>
            <MenuItem value="ThirdParty">3rd Party</MenuItem>
          </Select>
        </FormControl>
      </Box>
      
      {/* <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
         <DataGrid
              rows={userData}
              columns={columns}
              getRowId={getRowId}
              components={{
                Toolbar: () => (
                  <div>
                    <GridToolbar
                    csvOptions={{
                      fileName: `product_trisita_${user.first_name} ${user.last_name}_${currDate}_${currTime}`,
                      fields : ["name", "serial_number", "account_name", "manufacturer", "product_type", "product_category", "price", "product_status", "warrenty_startDate", "warrenty_endDate", "warrenty_period", "assigned_to", "created_at", "updated_at"]                ,
                      }} />
                  </div>
                )
              }}
              checkboxSelection 
              disableRowSelectionOnClick                                                               
            />
      </Box> */}
    </Box>
  );
};

export default OrderLoadingPO;
