import React, { useState, useEffect } from "react";
import Header from "../../components/Header.jsx";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { useNavigate } from "react-router-dom";
import { useCSN } from "../../components/CSNProvider.jsx";
import { Box, Typography, useTheme, Modal } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import moment from 'moment';
import { MdClose } from "react-icons/md";


const RenewEmailHistory = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { token } = useUser();
  const [renewalData, SetRenewalData] = useState([]);
  const [loading, SetLoading] = useState(true);
  const [tab, setTab] = useState(1);
  const [selectedRenewal, SetselectedRenewal] = useState(null);
  const [open, setOpen] = useState(false);


  const theme = useTheme();
  const colors = tokens(theme.palette.mode);



  const { csn } = useCSN();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token) {
      GetRenewalHistory()
    }
  }, [csn,token]);

  const GetRenewalHistory = () => {
    axios.get(BACKEND_BASE_URL + `v1/api/renew_email_list/${csn ? `${csn}` : ''}`, { headers })
      .then((response) => {
        if (response.status === 200) {
            SetRenewalData(response?.data?.Response)
            SetLoading(false)
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  const HandleViewDetails = (id) => {
    setOpen(true);
    axios.get(BACKEND_BASE_URL + `v1/api/renewal/${id}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetselectedRenewal(response?.data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

const FormattedDateCell = ({ value }) => {
  const formattedDate = moment(value).format('MMMM DD, YYYY hh:mm:ss A');
  return <div>{formattedDate}</div>;
};

const getRowId = (row) => row.id;

const columns = [
    
    { field: "subscription", headerName: "#Subscription", width: 180,
    renderCell: (params, index) => (
      (
        <div>
          <button
            className="action-button bg-white text-black px-3 py-1 rounded"
          >
            {params.value}
          </button>
        </div>
      )
    ),},
    {
        field: "user_assign",
        headerName: "BD Person Name",
        width: 100,
        renderCell: (params) => (
            <div>
          {params.value && params.value ? (
              params.value
              ) : (
                  <span style={{ color: "red" }}>Undefined</span>
                  )}
        </div>
      ),
    },
    { field: "account_name", headerName: "Account", width: 300,
    renderCell: (params) => {
        const { value: Account } = params;
        const maxChars = 20;
        
        return (
            <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {Account?.length > maxChars ? Account : Account?.slice(0, maxChars)}
          </div>
        );
    },
    },
    { field: "trigger_at", headerName: "Trigger At", width: 180},
    { field: "recipient", headerName: "Recipient", width: 180},
    { field: 'sent_at', headerName: 'Sent at', width: 220, renderCell: (params) => <FormattedDateCell {...params} /> },
    { field: "AccountStatus", headerName: "Autodesk Account Status", width: 180 },
    { field: "contract_status", headerName: "Trisita Account Status", width: 180 },
    {
      field: "branch",
      headerName: "Branch",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    { field: "status", headerName: "Message Status", width: 140 },
    {
      field: "",
      headerName: "Action",
      width: 150,
      renderCell: (params, index) => (
        <div className="flex items-center w-full justify-center">
          <button
            onClick={() => HandleViewDetails(params?.row.id)}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded"
          >
            View Details
          </button>
        </div>
      ),
    }
  ];


  return (
    <>
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Header title="Renew Email History" subtitle="welcome to you Team" />
      </Box>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
        <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Table Data
          </Typography>
        </Box>
        {loading ? (
          <p>Loading data...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <DataGrid
            rows={renewalData}
            columns={columns}
            getRowId={getRowId}
            components={{ Toolbar: GridToolbar }}
            checkboxSelection 
            disableRowSelectionOnClick
            
          />
        )}
      </Box>
    </Box>
      <Modal
    open={open}
    onClose={() => setOpen(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    classes={{ backgroundColor: "red" }}
  >
    <div className="relative -translate-x-2/4 bg-white h-[300px] max-w-[800px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
      <div className="absolute top-2 right-3 cursor-pointer">
        <MdClose
          className="cursor-pointer text-xl"
          onClick={() => {
            setOpen(false);
            SetselectedRenewal(null);
          }}
        />
      </div>
      <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
        Details Page
      </h3>
      <div>
          <p>Recipient: {selectedRenewal?.recipient}</p>
          <p>Subject: {selectedRenewal?.subject}</p>
          <p>Message : </p><div dangerouslySetInnerHTML={{ __html:selectedRenewal?.message_body}} />
          <p>Status: {selectedRenewal?.status}</p>
        </div>
          
    </div>
    </Modal>
    </>
  );
};

export default RenewEmailHistory;
