import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useCSN } from "../../components/CSNProvider.jsx";
import ReactApexChart from "react-apexcharts";
import { BoyTwoTone } from "@mui/icons-material";
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";
import { useUser } from "../../components/UserProvider.js";
import Loader from "../../components/Loader/Loader.js";


const GetUsage = () => {
  const navigate = useNavigate()
  const { csn } = useCSN();
  const initialState = {
		csn: "",
		from_date: "",
		to_date: ""
	};
  const [formData, setFormData] = useState(initialState);
  const [accountsData, setAccountsData] = useState([])
  const [accountsname, setAccountsName] = useState(null)
  const [tempaccountsname, setTempAccountsName] = useState([{ label: "TATA PROJECTS Ltd", csn: 5101930229 }])
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const {token} = useUser()
  
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const getAccountsData = () => { 
    axios.get(BACKEND_BASE_URL + `v1/api/get_export_accounts/`, {headers})
    .then((response) => {
      if (response.status === 200) {
        setAccountsData(response.data)
        const data = response.data.accounts.map(item => {
          const name = item.name;
          const csn = item.csn;
          return {
            label: name,
            csn: csn,
          };
        });
        const uniqueLabels = new Set();

        const uniqueData = data.filter(item => {
          if (!uniqueLabels.has(item.label)) {
            uniqueLabels.add(item.label);
            return true;
          }
          return false;
        });
        setAccountsName(uniqueData)
      }
    })
   };
  useEffect(() => {
    if (token){
    getAccountsData()
    }
  }, [token])  

  const handleChange = (date, name) => {
   const format_date =  dayjs(date).format('YYYY-MM-DD')
		setFormData({
			...formData,
			[name]:format_date
		})
	};

	const handleSelect = (item) => {
		setFormData({
			...formData,
			csn: item?.csn
		})
	};


  // const handleSubmit = (ccsn) => {
  //     toast.info("Please wait while loading usages", {
  //       position: toast.POSITION.TOP_RIGHT
  //     })
  //     setIsLoading(true)
  //     const apiUrl = `${BACKEND_BASE_URL}v1/api/get_usage_data/${csn ? `${csn}` : ''}`;
  //     axios.post(apiUrl, formData, { headers })
  //       .then((response) => {
  //         if(response.status === 200) {
  //           setIsLoading(false)
  //           navigate(`/usage/${formData.csn}`)
  //         } else {
  //           toast.error(response?.data?.message, {
  //             position: toast.POSITION.TOP_RIGHT
  //           })
  //         }
  //       }).catch((error) => {
  //         toast.error(error?.response?.data?.message, {
  //           position: toast.POSITION.TOP_RIGHT
  //         })
  //       })		

  // }; 
  
  const isGetUsagesButtonDisabled = () => {
    return formData.from_date === '' || formData.to_date === '' || formData.csn === ''; 
  };


  return (
    <>
      <Box m="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Header title="Get Usages" subtitle="welcome to you Team" />
        </Box>
        <Box className="flex items-center">
          <Box className="mr-5 pt-2">
            {/* <Autocomplete
              placeholder="Combo box"
              options={accountsname}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Select a Account" variant="outlined" />
              )}
              onChange={(event, selectedValue) => {
                handleSelect(selectedValue)
            }}
            /> */}
            {accountsname ? (
                  <Autocomplete
                  placeholder="Combo box"
                  options={accountsname}
                  getOptionLabel={(option) => `${option.label} (${option.csn})`}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Select a Account" variant="outlined" />
                  )}
                  onChange={(event, selectedValue) => {
                    handleSelect(selectedValue)
                }}
                />
                ) : (
                  // <CircularProgress />
                  <Autocomplete
                    value={null}
                    disabled
                    autoSelect
                    getOptionLabel={(option) => `${option.label} (${option.csn})`}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an Account"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <Typography variant="body2" color="textSecondary">
                                Loading...
                              </Typography>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
          </Box>
          <Box className="mr-5">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker label="Basic date picker"
                name="from_date"
                value={formData.from_date}
                onChange={(date) => handleChange(date, 'from_date')}  />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
          <Box className="mr-5">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DatePicker"]}>
                <DatePicker label="Basic date picker"
                name="to_date"
                value={formData.to_date}
                onChange={(date) => handleChange(date, 'to_date')} />
              </DemoContainer>
            </LocalizationProvider>
          </Box>
          <Box>
              <Button variant="contained" color="success" className="!py-3.5 !mt-1 min-h-[52px] !text-base" onClick={() => navigate(`/usage/${formData.csn}/${formData.from_date}/${formData.to_date}`)}disabled={isGetUsagesButtonDisabled()} >Get Usages</Button>
          </Box>
        </Box>  
      </Box>
      {
        isLoading && <Loader />
      }
    </>
  );
};

export default GetUsage;
