import React from 'react'
import PieChart from '../../components/PieChart'
import { Box } from '@mui/material';
import Header from '../../components/Header';
const Bar = () => {
    return (
        <Box m="20px" height="75vh" p="2px">
            <Header title="BAR CHART" subtitle="simple bar chart" />
            <PieChart />
        </Box>
    )
}

export default Bar