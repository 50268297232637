import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { Box, InputBase, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useCSN } from "../../components/CSNProvider.jsx";
import ReactApexChart from "react-apexcharts";
import { useParams } from 'react-router-dom';
import { useUser } from "../../components/UserProvider.js";


const Usage = () => {
  const { ccsn, from_date, to_date} = useParams();
  const { csn } = useCSN()
  const initialState = {
		ccsn: ccsn,
    from_date: from_date,
    to_date: to_date
	};
  const [formData, setFormData] = useState(initialState);
  const [loading, SetLoading] = useState(true);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [usageData, SetUsageData] = useState([]);
  const [logincounts, SetLoginCounts] = useState([]);
  const [filterText, setFilterText] = useState('');

  const {token} = useUser()

  const filteredData = usageData?.filter((item) =>
    Object.values(item).some((value) =>
      value.toString().toLowerCase().includes(filterText.toLowerCase())
    )
  );
  const headers = {
		"Authorization": `Bearer ${token}`
	}

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    if (token){
    GetUsages()
    }
	}, [csn, token]);

  const GetUsages = () => {
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_usage_data/${csn ? `${csn}` : ''}`;
    axios.post(apiUrl, formData, { headers })
			.then((response) => {
      if (response.status === 200) {
        SetUsageData(response?.data?.usages)
        SetLoginCounts(response?.data?.login_counts)
        SetLoading(false);
        setError(null);
        toast.success("Data Loaded", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }).catch((error) => {
      setError(error?.response?.data?.Response?.error?.message);
      SetLoading(false);
      toast.error(error?.response?.data?.Response?.error?.message, {
        position: toast.POSITION.TOP_RIGHT  
      })				
    });
  }
   let uniqueIdCounter = 1;
  const getRowId = () => {
    return uniqueIdCounter++;
  };

  const columns = [
    { field: 'end_Customer_CSN', headerName: 'CSN', width: 200 },
    { field: 'account_name', headerName: 'Account', width: 200 },
    { field: 'usage_Type', headerName: 'Industry', width: 200 },
    
    {
      field: 'usage_date',
      headerName: 'Usage Date',
      width: 200,
      valueGetter: (params) => formatDate(params.row['usage_date']),
    },
    { field: 'product_line_code', headerName: 'Product Line Code', width: 200 },
    { field: 'feature_id', headerName: 'Feature Id', width: 200 },
    { field: 'team_name', headerName: 'Team Name', width: 200 },
    { field: 'primary_admin_email', headerName: 'Primary Admin Email', width: 200},
  ];


  const [status, setStatus] = React.useState("All Status");

  const uniqueProductLineCodes = [...new Set(logincounts?.map(count => count['Product Line Code']))];

const productLineData = {};

uniqueProductLineCodes.forEach(productLineCode => {
  const filteredData = logincounts?.filter(count => count['Product Line Code'] === productLineCode);

  if (filteredData) {
    const categories = filteredData.map(count => count['Usage Date']);
    const data = filteredData.map(count => (isNaN(count['Count']) ? 0 : count['Count']));

    productLineData[productLineCode] = {
      categories,
      data,
    };
  }
});

const defaultCategory = '';
const mergedData = (productLineData[uniqueProductLineCodes[0]]?.categories || []).map((date, index) => {
  const mergedItem = { Date: date || defaultCategory };

  uniqueProductLineCodes.forEach(productLineCode => {
    const productLineDataItem = productLineData[productLineCode];
    mergedItem[productLineCode] =
      (productLineDataItem && productLineDataItem.data && productLineDataItem.data[index] !== undefined)
        ? productLineDataItem.data[index]
        : 0;
  });

  return mergedItem;
});

const series = uniqueProductLineCodes.map(productLineCode => ({
  name: productLineCode,
  data: mergedData.map(item => item[productLineCode] || 0),
}));

  const options = {
    series,
    chart: {
      height: 350,
      type: 'line',
      dropShadow: {
        enabled: true,
        color: '#000',
        top: 18,
        left: 7,
        blur: 10,
        opacity: 0.2,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'smooth',
    },
    title: {
      text: 'Login Counts Over Time',
      align: 'left',
    },
    grid: {
      borderColor: '#e7e7e7',
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    markers: {
      size: 1,
    },
    xaxis: {
      categories: mergedData.map(item => item.Date),
      title: {
        text: 'Date',
      },
    },
    yaxis: {
      title: {
        text: 'Login Count',
      },
      min: 0,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'right',
      floating: true,
      offsetY: -25,
      offsetX: -5,
    },
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
        <Header title="Usages" subtitle="welcome to you Team" />
        <Box sx={{ display:{ md:"flex"}  }}>
        
          <Box sx={{ ml:  { md:2}   , minWidth:"200px"}}>
            <InputBase
              sx={{
               
                width: "100%",
                flex: 1,
                paddingX: 2,
                paddingY: "3px",
                borderRadius: 1,
                border: "1px solid",
                borderColor: colors.grey[900],
              }}
              placeholder="Search"
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{border:'0.8px solid' , borderColor:colors.grey[900]}}>
        <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
          <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Data of direct seat metrics i.e. 1:1 asscociation between product pool and agreement(without prorated)
          </Typography>
        </Box>
        <div id="chart">
        <ReactApexChart options={options} series={options.series} type="line" height={350} />
        </div>
      </Box>
      
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
      
      {loading ? (
          <p>Loading data...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <>
            <input
            type="text"
            placeholder="Search by Name"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            />
            <DataGrid
              rows={filteredData}
              columns={columns}
              getRowId={getRowId}
              components={{ Toolbar: GridToolbar }}
          />
            </>
      )}
      </Box>
    </Box>
  );
};

export default Usage;



