import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  InputBase,
  MenuItem,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Header from "../../components/Header.jsx";
import { Navigate, useParams } from "react-router-dom";
import {
  BACKEND_BASE_URL,
  BACKEND_BASE_MEDIA_URL,
} from "../../components/config/apiEnv.js";
import axios from "axios";
import { toast } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { useUser } from "../../components/UserProvider.js";
import { tokens } from "../../theme.js";
import { useNavigate } from "react-router-dom";


const UpdateProfile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [error, setError] = useState(null);
  const navigate = useNavigate()
  const params = useParams();
  
  const { HandleUser, token } = useUser();
  
  const { id: user_id } = useParams();
  console.log("____________________--user_id", params)
  console.log("____________________--user_id", user_id)

  const [user, SetUser] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    user_type: "",
    profile_image: null,
  });

  const headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  };

  useEffect(() => {
    if (token) {
      GetUser();
    }
  }, [token]);

  const GetUser = () => {
    console.log("____________________--user_id",user_id);
    
    axios
      .get(BACKEND_BASE_URL + `v1/user/${user_id}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetUser(response?.data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === "file") {
      SetUser((prevUser) => ({
        ...prevUser,
        [name]: files[0],
      }));
    } else {
      SetUser((prevUser) => ({
        ...prevUser,
        [name]: value,
      }));
    }
  };

  const HandleImagePath = () => {
    if (user.profile_image instanceof File) {
      return URL.createObjectURL(user.profile_image);
    } else {
      return `${user?.profile_image}`;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("first_name", user?.first_name);
    formData.append("last_name", user?.last_name);
    formData.append("phone", user?.phone);

    if (user.profile_image instanceof File) {
      formData.append("profile_image", user?.profile_image);
    }
    axios
      .put(BACKEND_BASE_URL + `v1/user/${user_id}`, formData, { headers })
      .then((response) => {
        if (response.status === 200) {
          GetUser();
          HandleUser(response?.data);
          navigate("/")
          setError(null);
          toast.success("User Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Header title="Update User" subtitle="welcome to you Team" />
      </Box>
      <div className="max-w-[800px] mx-auto mt-8 pb-14 p-6 bg-[#f2f0f0] rounded shadow-md">
        <h2 className="text-2xl font-semibold mb-6">Update Profile</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4 relative">
            <div className=" flex flex-col items-center justify-center mb-2">
              <div className="w-[200px] mb-5 relative h-[200px]">
                <img
                  src={HandleImagePath()}
                  alt="Profile"
                  className="w-full h-full z-[1] rounded-full "
                />
                <div className="absolute z-[2] bottom-[13px] right-[13px]">
                  <label htmlFor="imageInput" className="">
                    <IconButton
                      component="span"
                      sx={{
                        zIndex: 2,
                        background: "#fff !important",
                        padding: "8px",
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </label>
                  <input
                    type="file"
                    id="imageInput"
                    name="profile_image"
                    accept="image/*"
                    className="hidden"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <label
                htmlFor="profile_image"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Profile Image
              </label>
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="first_name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              First Name
            </label>
            <input
              type="text"
              id="first_name"
              name="first_name"
              value={user?.first_name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 background:#8080804d"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="last_name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Last Name
            </label>
            <input
              type="text"
              id="last_name"
              name="last_name"
              value={user?.last_name}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="phone"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Phone
            </label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={user?.phone}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
              required
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="last_name"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Designation
            </label>
            <input
              type="text"
              value={user?.designation}
              className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500 bg-gray-300"
              readOnly
            />
          </div>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
          >
            Update Profile
          </button>
        </form>
      </div>
    </Box>
  );
};

export default UpdateProfile;
