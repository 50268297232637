import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import axios from "axios";
import { useParams } from 'react-router-dom';



const InsightMatricsContract = () => {


  const params = useParams();

  const [ customerCSN , contractNumber ] =  params?.id?.split('&')
  const [contractCustomer, SetContractCustomer] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  
  useEffect(() => {
	getContractCustomer(customerCSN, contractNumber );
	},[]);

const checkingfunction = () => {
}

const getContractCustomer = (customerCSN, contractNumber) => {
    axios.get(BACKEND_BASE_URL + `/v1/api/get_insights_metrics_customer_contract/${customerCSN}/${contractNumber}`)
    .then((response) => {
        SetContractCustomer(response.data);
        checkingfunction()
    });
	}
    
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Insight Matrics Contract Customer " subtitle="" />
      </Box>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >

        {contractCustomer.length > 0 ? (
        <>
        <h3 className="mb-2 text-[18px] font-[600]">  Customer csn :-  {contractCustomer[0]['customerCSN']} </h3>
        <h3 className="mb-2 text-[18px] font-[600]">  Contract Number :-  {contractCustomer[0]['contractNumber']} </h3>
        <h3 className="mb-2 text-[18px] font-[600]">  Owner Type :-  {contractCustomer[0]['ownerType']} </h3>
        <h3 className="mb-2 text-[18px] font-[600]">  seatsPurchased :-  {contractCustomer[0]['seatsPurchased']} </h3>
        <h3 className="mb-2 text-[18px] font-[600]">  Users Assigned :-  {contractCustomer[0]['usersAssigned']} </h3>
        <h3 className="mb-2 text-[18px] font-[600]">  Seats InUse :-  {contractCustomer[0]['seatsInUse']} </h3>
        <h3 className="mb-2 text-[18px] font-[600]">  Users Assigned ProRated :-  {contractCustomer[0]['usersAssignedProRated']} </h3>
        <h3 className="mb-2 text-[18px] font-[600]">  seats InUse ProRated :-  {contractCustomer[0]['seatsInUseProRated']} </h3>
        <h3 className="mb-2 text-[18px] font-[600]">  tenantId :-  {contractCustomer[0]['tenantId']} </h3>
        <h3 className="mb-2 text-[18px] font-[600]">  premium Flag :-  {contractCustomer[0]['premiumFlag']} </h3>
        <h3 className="mb-2 text-[18px] font-[600]">  Reseller CSN :-  {contractCustomer[0]['resellerCSN']} </h3>
        <h3 className="mb-2 text-[18px] font-[600]">  Reseller Name :-  {contractCustomer[0]['resellerName']} </h3>
        </>
        ) : (
          <p>Loading data...</p>
        )}
      </Box>
    </Box>
  );
};

export default InsightMatricsContract;
