import React, { useState, useEffect } from "react";
import {Box} from "@mui/material";
import Header from "../../components/Header.jsx";
import {BACKEND_BASE_URL} from "../../components/config/apiEnv.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import { useCSN } from "../../components/CSNProvider.jsx";


const AddAccount = () => {
    const { csn } = useCSN();
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    const { token } = useUser();

    const [isSubmitting, setIsSubmitting] = useState(false);    
    const [allaccountdata, SetAllAccountData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [allBranch, SetAllBranch] = useState(null);
    const [allBdPerson, SetAllBdPerson] = useState(null);
    const [allRenewalPerson, SetAllRenewalPerson] = useState(null);
    const [allAccount, SetAllAccount] = useState([]);
    


    const [formData, setFormData] = useState({
        partner_csn: '',
        csn: '',
        name: '',
        flexCustomerFlag: false,
        buyingReadinessScore: '',
        isNamedAccount: false,
        segment: '',
        individualFlag: false,
        type: '',
        address1: '',
        address2: '',
        address3: '',
        city: '',
        country: '',
        countryCode: '',
        county: '',
        geo: '',
        industry: '',
        phoneNumber: '',
        stateProvince: '',
        postal: '',
        parentAccountCsn: '',
        parentIsNamedAccount: false,
        autodeskMainContact: '',
        autodeskMainContactEmail: '',
        salesRegion: '',
        status: '',
        language: '',
        website: '',
        industryGroup: '',
        industrySegment: '',
        industrySubSegment: '',
        localName: '',
        victimCsns: '',
        latitude: '',
        longitude: '',
        branch: '',
        user_assign: [],
        renewal_person: [],
        third_party: false,
        account_associated_with:[]
    });

    const transformApiResponse = (data) => {
      return data.map(account => ({
        "id": account.id,
        "csn": account?.csn ?? null,
        "email": account?.contract_manager[0]?.email ?? null,
        "phone": account?.contract_manager[0]?.phone ?? null,
        "name": account?.name ?? "",
        "industryGroup": account?.industryGroup ?? null,
        "industrySegment": account?.industrySegment ?? null,
        "industrySubSegment": account?.industrySubSegment ?? null,
        "address1": account?.address1 ?? null,
        "city": account?.city ?? null,
        "status": account?.status ?? null,
        "contract_status": account?.contract_status ?? null,
        "buyingReadinessScore": account?.buyingReadinessScore ?? null,
        "branch":account?.branch_name ?? null,
        "branch_object":account?.branch ?? null,
        "user_assign":account?.user_assign_firstname ?? null,
        "renewal_person":account?.renewal_person_firstname ?? null,
        // "renewal_person_object":account?.renewal_person ?? null,
        // "user_assign_object":account?.user_assign ?? null,
      }));
    };

    const headers = {
        Authorization: `Bearer ${token}`,
    };

    useEffect(() => {
        if (token){
        GetAllBranch();
        GetAccounts();
        // eslint-disable-next-line
        }
    }, [token]);


    const GetAllBranch = () => {
        // setStatus("All Status");
        axios.get(BACKEND_BASE_URL + `v1/api/get_all_branch/`, { headers })
            .then((response) => {
            if (response.status === 200) {
                SetAllBranch(response?.data?.Branch);
                const data = response?.data?.Branch.map(item => {
                const name = item.branch_name;
                const value = item.id;
                return {
                    label: name,
                    value: value,
                };
                });
                const uniqueLabels = new Set();
                setError(null);
                toast.success("Data Loaded", {
                position: toast.POSITION.TOP_RIGHT,
                });
            } else {
                toast.error("Error occured while displaying  sdasDSDASF accounts.", {
                position: toast.POSITION.TOP_RIGHT,
                });
            }
            })
            .catch(() => {
            setError(error?.response.data.Response.message);
            
            toast.error("Error occured while displaying  DSFDSFDS F accounts.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            
            });
        };

    const GetAccounts = () => {
      console.log("___________________done ");
      setIsLoading(true)
      // setStatus("All Status");
      const apiUrl = `${BACKEND_BASE_URL}v1/api/get_export_accounts/${csn ? `${csn}` : ''}`;
      axios.get(apiUrl, { headers })
          .then((response) => {
          if (response.status === 200) {
              const transformedData = transformApiResponse(response?.data.accounts);
              SetAllAccount(transformedData);
              setIsLoading(false)
              setError(null);
              // setSearchBranch(null)
              // setLastUpdated(response?.data?.last_updated)
              toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
              });
          } else {
              toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
              });
          }
          })
          .catch(() => {
          setIsLoading(false)
          setError(error?.response.data.Response.message);
          toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
          });
          });
    };


    useEffect(() => {
        if (token){
        if (formData.branch){
          handleBranch(formData.branch);
          setFormData(prevData => ({
            ...prevData,
            bd_person: [] 
          }));
          }
        }
      }, [token, formData.branch, formData.order_type]);
  
      const handleBranch = (electedOption) => {
        console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ handleBranch ~ electedOption:", formData.branch)
        if (electedOption.value){
        handleSelectChange(electedOption, { name: 'branch' })
        }
    
        axios.get(BACKEND_BASE_URL + `v1/api/get_bd_and_renewal_person_by_branch/${electedOption?.value ? `${electedOption?.value}` : `${electedOption}`}`, { headers })
          .then((response) => {
          if (response.status === 200) {
              SetAllBdPerson(response?.data.bd_person);
              SetAllRenewalPerson(response?.data.renewal_person);
              setError(null);
              toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
              });
          } else {
              toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
              });
          }
          })
          .catch(() => {
          setError(error?.response.data.Response.message);
          
          setIsLoading(false)
          toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
          });
          
          });
          
      }

      const handleChange = (e) => {
        const { name, checked, type } = e.target;
      
        setFormData({
          ...formData,
          [name]: type === 'checkbox' ? checked : e.target.value,
        });
      };

    const handleSubmit = (e) => {
      setIsSubmitting(true);
      e.preventDefault();
      const requestData = {
        partner_csn: formData?.partner_csn,
        csn: formData?.csn,
        name: formData?.name,
        flexCustomerFlag: formData?.flexCustomerFlag,
        buyingReadinessScore: formData?.buyingReadinessScore,
        isNamedAccount: formData?.isNamedAccount,
        segment: formData?.segment,
        individualFlag: formData?.individualFlag,
        type: formData?.type,
        address1: formData?.address1,
        address2: formData?.address2,
        address3: formData?.address3,
        city: formData?.city,
        country: formData?.country,
        countryCode: formData?.countryCode,
        county: formData?.county,
        geo: formData?.geo,
        industry: formData?.industry,
        phoneNumber: formData?.phoneNumber,
        stateProvince: formData?.stateProvince,
        postal: formData?.postal,
        parentAccountCsn: formData?.parentAccountCsn,
        parentIsNamedAccount: formData?.parentIsNamedAccount,
        autodeskMainContact: formData?.autodeskMainContact,
        autodeskMainContactEmail: formData?.autodeskMainContactEmail,
        salesRegion: formData?.salesRegion,
        status: formData?.status,
        language: formData?.language,
        website: formData?.website,
        industryGroup: formData?.industryGroup,
        industrySegment: formData?.industrySegment,
        industrySubSegment: formData?.industrySubSegment,
        localName: formData?.localName,
        victimCsns: formData?.victimCsns,
        latitude: formData?.latitude,
        longitude: formData?.longitude,
        branch: formData?.branch,
        user_assign: formData?.user_assign,
        renewal_person: formData?.renewal_person,
        third_party: formData?.third_party,
        account_associated_with:formData?.account_associated_with

    };
        console.log("________________-formData?.third_party",formData?.third_party);
        
        axios
        .post(BACKEND_BASE_URL + `v1/api/third_party_account/`, requestData, { headers })
        .then((response) => {
            if (response.status === 201) {
                if (formData?.third_party){
                  // navigate("/third_party_account")
                }
                else{
                  navigate("/account")
                }
            setError(null);
            toast.success("Third Party Account created Successfully", {
                position: toast.POSITION.TOP_RIGHT,
            });
            } else {
            toast.error("Error occured while displaying accountsfdfdsfds.", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setIsSubmitting(false);
            }
        })
        .catch((error) => {
            toast.error(error.response.data?.error, {
            position: toast.POSITION.TOP_RIGHT,
            });
            setIsSubmitting(false);
        });
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const { name } = actionMeta;
        setFormData(prevData => ({
            ...prevData,
            [name]: selectedOption?.value || ''
        }));
      };

    const handleMultipleSelectChange = (selectedOptions, actionMeta) => {
        const { name } = actionMeta;
        
        setFormData(prevData => ({
            ...prevData,
            [name]: selectedOptions ? selectedOptions.map(option => option.value) : []
        }));
    };

    const branchOptions = allBranch?.map(branch => ({
        value: branch.id,
        label: branch.branch_name
    })) || [];
    
    const bdPersonOptions = allBdPerson?.map(user => ({
        value: user.id,
        label: user.email
      })) || [];

    const renewalOptions = allRenewalPerson?.map(user => ({
      value: user.id,
      label: user.email
    })) || [];

    const accountOptions = allAccount?.map(account => ({
      value: account.id,
      // label: account.name,
      label: `${account.name} (${account.csn})`,
    })) || [];


  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Header title="Add Account" subtitle="welcome to you Team" />
      </Box>
      <div className="max-w-[800px] mx-auto mt-8 pb-14 p-6 bg-[#f2f0f0] rounded shadow-md">
      <h2 className="text-2xl font-semibold mb-6">Add Account Form</h2>
      <form onSubmit={handleSubmit}>
      {/* Text Input Fields */}
      <div className="mb-4">
        <label htmlFor="partner_csn" className="block text-gray-700 text-sm font-bold mb-2">
          Partner CSN *
        </label>
        
        <select
          id="partner_csn"
          name="partner_csn"
          value={formData.partner_csn}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          required
        >
          <option value="">Select a Partner CSN</option>
          <option value="5102086717">5102086717</option>
          <option value="5117963549">5117963549</option>
        </select>
      </div>

      <div className="mb-4">
        <label htmlFor="csn" className="block text-gray-700 text-sm font-bold mb-2">
          CSN
        </label>
        <input
          type="text"
          id="csn"
          name="csn"
          value={formData.csn}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="name" className="block text-gray-700 text-sm font-bold mb-2">
          Account Name *
        </label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
            <label htmlFor="branch" className="block text-gray-700 text-sm font-bold mb-2">Branch *</label>
            <Select
                id="branch"
                name="branch"
                options={branchOptions}
                value={branchOptions.find(option => option.value === formData.branch)}
                onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'branch' })}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select a Branch"
                isClearable={true} 
            />
      </div>

    <div className="mb-4">
    <label htmlFor="bd_person" className="block text-gray-700 text-sm font-bold mb-2">BD Person *</label>
    <Select
            id="user_assign"
            name="user_assign"
            options={bdPersonOptions}
            value={bdPersonOptions.filter(option => formData.user_assign.includes(option.value))}
            onChange={(selectedOptions) => handleMultipleSelectChange(selectedOptions, { name: 'user_assign' })}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select a BD Person"
            required
            isClearable={true}
            isMulti={true}
        />
        </div>
        <div className="mb-4">
        <label htmlFor="renewal_person" className="block text-gray-700 text-sm font-bold mb-2">Renewal Person *</label>
        <Select
                id="renewal_person"
                name="renewal_person"
                options={renewalOptions}
                value={renewalOptions.filter(option => formData.renewal_person.includes(option.value))}
                onChange={(selectedOptions) => handleMultipleSelectChange(selectedOptions, { name: 'renewal_person' })}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select a Renewal Person"
                required
                isClearable={true}
                isMulti={true}
            />
        </div>


      <div className="mb-4">
        <label htmlFor="buyingReadinessScore" className="block text-gray-700 text-sm font-bold mb-2">
          Buying Readiness Score
        </label>
        <input
          type="text"
          id="buyingReadinessScore"
          name="buyingReadinessScore"
          value={formData.buyingReadinessScore}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="segment" className="block text-gray-700 text-sm font-bold mb-2">
          Segment *
        </label>
        <select
          id="segment"
          name="segment"
          value={formData.segment}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          required
        >
          <option value="">Select segment</option>
          <option value="Emerging">Emerging</option>
          <option value="Strategic Account">Strategic Account</option>
          <option value="Strategic Territory">Mature</option>
          <option value="Named Account">Named Account</option>
          <option value="Territory New">Territory New</option>
          <option value="Midmarket Federal">Midmarket Federal</option>
          <option value="Territory">Territory</option>
          <option value="Midmarket">Midmarket</option>
        </select>
      </div>

      <div className="mb-4">
        <label htmlFor="type" className="block text-gray-700 text-sm font-bold mb-2">
          Account Type *
        </label>
        <select
          id="type"
          name="type"
          value={formData.type}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          required
        >
          <option value="">Select account type</option>
          <option value="End Customer">End Customer</option>
          <option value="Government">Government</option>
          <option value="Strategic Account">Strategic Account</option>
          <option value="Reseller">Reseller</option>
          <option value="Distributor">Distributor</option>
        </select>
      </div>

      
      <div className="mb-4">
        <label htmlFor="address1" className="block text-gray-700 text-sm font-bold mb-2">
          Address Line 1
        </label>
        <input
          type="text"
          id="address1"
          name="address1"
          value={formData.address1}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="address2" className="block text-gray-700 text-sm font-bold mb-2">
          Address Line 2
        </label>
        <input
          type="text"
          id="address2"
          name="address2"
          value={formData.address2}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="address3" className="block text-gray-700 text-sm font-bold mb-2">
          Address Line 3
        </label>
        <input
          type="text"
          id="address3"
          name="address3"
          value={formData.address3}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="city" className="block text-gray-700 text-sm font-bold mb-2">
          City
        </label>
        <input
          type="text"
          id="city"
          name="city"
          value={formData.city}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="country" className="block text-gray-700 text-sm font-bold mb-2">
          Country
        </label>
        <input
          type="text"
          id="country"
          name="country"
          value={formData.country}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="countryCode" className="block text-gray-700 text-sm font-bold mb-2">
          Country Code
        </label>
        <input
          type="text"
          id="countryCode"
          name="countryCode"
          value={formData.countryCode}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="county" className="block text-gray-700 text-sm font-bold mb-2">
          County
        </label>
        <input
          type="text"
          id="county"
          name="county"
          value={formData.county}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="geo" className="block text-gray-700 text-sm font-bold mb-2">
          Geo *
        </label>
        <select
          id="geo"
          name="geo"
          value={formData.geo}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          required
        >
          <option value="">Select geo</option>
          <option value="Americas">Americas</option>
          <option value="APAC">APAC</option>
          <option value="EMEA">EMEA</option>
        </select>
      </div>


      <div className="mb-4">
        <label htmlFor="industry" className="block text-gray-700 text-sm font-bold mb-2">
          Industry
        </label>
        <input
          type="text"
          id="industry"
          name="industry"
          value={formData.industry}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="phoneNumber" className="block text-gray-700 text-sm font-bold mb-2">
          Phone Number
        </label>
        <input
          type="text"
          id="phoneNumber"
          name="phoneNumber"
          value={formData.phoneNumber}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="stateProvince" className="block text-gray-700 text-sm font-bold mb-2">
          State/Province
        </label>
        <input
          type="text"
          id="stateProvince"
          name="stateProvince"
          value={formData.stateProvince}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="postal" className="block text-gray-700 text-sm font-bold mb-2">
          Postal Code
        </label>
        <input
          type="text"
          id="postal"
          name="postal"
          value={formData.postal}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="csn" className="block text-gray-700 text-sm font-bold mb-2">
        Parent AccountCsn *
        </label>
        <input
          type="text"
          id="parentAccountCsn"
          name="parentAccountCsn"
          value={formData.parentAccountCsn}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="autodeskMainContact" className="block text-gray-700 text-sm font-bold mb-2">
          Autodesk Main Contact
        </label>
        <input
          type="text"
          id="autodeskMainContact"
          name="autodeskMainContact"
          value={formData.autodeskMainContact}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="autodeskMainContactEmail" className="block text-gray-700 text-sm font-bold mb-2">
          Autodesk Main Contact Email
        </label>
        <input
          type="email"
          id="autodeskMainContactEmail"
          name="autodeskMainContactEmail"
          value={formData.autodeskMainContactEmail}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="salesRegion" className="block text-gray-700 text-sm font-bold mb-2">
          Sales Region
        </label>
        <input
          type="text"
          id="salesRegion"
          name="salesRegion"
          value={formData.salesRegion}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="status" className="block text-gray-700 text-sm font-bold mb-2">
          Status *
        </label>
        <select
          id="status"
          name="status"
          value={formData.status}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          required
        >
          <option value="">Select status</option>
          <option value="Active">Active</option>
          <option value="Contract Pending">Contract Pending</option>
          <option value="Inactive">Inactive</option>
          <option value="Marked for Deletion">Marked for Deletion</option>
          <option value="Out of Business">Out of Business</option>
        </select>
      </div>

      <div className="mb-4">
        <label htmlFor="language" className="block text-gray-700 text-sm font-bold mb-2">
          Language
        </label>
        <input
          type="text"
          id="language"
          name="language"
          value={formData.language}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="website" className="block text-gray-700 text-sm font-bold mb-2">
          Website
        </label>
        <input
          type="text"
          id="website"
          name="website"
          value={formData.website}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="industryGroup" className="block text-gray-700 text-sm font-bold mb-2">
          Industry Group
        </label>
        <select
          id="industryGroup"
          name="industryGroup"
          value={formData.industryGroup}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        >
          <option value="">Select Industry Group</option>
          <option value="EDU">EDU</option>
          <option value="MFG">MFG</option>
          <option value="OTH">OTH</option>
          <option value="AEC">AEC</option>
        </select>
      </div>

      <div className="mb-4">
        <label htmlFor="industrySegment" className="block text-gray-700 text-sm font-bold mb-2">
          Industry Segment
        </label>
        <input
          type="text"
          id="industrySegment"
          name="industrySegment"
          value={formData.industrySegment}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="industrySubSegment" className="block text-gray-700 text-sm font-bold mb-2">
          Industry Sub-Segment
        </label>
        <input
          type="text"
          id="industrySubSegment"
          name="industrySubSegment"
          value={formData.industrySubSegment}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="localName" className="block text-gray-700 text-sm font-bold mb-2">
          Local Name
        </label>
        <input
          type="text"
          id="localName"
          name="localName"
          value={formData.localName}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="victimCsns" className="block text-gray-700 text-sm font-bold mb-2">
          Victim CSNs
        </label>
        <input
          type="text"
          id="victimCsns"
          name="victimCsns"
          value={formData.victimCsns}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="latitude" className="block text-gray-700 text-sm font-bold mb-2">
          Latitude
        </label>
        <input
          type="text"
          id="latitude"
          name="latitude"
          value={formData.latitude}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="longitude" className="block text-gray-700 text-sm font-bold mb-2">
          Longitude
        </label>
        <input
          type="text"
          id="longitude"
          name="longitude"
          value={formData.longitude}
          onChange={handleChange}
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
      </div>

      {/* Checkbox Fields */}
      <div className="mb-4">
        <label htmlFor="flexCustomerFlag" className="block text-gray-700 text-sm font-bold mb-2">
          Flex Customer Flag
        </label>
        <input
          type="checkbox"
          id="flexCustomerFlag"
          name="flexCustomerFlag"
          checked={formData.flexCustomerFlag}
          onChange={handleChange}
          className="mr-2 leading-tight"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="isNamedAccount" className="block text-gray-700 text-sm font-bold mb-2">
          Is Named Account
        </label>
        <input
          type="checkbox"
          id="isNamedAccount"
          name="isNamedAccount"
          checked={formData.isNamedAccount}
          onChange={handleChange}
          className="mr-2 leading-tight"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="individualFlag" className="block text-gray-700 text-sm font-bold mb-2">
          Individual Flag
        </label>
        <input
          type="checkbox"
          id="individualFlag"
          name="individualFlag"
          checked={formData.individualFlag}
          onChange={handleChange}
          className="mr-2 leading-tight"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="parentIsNamedAccount" className="block text-gray-700 text-sm font-bold mb-2">
          Parent Is Named Account
        </label>
        <input
          type="checkbox"
          id="parentIsNamedAccount"
          name="parentIsNamedAccount"
          checked={formData.parentIsNamedAccount}
          onChange={handleChange}
          className="mr-2 leading-tight"
        />
      </div>

      <div className="mb-4">
        <label htmlFor="third_party" className="block text-gray-700 text-sm font-bold mb-2">
          Third Party
        </label>
        <input
          type="checkbox"
          id="third_party"
          name="third_party"
          checked={formData.third_party}
          onChange={handleChange}
          className="mr-2 leading-tight"
        />
      </div>

      {formData.third_party && (
      <div className="mb-4">
          <label htmlFor="account_associated_with" className="block text-gray-700 text-sm font-bold mb-2">Account Associated With</label>
          <Select
              id="account_associated_with"
              name="account_associated_with"
              options={accountOptions}
              value={accountOptions.filter(option => formData.account_associated_with.includes(option.value))}
              onChange={(selectedOptions) => handleMultipleSelectChange(selectedOptions, { name: 'account_associated_with' })}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Select an Account"
              required
              isClearable={true}
              isMulti={true}
          />
      </div>
      )}

      {/* Submit Button */}
      <div className="mb-4">
        <button
          type="submit"
          disabled={isSubmitting}
          className={`bg-${error ? 'gray-300' : 'blue-500'} text-white px-4 py-2 rounded-md ${error ? 'cursor-not-allowed' : 'hover:bg-blue-600 focus:outline-none focus:bg-blue-600'}`}
        >
           {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </div>
    </form>
      </div>
    </Box>
  );
};

export default AddAccount;