import React, { useState, useEffect } from "react";
import Header from "../../components/Header.jsx";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { useNavigate } from "react-router-dom";
import {
  Autocomplete,
  Box,
  TextField,
  Typography,
  useTheme,
  Modal,
  FormControl,
  InputLabel,
  Select,
  Menu,
  IconButton,
  Button,
  AppBar,
  Toolbar,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from "@mui/material";
import { MdOutlineFileUpload } from "react-icons/md";
import { useCSN } from "../../components/CSNProvider.jsx";
import { useProSidebar } from "react-pro-sidebar";
import {
  Close,
  InfoOutlined,
  KeyboardArrowDown,
  SettingsOutlined,
  ViewModuleOutlined,
} from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Disclosure } from "@headlessui/react";

const RunChampaign = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { token } = useUser();
  const [image, setImage] = useState();

  const [industry, SetIndustry] = useState("");
  const [segment, SetSegment] = useState("");
  const [subsegment, SetSubSegment] = useState("");
  const [allproductline, SetAllProductline] = useState("");
  const [productline, SetProductline] = useState("");
  const [searchBranch, setSearchBranch] = useState(null);
  const [branches, setBranches] = useState([]);

  const [selected, setSelectedValue] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [selectedSubsegment, setSelectedSubSegment] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [status, setStatus] = React.useState("All Status");
  const [loading, SetLoading] = useState(true);
  const [formData, SetFromData] = useState({});
  console.log("🚀🚀🚀🚀🚀🚀🚀 ~ RunChampaign ~ formData:", formData)
  const [campaignCount, setCampaignCount] = useState(0);
  console.log("🚀 ~ RunChampaign ~ campaignCount:", campaignCount)
  const [backPopup, showBackPopup] = useState(false);
  const [templateName, setTemplateName] = useState(""); // Selected template name
  const [newContent, setNewContent] = useState(""); // Edited template content
  const [newOpen, setNewOpen] = useState(false);


  const { csn } = useCSN();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token && selectedBranch) {
      if (selectedBranch === "all") {
        getProductline(null);
      } else {
        getProductline(selectedBranch);
      }
    } else {
      SetProductline(null);
    }
  }, [ selectedBranch, csn, selectedIndustry, selectedSegment, selectedSubsegment,]);

  const getProductline = (branch) => {
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_all_product_line/?pcsn=${
      csn || ""
    }&branch_id=${branch || ""}&industry=${
      selectedIndustry?.value || ""
    }&segment=${selectedSegment?.value || ""}&subsegment=${
      selectedSubsegment?.value || ""
    }`;
    axios.get(apiUrl, { headers }).then((response) => {
      if (response.status === 200) {
        const setData = new Set(response?.data?.data.map(JSON.stringify));
        const uniqueArray = Array.from(setData).map(JSON.parse);

        const data = uniqueArray.map((item) => {
          const name = item.productLineCode;
          return {
            label: name,
            value: name,
          };
        });
        const industrydata = uniqueArray?.map((item) => {
          const name = item.industry;
          return {
            label: name,
            value: name,
          };
        });
        const setindustryData = new Set(industrydata.map(JSON.stringify));
        const uniqueIndustrydArray = Array.from(setindustryData).map(
          JSON.parse
        );

        const segmentdata = uniqueArray?.map((item) => {
          const name = item.segment;
          return {
            label: name,
            value: name,
          };
        });
        const setsegmentData = new Set(segmentdata.map(JSON.stringify));
        const uniqueSegmentArray = Array.from(setsegmentData).map(JSON.parse);
        const subsegmentdata = uniqueArray?.map((item) => {
          const name = item.subsegment;
          return {
            label: name,
            value: name,
          };
        });
        const setsubsegmentData = new Set(subsegmentdata.map(JSON.stringify));
        const uniqueSubSegmentArray = Array.from(setsubsegmentData).map(
          JSON.parse
        );
        SetAllProductline(response.data.data);
        SetProductline(data);
        SetIndustry(uniqueIndustrydArray);
        SetSegment(uniqueSegmentArray);
        SetSubSegment(uniqueSubSegmentArray);
      }
    });
  };

  useEffect(() => {
    if (token) {
      GetAllBranch();
    }
  }, [token]);

  const GetAllBranch = () => {
    axios
      .get(BACKEND_BASE_URL + `v1/api/get_all_branch/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data?.Branch.map((item) => {
            const name = item.branch_name;
            const value = item.id;
            return {
              label: name,
              value: value,
            };
          });
          data.push({ label: "All Branch", value: "all" });
          setBranches(data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleChange = (e) => {
    setEditedContent(e.target.value);
    const { name, value } = e.target;
    SetFromData((prevPasswords) => ({
      ...prevPasswords,
      [name]: value,
    }));
  };

  const handleConfirmBack = (confirm) => {
    if (confirm) {
      handleSubmit()
    }
    showBackPopup(false);
  };

  const handlePopupSubmit = (e) => {
    const requestData = {
      productline: selected?.value || null,
      subject: formData?.subject || null,
      campaign_message: formData?.message || null,
      branch: searchBranch?.value === "all" ? null : searchBranch?.value,
      industry: selectedIndustry?.value || null,
      segment: selectedSegment?.value || null, 
      subsegment: selectedSubsegment?.value || null,
    };
    console.log("________________-requestData",requestData);
    
    axios
      .post(BACKEND_BASE_URL + `v1/api/popup_campaign_send/`, requestData, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          showBackPopup(true);
          setCampaignCount(response?.data?.count)
          toast.success(response.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accountsfdfdsfds.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleSubmit = (e) => {
    const requestData = {
      productline: selected?.value || null,
      subject: formData?.subject || null,
      campaign_message: formData?.message || null,
      branch: searchBranch?.value === "all" ? null : searchBranch?.value,
      industry: selectedIndustry?.value || null,
      segment: selectedSegment?.value || null,
      subsegment: selectedSubsegment?.value || null,
    };
    axios
      .post(BACKEND_BASE_URL + `v1/api/campaign_send/`, requestData, {
        headers,
      })
      .then((response) => {
        if (response.status === 200) {
          toast.success(response.data?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          navigate('/campaign_history')
        } else {
          toast.error("Error occured while displaying accountsfdfdsfds.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleBranchSelect = (branch) => {
    SetLoading(false);
    setSelectedValue(null);
    setSelectedIndustry(null);
    setSelectedSegment(null);
    setSelectedSubSegment(null);
    if (branch) {
      setSelectedBranch(branch.value);
    } else {
      setSelectedBranch(null);
    }
  };

  const [sidebarOpen, setSidebarOpen] = useState(true);

  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };


  const handleStatusChange = (event) => {
    const selectedValue = event.target.value;
    setStatus(selectedValue);
    if (selectedValue) {
      if (selectedValue === "All Status") {
        SetProductline(allproductline);
      } else {
        const filteredResults = allproductline.filter((item) => {
          const subscriptionStatus = item["contract_status"];
          return subscriptionStatus === selectedValue;
        });
        const setData = new Set(filteredResults.map(JSON.stringify));
        const uniqueArray = Array.from(setData).map(JSON.parse);

        const data = uniqueArray.map((item) => {
          const name = item.productLineCode;
          return {
            label: name,
            value: name,
          };
        });

        SetProductline(data);
      }
    } else {
    }
  };

  const [selectedTemplate, setSelectedTemplate] = useState(""); // Selected template name
  console.log("selectedTemplate ~ RunChampaign ~ selectedTemplate:", selectedTemplate)
  const [originalContent, setOriginalContent] = useState("");
  const [templates, setTemplates] = useState([]);
  const [editedContent, setEditedContent] = useState(""); // Edited template content

  useEffect(() => {
    GetAllTemplates();
  }, [token]);

  const GetAllTemplates = () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (token) {
      axios
        .get(BACKEND_BASE_URL + `v1/api/templates/`, { headers })
        .then((response) => {
          setTemplates(response.data); // Set templates list
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  }

  const handleHTMLContentChange = (event) => {
    setNewContent(event.target.value);
  };

  const handleHTMLNameChange = (event) => {
    setTemplateName(event.target.value);
  };


  const handleAdd = () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(
        BACKEND_BASE_URL + `v1/api/add_html_template/`,
        { name: templateName, content: newContent },
        { headers }
      )
      .then((response) => {
        if (response.status === 201) { 
          toast.success("Template added successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setNewContent(""); 
          setTemplateName(""); 
          setNewOpen(false); 
          GetAllTemplates()
        } else {
          toast.error("Error occurred while adding template.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.error || "An error occurred", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleTemplateChange = (event) => {
    const templateName = event.target.value;
    if (templateName == "new"){
      setNewOpen(true)
    }
    else{
    setSelectedTemplate(templateName);

    // Find the content of the selected template
    const selectedTemplate = templates.find(
      (template) => template.name === templateName
    );
    if (selectedTemplate) {
      console.log("______________---");
      
      setOriginalContent(selectedTemplate.content);
      setEditedContent(selectedTemplate.content);
      SetFromData((prevPasswords) => ({
        ...prevPasswords,
        ['message']: selectedTemplate.content,
      }));
      }
    }
  };

  // Handle content change
  const handleContentChange = (event) => {
    setEditedContent(event.target.value);
  };

  return (
    <>
      <Box m="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Header title="Campaign Page" subtitle="welcome to you Team" />
        </Box>
        <div className="flex items-start justify-center gap-10">
          <div className="pb-14 p-6 max-w-[800px] w-full bg-[#f2f0f0] rounded shadow-md">
            <h2 className="text-2xl font-semibold mb-6">Create Campaign</h2>
            <form onSubmit={handleSubmit}>
              {error && (
                <div style={{ color: "red", marginTop: "8px" }}>{error}</div>
              )}
              <div className="mb-4">
                {branches ? (
                  <Autocomplete
                    value={searchBranch}
                    onChange={(event, newValue) => {
                      handleBranchSelect(newValue);
                      setSearchBranch(newValue);
                    }}
                    options={branches}
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select a Branch"
                        variant="outlined"
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    value={null} 
                    disabled
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select a Branch"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <Typography variant="body2" color="textSecondary">
                                Loading...
                              </Typography>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </div>

              <div className="mb-4">
                {industry ? (
                  <Autocomplete
                    value={selectedIndustry}
                    onChange={(event, newValue) => {
                      setSelectedIndustry(newValue);
                    }}
                    options={industry}
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an Industry Group"
                        variant="outlined"
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    value={null} 
                    disabled
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an Industry Group"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <Typography variant="body2" color="textSecondary">
                                Wait
                              </Typography>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </div>
              <div className="mb-4">
                {segment ? (
                  <Autocomplete
                    value={selectedSegment}
                    onChange={(event, newValue) => {
                      setSelectedSegment(newValue);
                    }}
                    options={segment}
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an Segment Group"
                        variant="outlined"
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    value={null}
                    disabled
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an Segment Group"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <Typography variant="body2" color="textSecondary">
                                Wait
                              </Typography>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </div>
              <div className="mb-4">
                {subsegment ? (
                  <Autocomplete
                    value={selectedSubsegment}
                    onChange={(event, newValue) => {
                      setSelectedSubSegment(newValue);
                    }}
                    options={subsegment}
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an SubSegement Group"
                        variant="outlined"
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    value={null}
                    disabled
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an Product line code"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <Typography variant="body2" color="textSecondary">
                                Wait
                              </Typography>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </div>

              <div className="mb-4">
                <Box
                  sx={{
                    height: "52px",
                    width: 100,
                    minWidth: "200px",
                  }}
                >
                  <Select
                    value={status}
                    inputProps={{ "aria-label": "Without label" }}
                    onChange={handleStatusChange}
                    sx={{ width: "100%", height: "52px" }}
                    SelectDisplayProps={{
                      style: { paddingTop: 8, paddingBottom: 8 },
                    }}
                    disabled={loading}
                  >
                    <MenuItem value={"All Status"}>All Status</MenuItem>
                    <MenuItem value={"Active"}>Active</MenuItem>
                    <MenuItem value={"Expired"}>Expired</MenuItem>
                  </Select>
                </Box>
              </div>

              <div className="mb-4">
                {productline ? (
                  <Autocomplete
                    value={selected}
                    onChange={(event, newValue) => {
                      setSelectedValue(newValue);
                    }}
                    options={productline}
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an Product line code"
                        variant="outlined"
                      />
                    )}
                  />
                ) : (
                  <Autocomplete
                    value={null}
                    disabled
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an Product line code"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <Typography variant="body2" color="textSecondary">
                                Wait
                              </Typography>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="subject"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Subject
                </label>
                <input
                  type="text"
                  id="subject"
                  name="subject"
                  onChange={handleChange}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  required
                />
              </div>
              



              <div className="mb-4">
            <label
              htmlFor="templateSelect"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Select Template
            </label>
            <select
              id="templateSelect"
              className="w-full outline-none rounded-md"
              value={selectedTemplate}
              onChange={handleTemplateChange}
            >
              <option value="">Select a template</option>
              <option key='new' value="new"> Add New Template </option>
              {templates.map((template) => (
                <option key={template.name} value={template.name}>
                  {template.name}
                </option>
              ))}
            </select>
          </div>

          {selectedTemplate && (
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Template Content
              </label>
              <textarea
                name="message"
                style={{ height: '600px' }} // Set custom height for textarea
                className="w-full outline-none rounded-md"
                value={editedContent}
                onChange={handleChange}
              />
            </div>
          )}



              {/* <div className="mb-4">
                <label
                  htmlFor="message"
                  className="block text-gray-700 text-sm font-bold mb-2"
                >
                  Message Body
                </label>
                <textarea
                  name="message"
                  onChange={handleChange}
                  value={}
                  rows={4}
                  className="w-full"
                />
              </div> */}
              <button
                type="button"

                className={`bg-${
                  error ? "gray-300" : "blue-500"
                } text-white px-4 py-2 rounded-md ${
                  error
                    ? "cursor-not-allowed"
                    : "hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
                }`}
                disabled={!!error}
                onClick={handlePopupSubmit}
              >
                Run Campaign
              </button>
            </form>
          </div>
          <div className="max-w-[400px] w-full h-[70vh] overflow-auto">
            <div className="mb-10">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f2f0f0] px-4 py-2 text-left text-sm font-medium text-black focus:outline-none">
                      <span>User</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 max-h-[400px] overflow-auto">
                      <div className="mb-5">first_name</div>
                      <div className="mb-5">last_name</div>
                      <div className="mb-5">email</div>
                      <div className="mb-5">phone</div>
                      <div className="mb-5">date_of_birth</div>
                      <div className="mb-5">user_type</div>
                      <div className="mb-5">designation</div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mb-10">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f2f0f0] px-4 py-2 text-left text-sm font-medium text-black focus:outline-none">
                      <span>Main</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 max-h-[300px] h-full overflow-auto">
                      <div className="mb-5">subscriptionReferenceNumber</div>
                      <div className="mb-5">productLanguage</div>
                      <div className="mb-5">productStatus</div>
                      <div className="mb-5">subscriptionType</div>
                      <div className="mb-5">groupName</div>
                      <div className="mb-5">quantity</div>
                      <div className="mb-5">seats</div>
                      <div className="mb-5">packSize</div>
                      <div className="mb-5">subscriptionStatus</div>
                      <div className="mb-5">lastPurchaseDate</div>
                      <div className="mb-5">startDate</div>
                      <div className="mb-5">endDate</div>
                      <div className="mb-5">description</div>
                      <div className="mb-5">productLine</div>
                      <div className="mb-5">productLineCode</div>
                      <div className="mb-5">solutionDivision</div>
                      <div className="mb-5">release</div>
                      <div className="mb-5">deployment</div>
                      <div className="mb-5">licensingModel</div>
                      <div className="mb-5">subscriptionLevel</div>
                      <div className="mb-5">switchType</div>
                      <div className="mb-5">switchYear</div>
                      <div className="mb-5">opportunityNumber</div>
                      <div className="mb-5">licenseType</div>
                      <div className="mb-5">activationType</div>
                      <div className="mb-5">programType</div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mb-10">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f2f0f0] px-4 py-2 text-left text-sm font-medium text-black focus:outline-none">
                      <span>Account</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 max-h-[300px] h-full overflow-auto">
                      <div className="mb-5">branch_name</div>
                      <div className="mb-5">account_csn</div>
                      <div className="mb-5">account_name</div>
                      <div className="mb-5">account_address1</div>
                      <div className="mb-5">account_address2</div>
                      <div className="mb-5">account_address3</div>
                      <div className="mb-5">account_city</div>
                      <div className="mb-5">account_country</div>
                      <div className="mb-5">account_countryCode</div>
                      <div className="mb-5">account_geo</div>
                      <div className="mb-5">account_industry</div>
                      <div className="mb-5">account_phoneNumber</div>
                      <div className="mb-5">account_stateProvince</div>
                      <div className="mb-5">account_postal</div>
                      <div className="mb-5">account_parentAccountCsn</div>
                      <div className="mb-5">account_parentIsNamedAccount</div>
                      <div className="mb-5">account_autodeskMainContact</div>
                      <div className="mb-5">
                        account_autodeskMainContactEmail
                      </div>
                      <div className="mb-5">account_salesRegion</div>
                      <div className="mb-5">account_status</div>
                      <div className="mb-5">account_language</div>
                      <div className="mb-5">account_website</div>
                      <div className="mb-5">account_industryGroup</div>
                      <div className="mb-5">account_industrySegment</div>
                      <div className="mb-5">account_industrySubSegment</div>
                      <div className="mb-5">account_localName</div>
                      <div className="mb-5">account_victimCsns</div>
                      <div className="mb-5">account_latitude</div>
                      <div className="mb-5">account_longitude</div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mb-10">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f2f0f0] px-4 py-2 text-left text-sm font-medium text-black focus:outline-none">
                      <span>Contract</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 max-h-[300px] h-full overflow-auto">
                      <div className="mb-5">contract_number</div>
                      <div className="mb-5">contract_startDate</div>
                      <div className="mb-5">contract_endDate</div>
                      <div className="mb-5">contract_term</div>
                      <div className="mb-5">contract_billingBehavior</div>
                      <div className="mb-5">contract_subType</div>
                      <div className="mb-5">contract_renewalCounter</div>
                      <div className="mb-5">contract_duration</div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mb-10">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f2f0f0] px-4 py-2 text-left text-sm font-medium text-black focus:outline-none">
                      <span>EWS Adoption Health</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 max-h-[300px] h-full overflow-auto">
                      <div className="mb-5">adoptionHealth_currentMonth</div>
                      <div className="mb-5">adoptionHealth_bimonthly1</div>
                      <div className="mb-5">adoptionHealth_bimonthly2</div>
                      <div className="mb-5">adoptionHealth_bimonthly3</div>
                      <div className="mb-5">adoptionHealth_bimonthly4</div>
                      <div className="mb-5">adoptionHealth_bimonthly5</div>
                      <div className="mb-5">adoptionHealth_bimonthly6</div>
                      <div className="mb-5">adoptionHealth_bimonthly7</div>
                      <div className="mb-5">adoptionHealth_bimonthly8</div>
                      <div className="mb-5">adoptionHealth_bimonthly9</div>
                      <div className="mb-5">adoptionHealth_bimonthly10</div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mb-10">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f2f0f0] px-4 py-2 text-left text-sm font-medium text-black focus:outline-none">
                      <span>EWS Onboarding Health</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 max-h-[300px] h-full overflow-auto">
                      <div className="mb-5">onboardingHealth_health</div>
                      <div className="mb-5">onboardingHealth_score</div>
                      <div className="mb-5">onboardingHealth_accessDriver</div>
                      <div className="mb-5">
                        onboardingHealth_discoveryDriver
                      </div>
                      <div className="mb-5">
                        onboardingHealth_productUtilizationScoreDriver
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mb-10">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f2f0f0] px-4 py-2 text-left text-sm font-medium text-black focus:outline-none">
                      <span>EWS Retention Health</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 max-h-[300px] h-full overflow-auto">
                      <div className="mb-5">
                        retentionHealth_subscription_no
                      </div>
                      <div className="mb-5">retentionHealth_score</div>
                      <div className="mb-5">retentionHealth_riskBand</div>
                      <div className="mb-5">
                        retentionHealth_customerProfileScoreDriver
                      </div>
                      <div className="mb-5">
                        retentionHealth_productUtilizationDriver
                      </div>
                      <div className="mb-5">
                        retentionHealth_productProfileScoreDriver
                      </div>
                      <div className="mb-5">
                        retentionHealth_subscriptionEngagementScoreDriver
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mb-10">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f2f0f0] px-4 py-2 text-left text-sm font-medium text-black focus:outline-none">
                      <span>Accounts Sold To</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 max-h-[300px] h-full overflow-auto">
                      <div className="mb-5">soldTo_csn</div>
                      <div className="mb-5">soldTo_name</div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mb-10">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f2f0f0] px-4 py-2 text-left text-sm font-medium text-black focus:outline-none">
                      <span>Accounts Reseller</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 max-h-[300px] h-full overflow-auto">
                      <div className="mb-5">reseller_csn</div>
                      <div className="mb-5">reseller_name</div>
                      <div className="mb-5">reseller_localLanguageName</div>
                      <div className="mb-5">reseller_resellerType</div>
                      <div className="mb-5">reseller_address1</div>
                      <div className="mb-5">reseller_address2</div>
                      <div className="mb-5">reseller_address3</div>
                      <div className="mb-5">reseller_city</div>
                      <div className="mb-5">reseller_stateProvince</div>
                      <div className="mb-5">reseller_postalCode</div>
                      <div className="mb-5">reseller_country</div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mb-10">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f2f0f0] px-4 py-2 text-left text-sm font-medium text-black focus:outline-none">
                      <span>Accounts Nurture Reseller</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 max-h-[300px] h-full overflow-auto">
                      <div className="mb-5">nurtureReseller_csn</div>
                      <div className="mb-5">nurtureReseller_name</div>
                      <div className="mb-5">nurtureReseller_lockdate</div>
                      <div className="mb-5">
                        nurtureReseller_nurtureDiscountEligibility
                      </div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mb-10">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f2f0f0] px-4 py-2 text-left text-sm font-medium text-black focus:outline-none">
                      <span>EndCustomer Account</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 max-h-[300px] h-full overflow-auto">
                      <div className="mb-5">endCustomer_csn</div>
                      <div className="mb-5">endCustomer_name</div>
                      <div className="mb-5">endCustomer_type</div>
                      <div className="mb-5">endCustomer_address1</div>
                      <div className="mb-5">endCustomer_address2</div>
                      <div className="mb-5">endCustomer_address3</div>
                      <div className="mb-5">endCustomer_city</div>
                      <div className="mb-5">endCustomer_stateProvince</div>
                      <div className="mb-5">endCustomer_postalCode</div>
                      <div className="mb-5">endCustomer_country</div>
                      <div className="mb-5">endCustomer_individualFlag</div>
                      <div className="mb-5">endCustomer_namedAccountFlag</div>
                      <div className="mb-5">
                        endCustomer_parentIndustryGroup
                      </div>
                      <div className="mb-5">
                        endCustomer_parentIndustrySegment
                      </div>
                      <div className="mb-5">
                        endCustomer_primaryAdminFirstName
                      </div>
                      <div className="mb-5">
                        endCustomer_primaryAdminLastName
                      </div>
                      <div className="mb-5">endCustomer_primaryAdminEmail</div>
                      <div className="mb-5">endCustomer_teamId</div>
                      <div className="mb-5">endCustomer_teamName</div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mb-10">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f2f0f0] px-4 py-2 text-left text-sm font-medium text-black focus:outline-none">
                      <span>Contract Manager</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 max-h-[300px] h-full overflow-auto">
                      <div className="mb-5">contractManager_first</div>
                      <div className="mb-5">contractManager_last</div>
                      <div className="mb-5">contractManager_email</div>
                      <div className="mb-5">contractManager_address1</div>
                      <div className="mb-5">contractManager_address2</div>
                      <div className="mb-5">contractManager_address3</div>
                      <div className="mb-5">contractManager_city</div>
                      <div className="mb-5">contractManager_stateProvince</div>
                      <div className="mb-5">contractManager_postalCode</div>
                      <div className="mb-5">contractManager_country</div>
                      <div className="mb-5">contractManager_phone</div>
                      <div className="mb-5">contractManager_fax</div>
                      <div className="mb-5">contractManager_status</div>
                      <div className="mb-5">
                        contractManager_portalRegistration
                      </div>
                      <div className="mb-5">contractManager_doNotCall</div>
                      <div className="mb-5">contractManager_doNotEmail</div>
                      <div className="mb-5">contractManager_doNotMail</div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
            <div className="mb-10">
              <Disclosure>
                {({ open }) => (
                  <>
                    <Disclosure.Button className="flex w-full justify-between rounded-lg bg-[#f2f0f0] px-4 py-2 text-left text-sm font-medium text-black focus:outline-none">
                      <span>Software Coordinator</span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500 max-h-[300px] h-full overflow-auto">
                      <div className="mb-5">softwareCoordinator_first</div>
                      <div className="mb-5">softwareCoordinator_last</div>
                      <div className="mb-5">softwareCoordinator_email</div>
                      <div className="mb-5">softwareCoordinator_address1</div>
                      <div className="mb-5">softwareCoordinator_address2</div>
                      <div className="mb-5">softwareCoordinator_address3</div>
                      <div className="mb-5">softwareCoordinator_city</div>
                      <div className="mb-5">
                        softwareCoordinator_stateProvince
                      </div>
                      <div className="mb-5">softwareCoordinator_postalCode</div>
                      <div className="mb-5">softwareCoordinator_country</div>
                      <div className="mb-5">softwareCoordinator_phone</div>
                      <div className="mb-5">softwareCoordinator_fax</div>
                      <div className="mb-5">softwareCoordinator_status</div>
                      <div className="mb-5">
                        softwareCoordinator_portalRegistration
                      </div>
                      <div className="mb-5">softwareCoordinator_doNotCall</div>
                      <div className="mb-5">softwareCoordinator_doNotEmail</div>
                      <div className="mb-5">softwareCoordinator_doNotMail</div>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </div>
          </div>
        </div>
      </Box>

      <Modal
        open={backPopup}
        onClose={() => showBackPopup(false)}
        aria-labelledby="modal-back-title"
        aria-describedby="modal-back-description"
      >
        <div className="absolute -translate-x-2/4 bg-white h-[300px] max-w-[400px] w-full rounded-lg shadow-[24] p-4 left-2/4 top-[50%] translate-y-[-50%]">
          <div className="h-full flex flex-col justify-between">
            <h2 id="modal-back-title" className="text-xl font-bold mb-4">Confirm Campaign Dispatch</h2>
            <p id="modal-back-description">Are you sure you want to proceed with sending the campaign to {campaignCount} recipients?</p>
            <div className="flex justify-end mt-4">
              <button
                className="border px-4 py-2 font-[700] text-white bg-green-500 border-gray-300 rounded mr-2"
                onClick={() => handleConfirmBack(true)}
              >
                Yes
              </button>
              <button
                className="border px-4 py-2 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                onClick={() => handleConfirmBack(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
    </Modal>
    <Modal
            open={newOpen}
            onClose={() => setNewOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            classes={{ backgroundColor: "red" }}
          >
        <div className="absolute -translate-x-2/4 bg-white h-[746px] max-w-[1150px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
          <div className="h-full">

          <div className="mb-4">
              <label htmlFor="po_number" className="block text-gray-700 text-sm font-bold mb-2">Template Name</label>
              <input
                  type="text"
                  id="name"
                  name="name"
                  value={templateName}
                  onChange={handleHTMLNameChange}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  required
              />
          </div>
          
              <label
                htmlFor="message"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                HTML Template Content
              </label>
          <div className="mb-4">
              <textarea
                name="new_template"
                style={{ height: '530px' }} // Set custom height for textarea
                className="w-full outline-none rounded-md"
                value={newContent}
                onChange={handleHTMLContentChange}
              />
            </div>

            <div className="border-t">
              <div className="pt-4 text-right">
              <button
                  className="border px-2 py-1 font-[700] text-white bg-green-500 border-gray-300 rounded"
                  onClick={() => handleAdd()} style={{ cursor: 'pointer' }}
                >
                  Add
                </button>
                <button
                    className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                    onClick={() => {
                      setNewOpen(false);
                      // SetselectedAccount(null);
                    }}
                  >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
    </Modal>


    </>
  );
};

export default RunChampaign;
