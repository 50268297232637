import React, { useState, useEffect } from "react";
import {Box, Modal, Typography, Grid} from "@mui/material";
import Header from "../../components/Header.jsx";
import {BACKEND_BASE_URL} from "../../components/config/apiEnv.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext.js";
import { MdClose } from "react-icons/md";
import AddIcon from '@mui/icons-material/Add';


const AddQuotation = () => {
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    const { token } = useUser();
    const [isLoading, setIsLoading] = useState(false);
    const [dependency, SetDependency] = useState(false);
    const [allSalesStages, SetAllSalesStage] = useState(null);
    const [modelValue, SetModelValue] = useState(null);
    const [openassign, setOpenAssign] = useState(false);
    const [selectedCategory, SetSelectedSalesStage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);   
    const [formData, setFormData] = useState({
      quotation_date: '',
      quotation_no: '',
      name: '',
      general_total: '',
      sales_stage: '',
      opportunity: '',
      account: '',
      billing_contact: '',
      valid_until: '',
      updated_by: '',
      created_by: '',
  });


    
    const headers = {
        Authorization: `Bearer ${token}`,
    };


  useEffect(() => {
    if (token){
      GetAllSaltesStage();
    }
  }, [token, dependency]);

  const GetAllSaltesStage = () => {
    axios.get(BACKEND_BASE_URL + `v1/product/sales_stage/`, { headers })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response)
        if (response.status === 200) {
          SetAllSalesStage(response?.data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        
        setIsLoading(false)
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };


  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
      
  const handleAdd = (adding_value) => {
    const requestData = {
        name: selectedCategory || null,
    };
    axios
    .post(BACKEND_BASE_URL + `v1/product/${adding_value}/`, requestData, { headers })
    .then((response) => {
        console.log("🚀 ~ .then ~ response:", response)
        if (response.status === 201) {
          SetDependency(true)
          setOpenAssign(false)
          SetSelectedSalesStage(null)
        setError(null);
        toast.success("Product Master created Successfully", {
            position: toast.POSITION.TOP_RIGHT,
        });
        } else {
        toast.error("Error occured while displaying accountsfdfdsfds.", {
            position: toast.POSITION.TOP_RIGHT,
        });
        }
    })
    .catch((error) => {
        toast.error(error.response.data?.error, {
        position: toast.POSITION.TOP_RIGHT,
        });
    });
  };


  const handleSubmit = (e) => {
    setIsSubmitting(true);
    e.preventDefault();
      const requestData = {
        quotation_date: formData?.quotation_date,
        quotation_no: formData?.quotation_no,
        name: formData?.name,
        general_total: formData?.general_total,
        sales_stage: formData?.sales_stage,
        opportunity: formData?.opportunity,
        account: formData?.account,
        billing_contact: formData?.billing_contact,
        valid_until: formData?.valid_until,
        updated_by: formData?.updated_by,
        created_by: formData?.created_by,
    };

      axios
      .post(BACKEND_BASE_URL + `v1/product/quotation/`, requestData, { headers })
      .then((response) => {
          console.log("🚀 ~ .then ~ response:", response)
          if (response.status === 201) {
              navigate("/quotation")
          setError(null);
          toast.success("Quotation created Successfully", {
              position: toast.POSITION.TOP_RIGHT,
          });
          } else {
          toast.error("Error occured while displaying accountsfdfdsfds.", {
              position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false);
          }
      })
      .catch((error) => {
          toast.error(error.response.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false);
      });
  };
  const AddSalesStage = (value) => {
    SetModelValue(value)
    setOpenAssign(true);
    console.log("__________ Add category __________");
  };

    
    
  return (
    <>
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Header title="Product Master" subtitle="welcome to you Team" />
      </Box>
      <div className="max-w-[800px] mx-auto mt-8 pb-14 p-6 bg-[#f2f0f0] rounded shadow-md">
      <h2 className="text-2xl font-semibold mb-6">Quotation Form</h2>
      <form onSubmit={handleSubmit}>
    {error && (
      <div style={{ color: 'red', marginTop: '8px' }}>
        {error}
      </div>
    )}
    <div className="mb-4">
      <label
        htmlFor="quotation_date"
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        Quotation Date
      </label>
      <input
        type="date"
        id="quotation_date"
        name="quotation_date"
        value={formData.quotation_date}
        onChange={handleChange}
        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        required
      />
    </div>
    <div className="mb-4">
      <label
        htmlFor="quotation_no"
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        Quotation Number
      </label>
      <input
        type="text"
        id="quotation_no"
        name="quotation_no"
        value={formData.quotation_no}
        onChange={handleChange}
        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        required
      />
    </div>
    <div className="mb-4">
      <label
        htmlFor="name"
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        Name
      </label>
      <input
        type="text"
        id="name"
        name="name"
        value={formData.name}
        onChange={handleChange}
        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        required
      />
    </div>
    <div className="mb-4">
      <label
        htmlFor="general_total"
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        General Total
      </label>
      <input
        type="text"
        id="general_total"
        name="general_total"
        value={formData.general_total}
        onChange={handleChange}
        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        required
      />
    </div>
    <div className="mb-4">
      <label
        htmlFor="sales_stage"
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        Sales Stage
      </label>
      <select
        id="sales_stage"
        name="sales_stage"
        value={formData.sales_stage}
        onChange={handleChange}
        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        required
      >
        {allSalesStages ? (
          <option key="null" value="">Select a Sales Stage</option>
        ) : null}
        {allSalesStages?.map(stage => (
          <option key={stage.id} value={stage.id}>{stage.name}</option>
        ))}
      </select>
      <div onClick={() => AddSalesStage("sales_stage")} style={{ cursor: 'pointer' }}>
              <AddIcon />
          </div>
    </div>
    <div className="mb-4">
      <label
        htmlFor="opportunity"
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        Opportunity
      </label>
      <input
        type="text"
        id="opportunity"
        name="opportunity"
        value={formData.opportunity}
        onChange={handleChange}
        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
      />
    </div>
    <div className="mb-4">
      <label
        htmlFor="account"
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        Account
      </label>
      <input
        type="text"
        id="account"
        name="account"
        value={formData.account}
        onChange={handleChange}
        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
      />
    </div>
    <div className="mb-4">
      <label
        htmlFor="billing_contact"
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        Billing Contact
      </label>
      <input
        type="text"
        id="billing_contact"
        name="billing_contact"
        value={formData.billing_contact}
        onChange={handleChange}
        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
      />
    </div>
    <div className="mb-4">
      <label
        htmlFor="valid_until"
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        Valid Until
      </label>
      <input
        type="date"
        id="valid_until"
        name="valid_until"
        value={formData.valid_until}
        onChange={handleChange}
        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
      />
    </div>
    <div className="mb-4">
      <label
        htmlFor="updated_by"
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        Updated By
      </label>
      <input
        type="text"
        id="updated_by"
        name="updated_by"
        value={formData.updated_by}
        onChange={handleChange}
        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
      />
    </div>
    <div className="mb-4">
      <label
        htmlFor="created_by"
        className="block text-gray-700 text-sm font-bold mb-2"
      >
        Created By
      </label>
      <input
        type="text"
        id="created_by"
        name="created_by"
        value={formData.created_by}
        onChange={handleChange}
        className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
      />
    </div>
    <button
      type="submit"
      className={`bg-${error ? 'gray-300' : 'blue-500'} text-white px-4 py-2 rounded-md ${error ? 'cursor-not-allowed' : 'hover:bg-blue-600 focus:outline-none focus:bg-blue-600'}`}
      // disabled={!!error}
      disabled={isSubmitting}
>
      {isSubmitting ? "Submitting..." : "Submit"}
    </button>
  </form>

      </div>
    </Box>
    <Modal
        open={openassign}
        onClose={() => setOpenAssign(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{ backgroundColor: "red" }}
      >
        <div className="relative -translate-x-2/4 bg-white h-[350px] max-w-[600px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
          <div className="absolute top-2 right-3 cursor-pointer">
            <MdClose
              className="cursor-pointer text-xl"
              onClick={() => {
                setOpenAssign(false);
                // SetselectedAssignAccount(null);  
              }}
            />
          </div>
          <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
            Add Quotation {modelValue}
          </h3>
              <Box sx={{paddingY:'30px'}}>

            <Grid container spacing={2}>

              <Grid item sx={12} sm={3}>
                <Typography sx={{fontSize:'18px' , fontWeight:600}}> {modelValue} Name :</Typography>
              </Grid>
              <Grid item sx={12} sm={9}>
              <input
                  type="text"
                  id={modelValue}
                  name={modelValue}
                  value={selectedCategory}
                  onChange={(event) => {
                    SetSelectedSalesStage(event.target.value);
                  }}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                />
              </Grid>
            </Grid>
            <div className="max-w-[447px] w-full text-right mt-4">
              <button
                    className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded "
                    onClick={() => handleAdd(modelValue)}
                  >
                    Add
                  </button>
            </div>
              </Box>
        </div> 
      </Modal>
    </>
  );
};

export default AddQuotation;