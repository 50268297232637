import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import { mockDataTeam } from "../../data/mockData.js";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";

const Product = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [userData, SetUserData] = useState([]);
  const [loading, SetLoading] = useState(true);
  const [error, setError] = useState(null);

  const {token, user} = useUser()

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token){
    GetUserData();
    }
  }, [token]);


  const GetUserData = () => {
      axios.get(BACKEND_BASE_URL + `v1/product/product/`, {headers})
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response)
        if (response.status === 200) {
          SetUserData(response?.data?.product);
          SetLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }else {
            toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getRowId = (row) => row.id;

  const columns = [
    { field: "name", headerName: "Name" },
    { field: "serial_number", headerName: "Serial Number", width: 200 },
    { field: "account_name", headerName: "Account Name" },
    { field: "manufacturer", headerName: "Manufacturer" },
    { field: "product_type", headerName: "Product Type", width: 150 },
    { field: "product_category", headerName: "Product Category", width: 150 },
    { field: "price", headerName: "Price", width: 150 },
    { field: "product_status", headerName: "Product Status", width: 150 },
    { field: "warrenty_startDate", headerName: "Warrenty Start Date", width: 150 },
    { field: "warrenty_endDate", headerName: "Warrenty End Date", width: 150 },
    { field: "warrenty_period", headerName: "Warrenty Period", width: 150 },
    { field: "assigned_to", headerName: "Assigned To", width: 150 },
    { field: "created_at", headerName: "Created At", width: 150 },
    { field: "updated_at", headerName: "Updated At", width: 150 },

  ];


  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Product" subtitle="we are in the same team" />
      </Box>
      <Box sx={{display:"flex"  , gap:'10px' , justifyContent:'end'}}>
      <Box display="flex" marginBottom={2} justifyContent="end" alignItems="center">
          <button
            onClick={() => navigate('/add_product')}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] text-center text-lg"
            style={{ whiteSpace: 'nowrap' }}
          >
            Add Product
          </button>       
        </Box>
        </Box>

      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
         {/* <DataGrid rows={userData} columns={columns} getRowId={getRowId} /> */}
         <DataGrid
              rows={userData}
              columns={columns}
              getRowId={getRowId}
              components={{
                Toolbar: () => (
                  <div>
                    <GridToolbar
                    csvOptions={{
                      fileName: `product_trisita_${user.first_name} ${user.last_name}_${currDate}_${currTime}`,
                      fields : ["name", "serial_number", "account_name", "manufacturer", "product_type", "product_category", "price", "product_status", "warrenty_startDate", "warrenty_endDate", "warrenty_period", "assigned_to", "created_at", "updated_at"]                ,
                      }} />
                  </div>
                )
              }}
              checkboxSelection 
              disableRowSelectionOnClick                                                               
            />
      </Box>
    </Box>
  );
};

export default Product;
