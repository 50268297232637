import React, { useState, useEffect } from "react";
import Header from "../../components/Header.jsx";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { useNavigate, Link } from "react-router-dom";
import { useCSN } from "../../components/CSNProvider.jsx";
import { Box, Typography, useTheme, Modal } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import moment from 'moment';
import { MdClose } from "react-icons/md";

const CampaignHsitory = () => {
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { token } = useUser();

  const [open, setOpen] = useState(false);
  const [subsOpen, setSubsOpen] = useState(false);
  const [campaignData, SetCampaignData] = useState([]);
  const [tab, setTab] = useState(1);
  const [selectedSubscription, SetselectedSubscription] = useState(null);
  const [selectedCampaign, SetselectedCampaign] = useState(null);

  const [loading, SetLoading] = useState(true);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { csn } = useCSN();

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token) {
      GetCampaignHistory()
    }
  }, [csn,token]);

  const GetCampaignHistory = () => {
    axios.get(BACKEND_BASE_URL + `v1/api/campaign_list/${csn ? `${csn}` : ''}`, { headers })
      .then((response) => {
        if (response.status === 200) {
            SetCampaignData(response?.data?.Response)
            SetLoading(false)
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  const handleOpenModel = (subs_id) => {
    setSubsOpen(true);
    axios.get(BACKEND_BASE_URL + `v1/api/get_subscription/${subs_id}`, {headers})
      .then((response) => {
        if (response.status === 200) {
          SetselectedSubscription(response?.data);
          SetLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }else {
            toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleTabChange = (val) => {
    setTab(val);
  };

  const HandleViewDetails = (id) => {
    setOpen(true);
    axios.get(BACKEND_BASE_URL + `v1/api/campaign/${id}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetselectedCampaign(response?.data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

const FormattedDateCell = ({ value }) => {
  const formattedDate = moment(value).format('MMMM DD, YYYY hh:mm:ss A');
  return <div>{formattedDate}</div>;
};

const getRowId = (row) => row.id;

const columns = [
    
    { field: "subscription", headerName: "#Subscriptionr", width: 180,
    renderCell: (params, index) => (
      (
        <div>
          <button
            onClick={() => handleOpenModel(params?.row.subscription_id)}
            className="action-button bg-white text-black px-3 py-1 rounded"
          >
            {params.value}
          </button>
        </div>
      )
    ),},
    {
        field: "user_assign",
        headerName: "BD Person Name",
        width: 180,
        renderCell: (params) => (
            <div>
          {params.value && params.value ? (
              params.value
              ) : (
                  <span style={{ color: "red" }}>Undefined</span>
                  )}
        </div>
      ),
    },
    { field: "account_name", headerName: "Account", width: 250,
    renderCell: (params) => {
        const { value: Account } = params;
        const maxChars = 20;
        
        return (
            <div style={{ whiteSpace: "normal", maxWidth: "300px" }}>
            {Account?.length > maxChars ? Account : Account?.slice(0, maxChars)}
          </div>
        );
    },
    },
    { field: 'sent_at', headerName: 'Sent at', width: 200, renderCell: (params) => <FormattedDateCell {...params} /> },
    { field: "recipient", headerName: "Recipient", width: 200},
    { field: "AccountStatus", headerName: "Autodesk Account Status", width: 140 },
    { field: "contract_status", headerName: "Trisita Account Status", width: 140 },
    {
      field: "branch",
      headerName: "Branch",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    { field: "status", headerName: "Message Status", width: 140 },
    {
      field: "",
      headerName: "Action",
      width: 150,
      renderCell: (params, index) => (
        <div className="flex items-center w-full justify-center">
          <button
            onClick={() => HandleViewDetails(params?.row.id)}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded"
          >
            View Details
          </button>
        </div>
      ),
    }
  ];


  return (
    <>
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Header title="Campaign History" subtitle="welcome to you Team" />
      </Box>
      <Box
        m="8px 0 0 0"
        width="100%"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <Box sx={{backgroundColor:colors.primary[400] , borderBottom:'0.8px solid' , borderColor:colors.grey[900], padding:2,}}>
        <Typography variant="h4" sx={{fontWeight:'600'}} component="h3">
            Table Data
          </Typography>
        </Box>
        {loading ? (
          <p>Loading data...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <DataGrid
            rows={campaignData}
            columns={columns}
            getRowId={getRowId}
            components={{ Toolbar: GridToolbar }}
            checkboxSelection 
            disableRowSelectionOnClick
          />
        )}
      </Box>
    </Box>
    <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{ backgroundColor: "red" }}
      >
        <div className="relative -translate-x-2/4 bg-white h-[300px] max-w-[800px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
          <div className="absolute top-2 right-3 cursor-pointer">
            <MdClose
              className="cursor-pointer text-xl"
              onClick={() => {
                setOpen(false);
                SetselectedCampaign(null);
              }}
            />
          </div>
          <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
            Details Page
          </h3>
          <div>
              <p>Recipient: {selectedCampaign?.recipient}</p>
              <p>Subject: {selectedCampaign?.subject}</p>
              <p>Message :</p><div dangerouslySetInnerHTML={{ __html: selectedCampaign?.message_body }} />
              <p>Status: {selectedCampaign?.status}</p>
            </div>
              
        </div>
      </Modal>

      <Modal
        open={subsOpen}
        onClose={() => setSubsOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{ backgroundColor: "red" }}
      >
        {/* <div className="absolute -translate-x-2/4 bg-white -translate-y-2/4 w-[500] rounded-lg shadow-[24] p-4 left-2/4 top-[18%]"> */}
        <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[500px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
          <div className="h-full">
          <div className="text-black flex border-b border-gray-300">
              <Link
                to={"#"}
                className={`${
                  tab === 1
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(1)}
              >
                End Customer
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 2
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(2)}
              >
                Reseller
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 3
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(3)}
              >
                Sold to
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 4
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(4)}
              >
                Contract
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 5
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(5)}
              >
                Line Items
              </Link>
            </div>
            <div className="mt-5">
            </div>
            <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">
              {tab === 1 && (
                <>
                <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                End Customer Details
              </h1>
              {selectedSubscription ? (
                <>
                <div className="w-full">
                          <div className="flex my-4">
                            <div className="w-[200px]">Subscription#</div>
                            <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                          </div>
                          <div className="flex my-4">
                            <div className="w-[200px]">CSN</div>
                            <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.endCustomerCsn}</div>
                          </div>
                          <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.name}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Address Line 1</div>
                          <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.address1}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Address Line 2</div>
                          <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.address2}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">City</div>
                          <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.city}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">State</div>
                          <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.stateProvince}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Country</div>
                          <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.country}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Postal</div>
                          <div className="whitespace-pre">{selectedSubscription?.endCustomer_account.postalCode}</div>
                        </div>
                       </div>
                        </>
                        ) : (
                          <p>Loading data...</p>
                        )}
                      <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                        Contract Manager Details
                      </h1>
                      {selectedSubscription ? (
                      <>
                      <div className="w-full">
                          <div className="flex my-4">
                          <div className="w-[200px]">First Name</div>
                          <div className="whitespace-pre">{selectedSubscription?.endCustomer_contractManager.first}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Last Name</div>
                          <div className="whitespace-pre">{selectedSubscription?.endCustomer_contractManager.last}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Email</div>
                          <div className="whitespace-pre">{selectedSubscription?.endCustomer_contractManager.email}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Phone</div>
                          <div className="whitespace-pre">{selectedSubscription?.endCustomer_contractManager.phone}</div>
                        </div>
                      </div>
                        </>
                        ) : (
                          <p>Loading data...</p>
                        )}
                </>
              )}
              {tab === 2 && (
                <>
                <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                        Reseller Details
                      </h1>
                      {selectedSubscription ? (
                      <>
                      <div className="w-full">
                          <div className="flex my-4">
                          <div className="w-[200px]"> Opportunity#</div>
                          <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">CSN</div>
                          <div className="whitespace-pre">{selectedSubscription?.accounts_reseller.csn}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">{selectedSubscription?.accounts_reseller.name}</div>
                        </div>
                      </div>
                        </>
                        ) : (
                          <p>Loading data...</p>
                        )}
                </>
               
              )}
              {tab === 3 && (
                <>
                <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                        Sold To Details
                      </h1>
                      {selectedSubscription ? (
                      <>
                      <div className="w-full">
                          <div className="flex my-4">
                          <div className="w-[200px]"> Subscription#</div>
                          <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">CSN</div>
                          <div className="whitespace-pre">{selectedSubscription?.accounts_soldTo.csn}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">{selectedSubscription?.accounts_soldTo.name}</div>
                        </div>
                      </div>
                        </>
                        ) : (
                          <p>Loading data...</p>
                        )}
                </>
              )}
              {tab === 4 && (
                <>
                <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                        Contract Details
                      </h1>
                      {selectedSubscription ? (
                      <>
                      <div className="w-full">
                          <div className="flex my-4">
                          <div className="w-[200px]"> Opportunity#</div>
                          <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Contract#</div>
                          <div className="whitespace-pre">{selectedSubscription?.contract.contract_number}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Type</div>
                          <div className="whitespace-pre">{selectedSubscription?.contract.subType}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Term</div>
                          <div className="whitespace-pre">{selectedSubscription?.contract.contract_term}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Duration</div>
                          <div className="whitespace-pre">{selectedSubscription?.contract.contract}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Retention Health</div>
                          <div className="whitespace-pre">{selectedSubscription?.ews_retentionHealth}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Sub end date</div>
                          <div className="whitespace-pre">{selectedSubscription?.endDate}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Quantity</div>
                          <div className="whitespace-pre">{selectedSubscription?.quantity}</div>
                        </div>
                      </div>
                        </>
                        ) : (
                          <p>Loading data...</p>
                        )}
                </>
              )}
              {tab === 5 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black"> Line Items 1 Details </h1>
                  {selectedSubscription ? (
                      <>
                      <div className="w-1/2">
                      <div className="flex my-4">
                          <div className="w-[200px]"> Serial#</div>
                          <div className="whitespace-pre">{selectedSubscription?.subscriptionReferenceNumber}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Renewal SKU</div>
                          <div className="whitespace-pre">{""}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">SKU Description</div>
                          <div className="whitespace-pre">{selectedSubscription?.id}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Quantity</div>
                          <div className="whitespace-pre">{selectedSubscription?.quantity}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Seats</div>
                          <div className="whitespace-pre">{selectedSubscription?.seats}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Renewed Quantity</div>
                          <div className="whitespace-pre">{selectedSubscription?.id}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Deployment</div>
                          <div className="whitespace-pre">{selectedSubscription?.deployment}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Status</div>
                          <div className="whitespace-pre">{selectedSubscription?.subscriptionStatus}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Asset Status</div>
                          <div className="whitespace-pre">{selectedSubscription?.productStatus}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Support Program</div>
                          <div className="whitespace-pre">{""}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Program Eligibility</div>
                          <div className="whitespace-pre">{""}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Renew</div>
                          <div className="whitespace-pre">{""}</div>
                        </div>
                      </div>
                      </>
                        ) : (
                          <p>Loading data...</p>
                        )}

                </>
              )}
            </div>
            <div className="border-t">
              <div className="pt-4 text-right">
                <button
                  className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                  onClick={() => {
                    setSubsOpen(false);
                    SetselectedSubscription(null);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CampaignHsitory;
