import React, { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { useUser } from "../../components/UserProvider.js";
import { Box, Modal, Grid, Typography, TextField, MenuItem, Button, Tooltip } from '@mui/material';
import Header from "../../components/Header.jsx";
import ViewModal from "../../components/ViewModal/ViewModal.js";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ManageTemplates = () => {
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [originalContent, setOriginalContent] = useState("");
  const [editedContent, setEditedContent] = useState("");
  const [templateName, setTemplateName] = useState("");
  const [newContent, setNewContent] = useState("");
  
  const [newOpen, setNewOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const { token } = useUser();


  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (token) {
      axios
        .get(BACKEND_BASE_URL + `v1/api/templates/`, { headers })
        .then((response) => {
          setTemplates(response.data);
        })
        .catch((error) => {
          toast.error(error.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  }, [token]);

  const handleTemplateChange = (event) => {
    const templateName = event.target.value;
    setSelectedTemplate(templateName);

    const selectedTemplate = templates.find(
      (template) => template.name === templateName
    );
    if (selectedTemplate) {
      setOriginalContent(selectedTemplate.content);
      setEditedContent(selectedTemplate.content);
    }
  };

  const handleContentChange = (event) => {
    setEditedContent(event.target.value);
  };

  const handleHTMLContentChange = (event) => {
    setNewContent(event.target.value);
  };

  const handleHTMLNameChange = (event) => {
    setTemplateName(event.target.value);
  };

  

  
  const handleSubmit = () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .put(
        BACKEND_BASE_URL + `v1/api/templates/`,
        { name: selectedTemplate, content: editedContent },
        { headers }
      )
      .then((response) => {
        if (response.status === 200) {
          toast.success("Template content updated successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setEditedContent(""); 
          setSelectedTemplate(""); 
          setOriginalContent(""); 
          setOpen(false); 
        } else {
          toast.error("Error occurred while updating template.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.error || "An error occurred", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleView = () => {
    setOpen(!open);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const handleNewOpen = () => {
    setNewOpen(true)
  };

  const handleAdd = () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .post(
        BACKEND_BASE_URL + `v1/api/add_html_template/`,
        { name: templateName, content: newContent },
        { headers }
      )
      .then((response) => {
        if (response.status === 201) { // Changed to 200 for success
          toast.success("Template added successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setNewContent(""); // Clear the textarea
          setTemplateName(""); // Deselect the template
          setNewOpen(false); // Close the modal
        } else {
          toast.error("Error occurred while adding template.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.error || "An error occurred", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <Box m="20px">
        <Box display="flex" justifyContent="space-between" alignItems="center" flexWrap="wrap">
          <Header title="Manage Templates" />
        </Box>
        <Box sx={{display:"flex"  , gap:'10px' , justifyContent:'end'}}>
      <Box display="flex" marginBottom={2} justifyContent="end" alignItems="center">
          <button
            onClick={() => handleNewOpen()}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] text-center text-lg"
            style={{ whiteSpace: 'nowrap' }}
          >
            Add HTML Template.
          </button>       
        </Box>
        </Box>
        <div className="mx-auto mt-30 p-6 max-w-[1000px] w-full bg-[#f2f0f0] rounded shadow-md">
          <div className="mb-4">
            <label
              htmlFor="templateSelect"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Select Template
            </label>
            <select
              id="templateSelect"
              className="w-full outline-none rounded-md"
              value={selectedTemplate}
              onChange={handleTemplateChange}
            >
              <option value="">Select a template</option>
              {templates.map((template) => (
                <option key={template.name} value={template.name}>
                  {template.name}
                </option>
              ))}
            </select>
          </div>

          {selectedTemplate && (
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                Template Content
              </label>
              <textarea
                name="message"
                style={{ height: '600px' }} // Set custom height for textarea
                className="w-full outline-none rounded-md"
                value={editedContent}
                onChange={handleContentChange}
              />
            </div>
          )}

          <div className="flex items-center justify-end">
            <button
              type="button"
              className={`bg-${error ? "gray-300" : "blue-500"} text-white px-4 mr-3 py-2 rounded-md ${
                error
                  ? "cursor-not-allowed"
                  : "hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              }`}
              disabled={!!error || !selectedTemplate}
              onClick={handleSubmit}
            >
              Save
            </button>
            <button
              type="button"
              className={`bg-${error ? "gray-300" : "blue-500"} text-white px-4 py-2 rounded-md ${
                error
                  ? "cursor-not-allowed"
                  : "hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              }`}
              disabled={!!error || !selectedTemplate}
              onClick={handleView}
            >
              View
            </button>
          </div>
        </div>
      </Box>

      {open && <ViewModal data={originalContent} open={open} closeModal={closeModal} />}

      {/* ToastContainer for notifications */}
      <ToastContainer />
      
      <Modal
            open={newOpen}
            onClose={() => setNewOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            classes={{ backgroundColor: "red" }}
          >
        <div className="absolute -translate-x-2/4 bg-white h-[746px] max-w-[1150px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
          <div className="h-full">

          <div className="mb-4">
              <label htmlFor="po_number" className="block text-gray-700 text-sm font-bold mb-2">Template Name</label>
              <input
                  type="text"
                  id="name"
                  name="name"
                  value={templateName}
                  onChange={handleHTMLNameChange}
                  className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                  required
              />
          </div>
          
              <label
                htmlFor="message"
                className="block text-gray-700 text-sm font-bold mb-2"
              >
                HTML Template Content
              </label>
          <div className="mb-4">
              <textarea
                name="new_template"
                style={{ height: '530px' }} // Set custom height for textarea
                className="w-full outline-none rounded-md"
                value={newContent}
                onChange={handleHTMLContentChange}
              />
            </div>

            <div className="border-t">
              <div className="pt-4 text-right">
              <button
                  className="border px-2 py-1 font-[700] text-white bg-green-500 border-gray-300 rounded"
                  onClick={() => handleAdd()} style={{ cursor: 'pointer' }}
                >
                  Add
                </button>
                <button
                    className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                    onClick={() => {
                      setNewOpen(false);
                      // SetselectedAccount(null);
                    }}
                  >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
    </Modal>

    </>
  );
};

export default ManageTemplates;
