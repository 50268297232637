import React, { useState, useContext, createContext } from "react";

const YearContext = createContext();

export const YearProvider = ({ children }) => {
  const [year, setYear] = useState("");

  const HandleYear= (year) => {
    setYear(year)
  } 

  return (
    <YearContext.Provider value={{ year, HandleYear }}>
      {children}
    </YearContext.Provider>
  );
};

export const useYear = () => {
  return useContext(YearContext);
};