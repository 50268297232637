import React, { createContext, useContext, useState } from 'react';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {

  const HandleLogout = () => {
    localStorage.removeItem('user');
    localStorage.removeItem('token');
  }

  const isTokenExpired = (token) => {
    if (!token) {
      return true;
    }

    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    return decodedToken.exp < currentTime;
  };

  const checkAuthentication = () => {
    const token = localStorage.getItem('token');
    return token && !isTokenExpired(token);
  };

  return (
    <AuthContext.Provider
      value={{
        checkAuthentication, HandleLogout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
