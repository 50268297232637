import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import { mockDataTeam } from "../../data/mockData.js";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme, MenuItem, FormControl, Select, InputLabel } from "@mui/material";


const UploadPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()


  const {token, user} = useUser()
  const [uploadType, setUploadType] = useState("");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleUploadTypeChange = (event) => {
    const selectedType = event.target.value;
    setUploadType(selectedType);

    if (selectedType === "price_list") {
      navigate("/upload_price_list");
    } else if (selectedType === "quotation") {
      navigate("/upload_quotation");
    }
  };

  useEffect(() => {
    if (token){
    }
  }, [token]);


  const getRowId = (row) => row.id;

  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Upload" subtitle="we are in the same team" />
      </Box>
      {/* <Box sx={{display:"flex"  , gap:'10px' , justifyContent:'end'}}>
      <Box display="flex" marginBottom={2} justifyContent="end" alignItems="center">
          <button
            onClick={() => navigate('/upload_loading_po_list')}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] text-center text-lg"
            style={{ whiteSpace: 'nowrap' }}
          >
            Upload Loading PO History
          </button>       
        </Box>
        </Box> */}
        <Box display="flex" alignItems="center" gap="20px" mb="20px">
        <Typography>Select a type of Upload</Typography>
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel>Upload Type</InputLabel>
          <Select
            value={uploadType}
            onChange={handleUploadTypeChange}
            label="Upload Type"
          >
            <MenuItem value="price_list">Price List</MenuItem>
            <MenuItem value="quotation">Quotation</MenuItem>
          </Select>
        </FormControl>
      </Box>
      
     
    </Box>
  );
};

export default UploadPage;
