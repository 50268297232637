import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import {
  Box,
  Button,
  Divider,
  Grid,
  InputBase,
  MenuItem,
  Select,
  Typography,
  useTheme,
  Autocomplete,
  TextField,
  Modal,
} from "@mui/material";
import { MdClose } from "react-icons/md";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FiFileText } from "react-icons/fi";
import { BsGraphDown } from "react-icons/bs";
import { GiNetworkBars } from "react-icons/gi";
import { GoDownload } from "react-icons/go";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import ReactApexChart from "react-apexcharts";
import { DateTime } from "luxon";
import Loader from "../../components/Loader.jsx";
import { useCSN } from "../../components/CSNProvider.jsx";
import { useUser } from "../../components/UserProvider.js";
import CircleLoader from "../../components/Loader/Loader.js";
import ExportToExcel from "../../components/ExportToExcel.js";
import { useYear } from "../../components/YearProvider.js";


const Subscription = () => {
  const navigate = useNavigate();
  const { csn } = useCSN();
  const { year } = useYear();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [clickedStates, setClickedStates] = useState([]);
  const [selectedDataPoints, setSelectedDataPoints] = useState([]);
  
  const [isClicked, setIsClicked] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [tab, setTab] = useState(1);
  const [isChartLoading, SetisChartLoading] = useState(true);
  const [loading, SetLoading] = useState(true);
  const [subscriptionData, SetSubscriptionData] = useState([]);
  const [lastUpdated, setLastUpdated] = useState([]);
  
  const [searchedData, setSearchedData] = useState([]);
  const [selectedSubscription, SetselectedSubscription] = useState(null);
  const [selectedOnBoard, SetSelectedOnBoard] = useState(null);
  console.log("🚀 ~ Subscription ~ selectedOnBoard:", selectedOnBoard)

  const [subscriptionDataForOnboardData, SetsubscriptionDataForOnboardData] =
    useState([]);
  const [
    subscriptionDataForProductlineChart,
    SetsubscriptionDataForProductlineChart,
  ] = useState([]);

  const [subscriptionAllData, SetSubscriptionAllData] = useState([]);
  const [subscriptionTableData, SetSubscriptionTableData] = useState([]);
  const [DoubleFilter, setDoubleFilter] = useState([]);
  const [accountsname, setAccountsName] = useState(null);

  const [speedometerratio, SetSpeedoMeterRatio] = useState();
  const [seatchartsdata, SetSeatsChart] = useState([
    { value: "", label: "", chartname: "" },
  ]);
  const [onboardchartsdata, SetOnBoardSeatsChart] = useState([
    { value: "", label: "" },
  ]);
  const [onBoardHealthAllData, SetOnBoardHealthAllData] = useState({});
  const [linechartname, setLineChartName] = useState("By product line");

  const [totalProductlinecodeSeats, setTotalProductlinecodeSeats] = useState(
    {}
  );
  const [totalAccountNameSeats, setTotalAccountNameSeats] = useState({});
  const [totalTeamNameSeats, setTotalTeamNameSeats] = useState({});
  const [top50ProductLinecode, setTop50ProductLinecode] = useState({});
  const [top50AcoountName, setTop50AccountName] = useState({});
  const [top50TeamName, setTop50TeamName] = useState({});

  const [totalLastPurchaseYearSeats, setTotalLastPurchaseYearSeats] = useState(
    {}
  );
  const [percentageInactive, setPercentageInactive] = useState(0);
  const [percentageExpiredContract, setPercentageExpiredContract] = useState(0);

  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState(null);
  const [selectedValue, setSelectedValue] = useState([]);
  const [branchfilteredData, setBranchFilteredData] = useState([]);
  const [searchBranch, setSearchBranch] = useState(null);

  const [branches, setBranches] = useState([]);

  const { token , user} = useUser();


  const [openassign, setOpenAssign] = useState(false);
  const [allchoices, SetAllChoices] = useState(null);
  const [selectedSubsTrigger, SetselectedsubsTrigger] = useState([]);
  const [triggerSubsID, setTriggerSubsID] = useState(null);
  console.log("🚀 ~ Subscription ~ selectedSubsTrigger:", selectedSubsTrigger)
  const [userType, setUserType] = useState(null);
  console.log("🚀 ~ Subscription ~ userType:", userType)

  useEffect(() => {
    if (token){
      const user = localStorage.getItem("user");
      if (user) {
        const userData = JSON.parse(user);
        setUserType(userData.user_type);
      }
    }
    }, [token]);

  
  useEffect(() => {
    if (selectedOnBoard === null) {
      console.log("null onboard");
    } else {
      const onBoardChart = onBoardHealthAllData[selectedOnBoard];
      SetSubscriptionTableData(onBoardChart);
      SetsubscriptionDataForOnboardData(onBoardChart);
    }
  }, [selectedOnBoard]);

  const handleOpenModel = (subs_id) => {
    setOpen(true);
    axios
      .get(BACKEND_BASE_URL + `v1/api/get_subscription/${subs_id}`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetselectedSubscription(response?.data);
          SetLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  const handleTabChange = (val) => {
    setTab(val);
  };

  useEffect(() => {
    const total_productlinecode_seats = {};
    const total_account_name_seats = {};
    const total_team_name_seats = {};
    const total_last_purchase_year_seats = {};
    const ratio_for_inactiveuser_and_expiredsubs = {};
    const total_subscriptions = subscriptionData?.length;
    let inactive_count = 0;
    let expired_contract = 0;
    const currentYear = DateTime.now().year;

    subscriptionData?.forEach((subs) => {
      const product_line_code = subs.productLineCode;
      const account_name = subs["endCustomer.account.name"];
      const team_name = subs["endCustomer.account.teamName"];
      const last_purchase_date = subs.lastPurchaseDate;
      const inactive_user = subs["endCustomer.contractManager.status"];

      const seats = parseInt(subs.seats, 10) || 0;

      total_productlinecode_seats[product_line_code] =
        total_productlinecode_seats[product_line_code] || [];
      total_productlinecode_seats[product_line_code].push(subs);

      total_account_name_seats[account_name] =
        total_account_name_seats[account_name] || [];
      total_account_name_seats[account_name].push(subs);

      total_team_name_seats[team_name] = total_team_name_seats[team_name] || [];
      total_team_name_seats[team_name].push(subs);

      if (inactive_user === "Inactive") {
        inactive_count++;
      }
      if (subs.trisita_status === "Expired") {
        expired_contract++;
      }

      if (last_purchase_date) {
        const last_purchase_year = DateTime.fromFormat(
          last_purchase_date,
          "yyyy-MM-dd"
        ).year;

        if (last_purchase_year >= currentYear - 1) {
          total_last_purchase_year_seats[last_purchase_year] =
            total_last_purchase_year_seats[last_purchase_year] || [];
          total_last_purchase_year_seats[last_purchase_year].push(subs);
        }
      }
    });

    let percentage_inactive = 0;
    let percentage_expired_contract = 0;
    if (total_subscriptions > 0) {
      percentage_inactive = (inactive_count / total_subscriptions) * 100;
      percentage_expired_contract =
        (expired_contract / total_subscriptions) * 100;
    }

    ratio_for_inactiveuser_and_expiredsubs.percentage_inactive = parseFloat(
      percentage_inactive.toFixed(2)
    );
    ratio_for_inactiveuser_and_expiredsubs.percentage_expired_contract =
      parseFloat(percentage_expired_contract.toFixed(2));

    const productlineArray = Object.entries(total_productlinecode_seats);
    productlineArray.sort((a, b) => b[1].length - a[1].length);

    const top50Productlinecode = {};
    productlineArray
      ?.slice(0, 50)
      ?.map((item) => (top50Productlinecode[item[0]] = item[1]));

    const accountnamedataArray = Object.entries(total_account_name_seats);
    accountnamedataArray.sort((a, b) => b[1].length - a[1].length);

    const top50Accountname = {};
    accountnamedataArray
      ?.slice(0, 50)
      ?.map((item) => (top50Accountname[item[0]] = item[1]));

    const teamnamedataArray = Object.entries(total_team_name_seats);
    teamnamedataArray.sort((a, b) => b[1].length - a[1].length);

    const top50teamname = {};
    teamnamedataArray
      ?.slice(0, 50)
      ?.map((item) => (top50teamname[item[0]] = item[1]));

    setTotalProductlinecodeSeats(total_productlinecode_seats);
    setTotalAccountNameSeats(total_account_name_seats);
    setTotalTeamNameSeats(total_team_name_seats);
    setTop50ProductLinecode(top50Productlinecode);
    setTotalLastPurchaseYearSeats(total_last_purchase_year_seats);

    setTop50AccountName(top50Accountname);
    setTop50TeamName(top50teamname);
    const totalSubscriptions = subscriptionData?.length;
    const percentageInactive = (inactive_count / totalSubscriptions) * 100;
    const percentageExpiredContract =
      (expired_contract / totalSubscriptions) * 100;

    setPercentageInactive(percentageInactive.toFixed(2));
    setPercentageExpiredContract(percentageExpiredContract.toFixed(2));

    const veryHighData = subscriptionData?.filter((item) => {
      return item["retention_health_riskBand"] === "Very High";
    });

    const highData = subscriptionData?.filter((item) => {
      return item["retention_health_riskBand"] === "High";
    });
    const mediumData = subscriptionData?.filter((item) => {
      return item["retention_health_riskBand"] === "Medium";
    });
    const lowData = subscriptionData?.filter((item) => {
      return item["retention_health_riskBand"] === "Low";
    });
    const veryLowData = subscriptionData?.filter((item) => {
      return item["retention_health_riskBand"] === "Very Low";
    });
    const undefinedData = subscriptionData?.filter((item) => {
      return item["retention_health_riskBand"] === null;
    });
    const onboardall = {};
    onboardall["Very High"] = veryHighData || [];
    onboardall["High"] = highData || [];
    onboardall["Medium"] = mediumData || [];
    onboardall["Low"] = lowData || [];
    onboardall["Very Low"] = veryLowData || [];
    onboardall["Undefined"] = undefinedData || [];
    console.log("🚀🚀🚀______________________🚀🚀🚀 ~ useEffect ~ onboardall:", onboardall)

    SetOnBoardHealthAllData(onboardall);
  }, [subscriptionData]);

  useEffect(() => {
    const total_productlinecode_seats = {};
    const total_account_name_seats = {};
    const total_team_name_seats = {};
    const total_last_purchase_year_seats = {};
    const ratio_for_inactiveuser_and_expiredsubs = {};
    const total_subscriptions = subscriptionData?.length;
    let inactive_count = 0;
    let expired_contract = 0;
    const currentYear = DateTime.now().year;

    subscriptionDataForOnboardData?.forEach((subs) => {
      const product_line_code = subs.productLineCode;
      const account_name = subs["endCustomer.account.name"];
      const team_name = subs["endCustomer.account.teamName"];
      const last_purchase_date = subs.lastPurchaseDate;
      const inactive_user = subs["endCustomer.contractManager.status"];

      const seats = parseInt(subs.seats, 10) || 0;

      total_productlinecode_seats[product_line_code] =
        total_productlinecode_seats[product_line_code] || [];
      total_productlinecode_seats[product_line_code].push(subs);

      total_account_name_seats[account_name] =
        total_account_name_seats[account_name] || [];
      total_account_name_seats[account_name].push(subs);

      total_team_name_seats[team_name] = total_team_name_seats[team_name] || [];
      total_team_name_seats[team_name].push(subs);

      if (inactive_user === "Inactive") {
        inactive_count++;
      }
      if (subs.trisita_status === "Expired") {
        expired_contract++;
      }

      if (last_purchase_date) {
        const last_purchase_year = DateTime.fromFormat(
          last_purchase_date,
          "yyyy-MM-dd"
        ).year;

        if (last_purchase_year >= currentYear - 1) {
          total_last_purchase_year_seats[last_purchase_year] =
            total_last_purchase_year_seats[last_purchase_year] || [];
          total_last_purchase_year_seats[last_purchase_year].push(subs);
        }
      }
    });

    let percentage_inactive = 0;
    let percentage_expired_contract = 0;
    if (total_subscriptions > 0) {
      percentage_inactive = (inactive_count / total_subscriptions) * 100;
      percentage_expired_contract =
        (expired_contract / total_subscriptions) * 100;
    }

    ratio_for_inactiveuser_and_expiredsubs.percentage_inactive = parseFloat(
      percentage_inactive.toFixed(2)
    );
    ratio_for_inactiveuser_and_expiredsubs.percentage_expired_contract =
      parseFloat(percentage_expired_contract.toFixed(2));

    const productlineArray = Object.entries(total_productlinecode_seats);
    productlineArray.sort((a, b) => b[1].length - a[1].length);

    const top50Productlinecode = {};
    productlineArray
      ?.slice(0, 50)
      ?.map((item) => (top50Productlinecode[item[0]] = item[1]));

    const accountnamedataArray = Object.entries(total_account_name_seats);
    accountnamedataArray.sort((a, b) => b[1].length - a[1].length);

    const top50Accountname = {};
    accountnamedataArray
      ?.slice(0, 50)
      ?.map((item) => (top50Accountname[item[0]] = item[1]));

    const teamnamedataArray = Object.entries(total_team_name_seats);
    teamnamedataArray.sort((a, b) => b[1].length - a[1].length);

    const top50teamname = {};
    teamnamedataArray
      ?.slice(0, 50)
      ?.map((item) => (top50teamname[item[0]] = item[1]));

    setTotalProductlinecodeSeats(total_productlinecode_seats);
    setTotalAccountNameSeats(total_account_name_seats);
    setTotalTeamNameSeats(total_team_name_seats);
    setTop50ProductLinecode(top50Productlinecode);
    setTotalLastPurchaseYearSeats(total_last_purchase_year_seats);

    setTop50AccountName(top50Accountname);
    setTop50TeamName(top50teamname);
    const totalSubscriptions = subscriptionData.length;
    const percentageInactive = (inactive_count / totalSubscriptions) * 100;
    const percentageExpiredContract =
      (expired_contract / totalSubscriptions) * 100;

    setPercentageInactive(percentageInactive.toFixed(2));
    setPercentageExpiredContract(percentageExpiredContract.toFixed(2));
  }, [subscriptionDataForOnboardData]);

  useEffect(() => {
    const total_productlinecode_seats = {};
    const total_account_name_seats = {};
    const total_team_name_seats = {};
    const total_last_purchase_year_seats = {};
    const ratio_for_inactiveuser_and_expiredsubs = {};
    const total_subscriptions = subscriptionData?.length;
    let inactive_count = 0;
    let expired_contract = 0;
    const currentYear = DateTime.now().year;

    subscriptionData?.forEach((subs) => {
      const product_line_code = subs.productLineCode;
      const account_name = subs["endCustomer.account.name"];
      const team_name = subs["endCustomer.account.teamName"];
      const last_purchase_date = subs.lastPurchaseDate;
      const inactive_user = subs["endCustomer.contractManager.status"];

      const seats = parseInt(subs.seats, 10) || 0;

      total_productlinecode_seats[product_line_code] =
        total_productlinecode_seats[product_line_code] || [];
      total_productlinecode_seats[product_line_code].push(subs);

      total_account_name_seats[account_name] =
        total_account_name_seats[account_name] || [];
      total_account_name_seats[account_name].push(subs);

      total_team_name_seats[team_name] = total_team_name_seats[team_name] || [];
      total_team_name_seats[team_name].push(subs);

      if (inactive_user === "Inactive") {
        inactive_count++;
      }
      if (subs.trisita_status === "Expired") {
        expired_contract++;
      }

      if (last_purchase_date) {
        const last_purchase_year = DateTime.fromFormat(
          last_purchase_date,
          "yyyy-MM-dd"
        ).year;

        if (last_purchase_year >= currentYear - 1) {
          total_last_purchase_year_seats[last_purchase_year] =
            total_last_purchase_year_seats[last_purchase_year] || [];
          total_last_purchase_year_seats[last_purchase_year].push(subs);
        }
      }
    });

    let percentage_inactive = 0;
    let percentage_expired_contract = 0;
    if (total_subscriptions > 0) {
      percentage_inactive = (inactive_count / total_subscriptions) * 100;
      percentage_expired_contract =
        (expired_contract / total_subscriptions) * 100;
    }

    ratio_for_inactiveuser_and_expiredsubs.percentage_inactive = parseFloat(
      percentage_inactive.toFixed(2)
    );
    ratio_for_inactiveuser_and_expiredsubs.percentage_expired_contract =
      parseFloat(percentage_expired_contract.toFixed(2));

    const productlineArray = Object.entries(total_productlinecode_seats);
    productlineArray.sort((a, b) => b[1].length - a[1].length);

    const top50Productlinecode = {};
    productlineArray
      ?.slice(0, 50)
      ?.map((item) => (top50Productlinecode[item[0]] = item[1]));

    const accountnamedataArray = Object.entries(total_account_name_seats);
    accountnamedataArray.sort((a, b) => b[1].length - a[1].length);

    const top50Accountname = {};
    accountnamedataArray
      ?.slice(0, 50)
      ?.map((item) => (top50Accountname[item[0]] = item[1]));

    const teamnamedataArray = Object.entries(total_team_name_seats);
    teamnamedataArray.sort((a, b) => b[1].length - a[1].length);

    const top50teamname = {};
    teamnamedataArray
      ?.slice(0, 50)
      ?.map((item) => (top50teamname[item[0]] = item[1]));

    const totalSubscriptions = subscriptionData.length;
    const percentageInactive = (inactive_count / totalSubscriptions) * 100;
    const percentageExpiredContract =
      (expired_contract / totalSubscriptions) * 100;

    setPercentageInactive(percentageInactive.toFixed(2));
    setPercentageExpiredContract(percentageExpiredContract.toFixed(2));

    const veryHighData = subscriptionData?.filter((item) => {
      return item["retention_health_riskBand"] === "Very High";
    });

    const highData = subscriptionData?.filter((item) => {
      return item["retention_health_riskBand"] === "High";
    });
    const mediumData = subscriptionData?.filter((item) => {
      return item["retention_health_riskBand"] === "Medium";
    });
    const lowData = subscriptionData?.filter((item) => {
      return item["retention_health_riskBand"] === "Low";
    });
    const veryLowData = subscriptionData?.filter((item) => {
      return item["retention_health_riskBand"] === "Very Low";
    });
    const undefinedData = subscriptionData?.filter((item) => {
      return item["retention_health_riskBand"] === null;
    });
    const onboardall = {};
    onboardall["Very High"] = veryHighData || [];
    onboardall["High"] = highData || [];
    onboardall["Medium"] = mediumData || [];
    onboardall["Low"] = lowData || [];
    onboardall["Very Low"] = veryLowData || [];
    onboardall["Undefined"] = undefinedData || [];

    SetOnBoardHealthAllData(onboardall);
  }, [subscriptionDataForProductlineChart]);

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token) {
      GetAllBranch();
    }
  }, []);

  const GetAllBranch = () => {
    setStatus("All Status");
    axios
      .get(BACKEND_BASE_URL + `v1/api/get_all_branch/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          const data = response?.data?.Branch.map((item) => {
            const name = item.branch_name;
            const value = item.id;
            return {
              label: name,
              value: value,
            };
          });
          setBranches(data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  useEffect(() => {
    if (token) {
      ExportSubscriptions();
    }
  }, [csn, token]);

  const ExportSubscriptions = () => {
    setIsLoading(true)
    SetisChartLoading(true);
    setStatus("All Status");
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_export_subscriptions/${csn ? `${csn}` : ""}`;
    // const apiUrl = `${BACKEND_BASE_URL}v1/api/get_export_subscriptions/${csn ? `${csn}` : ""}${year ? `/${year}` : ""}`;

    axios
      .get(apiUrl, { headers })
      .then((response) => {
        SetisChartLoading(false);
        if (response.status === 200) {
          SetSubscriptionData(response?.data.subscriptions);
          SetSubscriptionAllData(response?.data.subscriptions);
          setSearchedData(response?.data.subscriptions);
          SetLoading(false);
          setIsLoading(false)
          SetSubscriptionTableData(response?.data.subscriptions);
          setBranchFilteredData(response?.data?.subscriptions);
          setDoubleFilter(null);
          setLastUpdated(response?.data?.last_updated)
          SetSpeedoMeterRatio(
            response?.data.percentage_ratio_of_inactiveuser_and_expiredsubs
          );

          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying subscription.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        setError(
          error.response.data?.Response
            ? error.response.data.Response.error?.message
            : error.response.data.error
        );
        SetLoading(false);
        toast.error(
          error.response.data?.Response
            ? error.response.data?.Response.error.message
            : error.response.data?.error,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      });
  };

  const getAccountsData = () => {
    axios
      .get(BACKEND_BASE_URL + `v1/api/get_export_accounts/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.accounts.map((item) => {
            const name = item.name;
            const csn = item.csn;
            return {
              label: name,
              csn: csn,
            };
          });
          const uniqueLabels = new Set();

          const uniqueData = data.filter((item) => {
            if (!uniqueLabels.has(item.label)) {
              uniqueLabels.add(item.label);
              return true;
            }
            return false;
          });
          setAccountsName(uniqueData);
        }
      });
  };
  useEffect(() => {
    if (token) {
      getAccountsData();
    }
  }, [token]);

  useEffect(() => {
    handleLinkClick(top50ProductLinecode, "By Product line");
    // eslint-disable-next-line
  }, [top50ProductLinecode]);

  const handleLinkClick = (chartdata, name) => {
    const values = Object.values(chartdata);

    const mainchartvalue = values?.map((item) => {
      const totalSeats = item.reduce((sum, subItem) => sum + subItem.seats, 0);
      return totalSeats;
    });
    const mainchartlabel = Object.keys(chartdata);
    SetSeatsChart({
      ...seatchartsdata,
      value: mainchartvalue,
      label: mainchartlabel,
      chartname: name,
    });
    setLineChartName(name);
  };

  useEffect(() => {
    if (onBoardHealthAllData) {
      const onboardchartvalue = Object.values(onBoardHealthAllData).map(
        (item) => item.length
      );
      const onboardchartlabel = Object.keys(onBoardHealthAllData);
      SetOnBoardSeatsChart({
        ...onboardchartsdata,
        value: onboardchartvalue,
        label: onboardchartlabel,
      });
    }
  }, [onBoardHealthAllData]);

  const getRowId = (row) => row.id;

  const columns = [
    {
      field: "subscriptionReferenceNumber",
      headerName: "subscription",
      width: 150,
      renderCell: (params, index) => (
        <div>
          <button
            onClick={() => handleOpenModel(params?.row.id)}
            className="action-button bg-white text-black px-3 py-1 rounded"
          >
            {params.value}
          </button>
        </div>
      ),
    },
    {
      field: "account_name",
      headerName: "Account Name",
      width: 200,
      renderCell: (params) => {
        const { value: account } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {account?.length > maxChars ? account : account?.slice(0, maxChars)}
          </div>
        );
      },
    },
    // { field: "bd_person", headerName: "BD Person Name", width: 200 },
    // { field: "renewal_person", headerName: "Renewal Person Name", width: 200 },
    ...(user.user_type !== "Client"
      ? [
    {
      field: "bd_person",
      headerName: "BD Person Name",
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },
    {
      field: "renewal_person",
      headerName: "Renewal Person Name",
      width: 200,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },]
    : []),
    
    { field: "account_csn", headerName: "Account CSN", width: 100 },
    
    { field: "retention_health_riskBand", headerName: "Retention health riskBand", width: 100 },
    ...(user.user_type !== "Client"
      ? [
    {
      field: "branch",
      headerName: "Branch",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.value && params.value ? (
            params.value
          ) : (
            <span style={{ color: "red" }}>Undefined</span>
          )}
        </div>
      ),
    },] : []),
    {
      field: "contract_manager_email",
      headerName: "Contract Mgr. Email",
      width: 200,
      renderCell: (params) => {
        const { value: email } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {email?.length > maxChars ? email : email?.slice(0, maxChars)}
          </div>
        );
      },
    },
    { field: "seats", headerName: "Seats", width: 70 },
    { field: "startDate", headerName: "Subs Start Date", width: 130 },
    { field: "endDate", headerName: "Subs End Date ", width: 130 },
    { field: "trisita_status", headerName: "Trisita Status", width: 130 },
    { field: "subscriptionStatus", headerName: "Status", width: 100 },
    { field: "lastPurchaseDate", headerName: "Last Purchase date", width: 130 },

    { field: "groupName", headerName: "Account Group", width: 100 },
    { field: "subscriptionType", headerName: "Subscription Type", width: 100 },
    { field: "contract_end_date", headerName: "Contract EndDate", width: 130 },
    {
      field: "productLine",
      headerName: "Product Line",
      width: 250,
      renderCell: (params) => {
        const { value: productLine } = params;
        const maxChars = 20;

        return (
          <div style={{ whiteSpace: "normal", maxWidth: "200px" }}>
            {productLine?.length > maxChars
              ? productLine
              : productLine?.slice(0, maxChars)}
          </div>
        );
      },
    },
    ...(userType && userType === "Superadmin"
      ? [{
        field: "",
        headerName: "Set Trigger",
        width: 150,
        renderCell: (params, index) => (
          <div className="flex items-center w-full justify-center">
            <button
              onClick={() => handleTriggerModel(params?.row.id)}
              className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded"
            >
              Assign Trigger
            </button>
          </div>
        ),
      }] :
      []),
  ];

  const HandleChartSeatData = (label, status, chartname) => {
    console.log("🚀 ~ HandleChartSeatData ~ label:", label)
    if (label) {
      if (chartname === "By Product line") {
        const barchartdata = top50ProductLinecode[label];
        SetSubscriptionTableData(barchartdata);
      } else if (chartname === "By Account names") {
        const barchartdata = top50AcoountName[label];
        SetSubscriptionTableData(barchartdata);
      } else if (chartname === "By Team names") {
        const barchartdata = top50TeamName[label];
        SetSubscriptionTableData(barchartdata);
      } else {
        const barchartdata = totalLastPurchaseYearSeats[label];
        SetSubscriptionTableData(barchartdata);
      }
    }
  };

  const series = [
    {
      name: "seats",
      data: seatchartsdata?.value,
    },
  ];

  const options = {
    chart: {
      events: {
        click(event, chartContext, config) {
          const x =
            event.clientX - chartContext.el.getBoundingClientRect().left;

          const dataPointIndex = Math.floor(
            (x / chartContext.el.getBoundingClientRect().width) *
              seatchartsdata?.label.length
          );

          if (
            dataPointIndex >= 0 &&
            dataPointIndex < seatchartsdata?.label.length
          ) {
            const seriesName = "sales";
            const label = seatchartsdata?.label[dataPointIndex - 1];
            const categories = config?.config.xaxis.categories;
            const dataPoint = config?.dataPointIndex;
            const category = categories[dataPoint];
            const chartname = seatchartsdata?.chartname;
            HandleChartSeatData(category, "active", chartname);
          } else {
            console.log("No data point selected.");
          }
        },
      },
      type: "bar",
      height: 350,
      width: "100%",
    },

    xaxis: {
      categories: seatchartsdata?.label,
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    dataLabels: {
      position: "top",
    },
  };

  const seriesData = onBoardHealthAllData
    ? Object.values(onBoardHealthAllData).map((item) => item.length)
    : [];
  const labels = onBoardHealthAllData ? Object.keys(onBoardHealthAllData) : [];

  const ChartData = {
    series: seriesData,
    options: {
      chart: {
        width: 350,
        type: "pie",
      },
      labels: labels,
      legend: {
        position: 'top',
        horizontalAlign: 'center', 
      },
    },
  };

  const handleOnboardChart = (category) => {
    console.log(selectedOnBoard,"🚀 ~ handleOnboardChart ~ status:",category)
    if (selectedDataPoints === category){
      console.log("__________________________-null");
      SetSelectedOnBoard(null);
    }
    else{
      if (category) {
        console.log("__________________________ not null");
        SetSelectedOnBoard(category);
      }
    }
  };

  const onboardhealthSeries = [
    {
      name: "Subscription",
      data: onboardchartsdata?.value || [],
    },
  ];

  const onboardhealthOptions = {
    chart: {
      events: {
        click(event, chartContext, config) {
          const selectedIndex = config.dataPointIndex;
          console.log("_____🚀🚀🚀🚀🚀🚀🚀🚀🚀________________________________--figh",chartContext);
          
          console.log(event,"🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ click ~ config:", config)
          const x =
            event.clientX - chartContext.el.getBoundingClientRect().left;

          const dataPointIndex = Math.floor(
            (x / chartContext.el.getBoundingClientRect().width) *
              onboardchartsdata?.label.length
          );

          if (
            dataPointIndex >= 0 &&
            dataPointIndex < onboardchartsdata?.label.length
          ) {
            const seriesName = "sales"; 
            const label = onboardchartsdata?.label[dataPointIndex - 1];
            const categories = config.config.xaxis.categories;
            const dataPoint = config.dataPointIndex;
            const category = categories[dataPoint];
            console.log("🚀 ~ click ~ category:", category)
            const chartname = onboardchartsdata?.chartname;
            setSelectedDataPoints(category)
            handleOnboardChart(category);
          } else {
            console.log("No data point selected.");
          }
        },
      },
      type: "bar",
      height: 350,
      width: "100%",
    },

    xaxis: {
      categories: onboardchartsdata?.label || [],
    },
    yaxis: {
      title: {
        text: "",
      },
    },
    dataLabels: {
      position: "top",
    },
  };

  // Initialize the clicked states only when onboardchartsdata is available
  useEffect(() => {
    if (onboardchartsdata?.label) {
      setClickedStates(new Array(onboardchartsdata?.label.length).fill(false));
    }
  }, [onboardchartsdata?.label]);

  const InactiveUserSpeedMeterData = {
    series: [speedometerratio?.percentage_inactive],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        offsetY: -10,
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            show: true,
            name: {
              show: false,
              fontSize: "16px",
              color: undefined,
              offsetY: 120,
            },
            value: {
              offsetY: 76,
              fontSize: "22px",
              color: undefined,
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91],
        },
      },
      stroke: {
        dashArray: 4,
      },
    },
  };

  const ExpiredContractSpeedMeterData = {
    series: [speedometerratio?.percentage_expired_contract],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
        offsetY: -10,
      },
      plotOptions: {
        radialBar: {
          startAngle: -135,
          endAngle: 135,
          dataLabels: {
            show: true,
            name: {
              show: false,
              fontSize: "16px",
              color: undefined,
              offsetY: 120,
            },
            value: {
              offsetY: 76,
              fontSize: "22px",
              color: undefined,
              formatter: function (val) {
                return val + "%";
              },
            },
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "dark",
          shadeIntensity: 0.15,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 65, 91],
        },
      },
      stroke: {
        dashArray: 4,
      },
    },
  };
  const [status, setStatus] = React.useState("All Status");

  const handleChange = (event) => {
    SetSelectedOnBoard(null);
    const selectedValue = event.target.value;
    setStatus(selectedValue);

    if (selectedValue) {
      if (selectedValue === "All Status") {
        SetSubscriptionData(searchedData);
        SetSubscriptionTableData(searchedData);
      } else {
        const filteredResults = branchfilteredData?.filter((item) => {
          const trisita_status = item["trisita_status"];
          return trisita_status === selectedValue;
        });

        SetSubscriptionData(filteredResults);
        SetSubscriptionTableData(filteredResults);
        SetisChartLoading(false);
        SetLoading(false);
      }
    } else {
      ExportSubscriptions();
    }
  };

  const handleSearch = (values) => {
    SetisChartLoading(true);
    setSearchTerm(values);
    
    if (values.length > 0) {
      const filteredResults = subscriptionAllData?.filter((item) => {
        const name = item["account_name"];
        return values.some(value => name && name.toLowerCase().includes(value.label.toLowerCase()));
      });
  
      setSearchedData(filteredResults);
      SetSubscriptionData(filteredResults);
      SetSubscriptionTableData(filteredResults);
      setBranchFilteredData(filteredResults);
      setDoubleFilter(filteredResults);
      SetisChartLoading(false);
    } else {
      setStatus("All Status");
      ExportSubscriptions();
      setDoubleFilter(null);
    }
  };

  const handleBranchFilter = (branch) => {
    if (branch?.label) {
      const filteredResults = searchedData?.filter((item) => {
        const name = item["branch"];
        return name === branch.label;
      });
      SetSubscriptionData(filteredResults);
      SetSubscriptionTableData(filteredResults);
      setBranchFilteredData(filteredResults);
      setStatus("All Status");
    } else {
      setStatus("All Status");
      handleSearch(searchTerm);
    }
  };

  const formatLastUpdated = (timestamp) => {
    if (!timestamp) return 'Not Available';
    const date = new Date(timestamp);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const HandleAllocate = (subs_id) => {
    console.log("🚀 ~ HandleAllocate ~ subs_id:", subs_id)
    console.log("___________selectedSubsTrigger.map(user => user.id)",selectedSubsTrigger.map(user => user.id));
    
    const requestBody = {
      "subscription": subs_id || null,
      "trigger_choices": selectedSubsTrigger.map(user => user.id),
    };

    axios.put(BACKEND_BASE_URL + `v1/api/subs_trigger/${subs_id}`, requestBody, { headers })
      .then((response) => {
        if (response.status === 200) {
          toast.success("Account Allocated", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occurred while allocating accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => {
        toast.error("Error occurred while allocating accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    setOpenAssign(false);
    ExportSubscriptions();
  };

  const handleTriggerModel = (subs_id) => {
    setOpenAssign(true);
    setTriggerSubsID(subs_id)
    axios
      .get(BACKEND_BASE_URL + `v1/api/subs_trigger/${subs_id}`, { headers })
      .then((response) => {
        console.log("🚀 ~ .then ~ response:", response)
        if (response.status === 200) {
          SetselectedsubsTrigger(response?.data?.trigger_choices || []);
          SetLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  useEffect(() => {
    if (token){
    GetMailChoices();
    // eslint-disable-next-line
    }
  }, [token]);

  const GetMailChoices = () => {
    setStatus("All Status");
    axios.get(BACKEND_BASE_URL + `v1/api/get_all_mail_trigger/`, { headers })
      .then((response) => {
        if (response.status === 200) {
          SetAllChoices(response?.data?.choices);
          const data = response?.data?.choices.map(item => {
            const name = item.choice;
            const value = item.id;
            return {
              label: name,
              value: value,
            };
          });
          const uniqueLabels = new Set();
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error("Error occured while displaying  sdasDSDASF accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(() => {
        setError(error?.response.data.Response.message);
        
        toast.error("Error occured while displaying  DSFDSFDS F accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        
      });
  };

  const formattedDate = formatLastUpdated(lastUpdated);

  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  const processedData = subscriptionTableData?.map((item) => ({
    ...item,
    // Join bd_person_first_names and renewal_person_first_names arrays into comma-separated strings
    bd_person: item.bd_person_first_names ? item.bd_person_first_names.join(', ') : '',
    renewal_person: item.renewal_person_first_names ? item.renewal_person_first_names.join(', ') : '',
  }));
  console.log("🚀 ~ processedData ~ processedData:", processedData)


//   useEffect(() => {
//     fetchData().then((response) => {
//         const processedData = response.map((item) => ({
//             ...item,
//             bd_person_first_names: item.bd_person_first_names ? item.bd_person_first_names.join(', ') : '',
//             renewal_person_first_names: item.renewal_person_first_names ? item.renewal_person_first_names.join(', ') : '',
//         }));
//         // Set the preprocessed data into state
//         // setData(processedData);
//     });
// }, [subscriptionTableData]);
  
  return (
    <>
      <Box m="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <Header title="Subscriptions" />
          </Box>
          <div className="relative group">
            <span className="text-gray-600">Last Updated</span>
              <div className="absolute left-1/2 transform -translate-x-1/2 mt-2 w-max px-4 py-2 bg-black text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                {formattedDate}
              </div>
          </div>
          <Box sx={{display:'flex'}}>
            <button
              onClick={() => ExportSubscriptions()}
              className="action-button bg-[#8dbe86] text-black px-3 mr-4 py-1 rounded h-[52px] w-[100px] text-center, text-lg"
            >
              All
            </button>
            <Box
              display="flex"
              marginBottom={2}
              justifyContent="end"
              alignItems="center"
            >
              {branches ? (
                <Autocomplete
                  value={searchBranch}
                  onChange={(event, newValue) => {
                    setSearchBranch(newValue);
                    handleBranchFilter(newValue);
                  }}
                  options={branches}
                  autoSelect
                  getOptionLabel={(option) => option.label}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a Branch"
                      variant="outlined"
                    />
                  )}
                />
              ) : (
                // <CircularProgress />
                <Autocomplete
                  value={null}
                  disabled 
                  autoSelect
                  getOptionLabel={(option) => option?.label}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select a Branch"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {params.InputProps.endAdornment}
                            <Typography variant="body2" color="textSecondary">
                              Loading...
                            </Typography>
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            </Box>
            <Box sx={{ display: { md: "flex" } }}>
              <Box
                sx={{
                  ml: { md: 2 },
                  height: "50px",
                  width: 100,
                  minWidth: "200px",
                }}
              >
                <Select
                  value={status}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={handleChange}
                  sx={{ width: "100%" }}
                  SelectDisplayProps={{
                    style: { paddingTop: 8, paddingBottom: 8 },
                  }}
                  disabled={isChartLoading}
                  className="min-h-[52.7px]"
                >
                  <MenuItem value={"All Status"}>All Status</MenuItem>
                  <MenuItem value={"Active"}>Active</MenuItem>
                  <MenuItem value={"Expired"}>Expired</MenuItem>
                </Select>
              </Box>
              <Box sx={{ ml: { md: 2 }, minWidth: "200px" }}>
                {accountsname ? (
                  <Autocomplete
                  value={selectedValue} // Assuming selectedValues is an array for multi-select
                  onChange={(event, newValues) => {
                    setSelectedValue(newValues);
                    handleSearch(newValues); // Assuming handleSearch handles multi-select values
                  }}
                  options={accountsname}
                  autoSelect
                  multiple // Add multiple prop for multi-select
                  getOptionLabel={(option) => `${option.label} (${option.csn})`}
                  // getOptionLabel={(option) => option.label}
                  sx={{ width: 300 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select an Account"
                      variant="outlined"
                    />
                  )}
                />
                ) : (
                  // <CircularProgress />
                  <Autocomplete
                    value={null}
                    disabled
                    autoSelect
                    getOptionLabel={(option) => option.label}
                    sx={{ width: 300 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select an Account"
                        variant="outlined"
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {params.InputProps.endAdornment}
                              <Typography variant="body2" color="textSecondary">
                                Loading...
                              </Typography>
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Grid container sx={{ marginBottom: 3 }} spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                border: "0.8px solid",
                marginTop: 3,
                borderColor: colors.grey[900],
              }}
            >
              <Box
                sx={{
                  backgroundColor: colors.primary[400],
                  borderBottom: "0.8px solid",
                  borderColor: colors.grey[900],
                  padding: 2,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600" }}
                  component="h5"
                >
                  Trend of number of seats purchased by product line code
                </Typography>
                <Box sx={{ marginTop: 1 }}>
                  <Button
                    sx={{ fontSize: "10px", color: "#1976d2" }}
                    variant="text"
                    onClick={() =>
                      handleLinkClick(top50ProductLinecode, "By Product line")
                    }
                  >
                    By Product line
                  </Button>
                  <Button
                    sx={{ fontSize: "10px", color: "#1976d2" }}
                    variant="text"
                    onClick={() =>
                      handleLinkClick(top50AcoountName, "By Account names")
                    }
                  >
                    By Account names
                  </Button>
                  <Button
                    sx={{ fontSize: "10px", color: "#1976d2" }}
                    variant="text"
                    onClick={() =>
                      handleLinkClick(top50TeamName, "By Team names")
                    }
                  >
                    By Team names
                  </Button>
                  <Button
                    sx={{ fontSize: "10px", color: "#1976d2" }}
                    variant="text"
                    onClick={() =>
                      handleLinkClick(
                        totalLastPurchaseYearSeats,
                        "By last years"
                      )
                    }
                  >
                    By Last Purchase Year
                  </Button>
                </Box>
              </Box>

              {isChartLoading ? (
                <Loader />
              ) : (
                <>
                  {seatchartsdata &&
                    seatchartsdata.label &&
                    seatchartsdata.value && (
                      <ReactApexChart
                        options={options}
                        series={series}
                        type="bar"
                        height={350}
                        width="100%"
                      />
                    )}
                </>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box
              sx={{
                border: "0.8px solid",
                height: "100%",
                borderColor: colors.grey[900],
              }}
            >
              <Box
                sx={{
                  backgroundColor: colors.primary[400],
                  borderBottom: "0.8px solid",
                  borderColor: colors.grey[900],
                  padding: 2,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600" }}
                  component="h5"
                >
                  Total inactive users who don't have any active contract by
                  today
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  alignItems: "center",
                  justifyContent: "center",
                }}
                id="chart"
              >
                {speedometerratio ? (
                  <ReactApexChart
                    options={InactiveUserSpeedMeterData.options}
                    series={InactiveUserSpeedMeterData.series}
                    type="radialBar"
                    height={350}
                  />
                ) : (
                  <p>Loading data...</p>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                border: "0.8px solid",
                height: "100%",
                borderColor: colors.grey[900],
              }}
              className="ooooooooooooooooooooooooooooooo"
            >
              <Box
                sx={{
                  backgroundColor: colors.primary[400],
                  borderBottom: "0.8px solid",
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                  borderColor: colors.grey[900],
                  padding: 2,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600" }}
                  component="h5"
                >
                  Team share of active licenses
                </Typography>
                <Box sx={{ display: "flex" }}>
                  <Button
                    sx={{ fontSize: "9px", color: "#1976d2" }}
                    variant="text"
                  >
                    By product line
                  </Button>
                  <Divider orientation="vertical" flexItem />
                  <Button
                    sx={{ fontSize: "9px", color: "#1976d2" }}
                    variant="text"
                  >
                    By Account names
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  p:2,
                  alignItems: "center",
                  justifyContent: "center",
                }}
                id="chart"
              >
                <ReactApexChart
                  options={ChartData.options}
                  series={ChartData.series}
                  type="pie"
                  width={400}
                />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                border: "0.8px solid",
                height: "100%",
                borderColor: colors.grey[900],
              }}
            >
              <Box
                sx={{
                  backgroundColor: colors.primary[400],
                  borderBottom: "0.8px solid",
                  borderColor: colors.grey[900],
                  padding: 2,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600" }}
                  component="h5"
                >
                  Expired subscriptions ratio of nurtrued and customer
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",

                  alignItems: "center",
                  justifyContent: "center",
                }}
                id="chart"
              >
                {loading ? (
                  <p>Loading data...</p>
                ) : error ? (
                  <p>Error: {error}</p>
                ) : (
                  <ReactApexChart
                    options={ExpiredContractSpeedMeterData?.options}
                    series={ExpiredContractSpeedMeterData?.series}
                    type="radialBar"
                    height={350}
                  />
                )}

                {/* {speedometerratio ? (
                <ReactApexChart
                options={ExpiredContractSpeedMeterData?.options}
                series={ExpiredContractSpeedMeterData?.series}
                type="radialBar" height={350}
              />
              ) : (
                <p>Loading data...</p>
              )} */}
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} md={5}>
            <Box
              sx={{
                border: "0.8px solid",
                height: "100%",
                borderColor: colors.grey[900],
              }}
            >
              <Box
                sx={{
                  backgroundColor: colors.primary[400],
                  borderBottom: "0.8px solid",
                  borderColor: colors.grey[900],
                  padding: 2,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600" }}
                  component="h5"
                >
                  Retention Risk shows summary of the renewal risk for the
                  subscription contract
                </Typography>
              </Box>
              <Box
                id="chart"
                sx={{
                  display: "flex",
                  height: "88%",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {loading ? (
                  <p>Loading data...</p>
                ) : error ? (
                  <p>Error: {error}fgfd</p>
                ) : (
                  <>
                    {onboardchartsdata &&
                      onboardchartsdata.label &&
                      onboardchartsdata.value && (
                        <ReactApexChart
                          options={ChartData.options}
                          series={ChartData.series}
                          type="pie"
                          width={400}
                        />
                      )}
                  </>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={7}>
            <Box
              sx={{
                height: "100%",
                border: "0.8px solid",
                borderColor: colors.grey[900],
              }}
            >
              <Box
                sx={{
                  backgroundColor: colors.primary[400],
                  borderBottom: "0.8px solid",
                  borderColor: colors.grey[900],
                  padding: 2,
                }}
              >
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "600" }}
                  component="h5"
                >
                  On boarding Health adoption report
                </Typography>
              </Box>

              <Box id="chart">
                {loading ? (
                  <p>Loading data...</p>
                ) : error ? (
                  <p>Error: {error}</p>
                ) : (
                  <>
                    {onboardchartsdata &&
                      onboardchartsdata.label &&
                      onboardchartsdata.value && (
                        <ReactApexChart
                          options={onboardhealthOptions}
                          series={onboardhealthSeries}
                          type="bar"
                          height={350}
                        />
                      )}
                  </>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>

        <Box
          m="8px 0 0 0"
          width="100%"
          height="80vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          {loading ? (
            <p>Loading data...</p>
          ) : error ? (
            <p>Error: {error}</p>
          ) : (
            <DataGrid
              rows={processedData}
              columns={columns}
              getRowId={getRowId}
              components={{
                Toolbar: () => (
                  <div>
                    <GridToolbar
                    csvOptions={{
                      fileName: `subs_trisita_${user.first_name} ${user.last_name}_${currDate}_${currTime}`,
                      fields: ['subscriptionReferenceNumber', 'account_name', 'account_csn', 'bd_person', 'renewal_person',
                         'bd_person_first_names', 'renewal_person_first_names', 
                         'branch', 'contract_manager_email', 'seats', 'endDate', 'startDate', 'trisita_status', 'subscriptionStatus', 'lastPurchaseDate', 'groupName', 'subscriptionType', 'contract_end_date', 'productLine'],
                    }} />
                    <ExportToExcel
                      data={processedData}
                      columns={columns}
                      fileName={`subs_trisita_${user.first_name} ${user.last_name}_${currDate}_${currTime}`}
                    />
                  </div>
                )
              }}
              checkboxSelection
              disableRowSelectionOnClick
            />
          )}
        </Box>
      </Box>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        classes={{ backgroundColor: "red" }}
      >
        {/* <div className="absolute -translate-x-2/4 bg-white -translate-y-2/4 w-[500] rounded-lg shadow-[24] p-4 left-2/4 top-[18%]"> */}
        <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[500px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
          <div className="h-full">
            <div className="text-black flex border-b border-gray-300">
              <Link
                to={"#"}
                className={`${
                  tab === 1
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(1)}
              >
                End Customer
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 2
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(2)}
              >
                Reseller
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 3
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(3)}
              >
                Distributor
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 4
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(4)}
              >
                Contract
              </Link>
              <Link
                to={"#"}
                className={`${
                  tab === 5
                    ? "border border-b-0 text-black bg-white mb-[-2px]"
                    : "border-b text-blue-500"
                } p-2 border-gray-300 flex-grow text-center`}
                onClick={() => handleTabChange(5)}
              >
                Line Items
              </Link>
            </div>
            <div className="mt-5"></div>
            <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">
              {tab === 1 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    End Customer Details
                  </h1>
                  {selectedSubscription ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]">Subscription#</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.subscriptionReferenceNumber}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">CSN</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_account
                                .endCustomerCsn
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.endCustomer_account.name}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Address Line 1</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.endCustomer_account.address1}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Address Line 2</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.endCustomer_account.address2}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">City</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.endCustomer_account.city}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">State</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_account
                                .stateProvince
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Country</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.endCustomer_account.country}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Postal</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_account
                                .postalCode
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Contract Manager Details
                  </h1>
                  {selectedSubscription ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]">First Name</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_contractManager
                                .first
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Last Name</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_contractManager
                                .last
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Email</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_contractManager
                                .email
                            }
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Phone</div>
                          <div className="whitespace-pre">
                            {
                              selectedSubscription?.endCustomer_contractManager
                                .phone
                            }
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 2 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Reseller Details
                  </h1>
                  {selectedSubscription ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]"> Opportunity#</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.subscriptionReferenceNumber}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">CSN</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.accounts_reseller.csn}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.accounts_reseller.name}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 3 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Distributor Details
                  </h1>
                  {selectedSubscription ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]"> Subscription#</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.subscriptionReferenceNumber}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">CSN</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.accounts_soldTo.csn}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Name</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.accounts_soldTo.name}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 4 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    Contract Details
                  </h1>
                  {selectedSubscription ? (
                    <>
                      <div className="w-full">
                        <div className="flex my-4">
                          <div className="w-[200px]"> Opportunity#</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.subscriptionReferenceNumber}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Contract#</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.contract.contract_number}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Type</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.contract.subType}
                          </div>
                        </div>

                        <div className="flex my-4">
                          <div className="w-[200px]">Product Line Name</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.productLine}
                          </div>
                        </div>

                        <div className="flex my-4">
                          <div className="w-[200px]"> Term</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.contract.contract_term}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Duration</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.contract.contract}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Retention Health</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.ews_retentionHealth}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Sub start date</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.startDate}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Sub end date</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.endDate}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]">Quantity</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.quantity}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
              {tab === 5 && (
                <>
                  <h1 className="pb-3 border-b text-black font-extrabold text-xl border-black">
                    {" "}
                    Line Items 1 Details{" "}
                  </h1>
                  {selectedSubscription ? (
                    <>
                      <div className="w-1/2">
                        <div className="flex my-4">
                          <div className="w-[200px]"> Serial#</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.subscriptionReferenceNumber}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Quantity</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.quantity}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Seats</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.seats}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Deployment</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.deployment}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Status</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.subscriptionStatus}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Asset Status</div>
                          <div className="whitespace-pre">
                            {selectedSubscription?.productStatus}
                          </div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Support Program</div>
                          <div className="whitespace-pre">{""}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Program Eligibility</div>
                          <div className="whitespace-pre">{""}</div>
                        </div>
                        <div className="flex my-4">
                          <div className="w-[200px]"> Renew</div>
                          <div className="whitespace-pre">{""}</div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <p>Loading data...</p>
                  )}
                </>
              )}
            </div>
            <div className="border-t">
              <div className="pt-4 text-right">
                <button
                  className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                  onClick={() => {
                    setOpen(false);
                    SetselectedSubscription(null);
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
      open={openassign}
      onClose={() => setOpenAssign(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ backgroundColor: "red" }}
    >
      <div className="relative -translate-x-2/4 bg-white h-[350px] max-w-[600px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
        <div className="absolute top-2 right-3 cursor-pointer">
          <MdClose
            className="cursor-pointer text-xl"
            onClick={() => {
              setOpenAssign(false);
              setTriggerSubsID(null);
              SetselectedsubsTrigger([])
            }}
          />
        </div>
        <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
          Set Email Trigger
        </h3>
        <Box sx={{ paddingY: '30px' }}>
          <Grid container spacing={2}>
            <Grid item sx={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Trigger Choice :</Typography>
            </Grid>
            <Grid item sx={12} sm={9}>
                {selectedSubsTrigger ? (
                    <>
                    <Autocomplete
                        disablePortal
                        multiple  // Allow multiple selections
                        id="combo-box-demo"
                        options={allchoices}
                        value={selectedSubsTrigger}
                        getOptionLabel={(option) => option.choice}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                        <TextField {...params} label="Select Trigger og" variant="outlined" />
                        )}
                        onChange={(event, newValue) => {
                        SetselectedsubsTrigger(newValue);
                        }}
                    />
                    </>
                    ):(
                        <>
                        <Autocomplete
                        disablePortal
                        multiple  // Allow multiple selections
                        id="combo-box-demo"
                        options={allchoices}
                        getOptionLabel={(option) => option.choice}
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                        <TextField {...params} label="Select Trigger" variant="outlined" />
                        )}
                        onChange={(event, newValue) => {
                        SetselectedsubsTrigger(newValue);
                        }}
                    />
                        </>
                    )
                }
            </Grid>

            
          </Grid>
          <div className="max-w-[447px] w-full text-right mt-4">
            <Button
              className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
              onClick={() => HandleAllocate(triggerSubsID)}
            >
              Allocate
            </Button>
          </div>
        </Box>
      </div>
    </Modal>
      {
        isLoading && <CircleLoader />
      }
    </>
  );
};

export default Subscription;
