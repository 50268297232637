import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import { mockDataTeam } from "../../data/mockData.js";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme, MenuItem, FormControl, Select, InputLabel, Button } from "@mui/material";


const OrderLoadingDistributor = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()

  const { token, user } = useUser();
  const [orderType, setOrderType] = useState("");

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.value);
    navigate('/add_order_loading_po', { state: { orderType: event.target.value } });
  };

  useEffect(() => {
    if (token) {
      // Fetch data or perform actions here
    }
  }, [token]);

  const getRowId = (row) => row.id;

  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  const openLinkInNewTab = (url) => {
    window.open(url, "_blank");
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Order Loading Distributor" subtitle="we are in the same team" />
      </Box>
      <div className="max-w-[800px] mx-auto mt-8 pb-14 p-6 bg-[#f2f0f0] rounded shadow-md">
      <Box className="text-center">
        <Button
          variant="contained"
          color="primary"
          onClick={() => openLinkInNewTab('https://in.datechportal.com/en')}
        >
          Tech Data Advanced Private Limited
        </Button>
        </Box>
        <Box>
          <br></br>
        <Box className="text-center">
        <Button
          variant="contained"
          color="secondary"
          onClick={() => openLinkInNewTab('https://redingtonconnect.com/Autodesk/partner/Dashboard')}
        >
          Redington Limited
        </Button>
      </Box>
      </Box>
      </div>
    </Box>
  );
};

export default OrderLoadingDistributor;
