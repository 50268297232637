import React, { useState, useEffect, useContext, createContext } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState("");
  const [token, setToken] = useState("");

  useEffect(() => {
    const local_user = JSON.parse(localStorage.getItem('user')) || ""
    const local_token = localStorage.getItem('token') || ""
    setUser(local_user)
    setToken(local_token)
  }, [])
  
  const HandleUser = (user) => {
    setUser(user)
    localStorage.setItem('user', JSON.stringify(user))
  }

  const HandleToken = (token) => {
    setToken(token)
    localStorage.setItem('token', token)
  } 
  
  return (
    <UserContext.Provider value={{ user, token, HandleUser , HandleToken}}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};