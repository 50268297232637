import React, { useState, useEffect } from "react";
import { Box, Modal, Grid, Typography, TextField, MenuItem, Button, Tooltip } from '@mui/material';

import Header from "../../components/Header.jsx";
import {BACKEND_BASE_URL} from "../../components/config/apiEnv.js";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/AuthContext.js";
import { MdClose } from "react-icons/md";
import AddIcon from '@mui/icons-material/Add';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useCSN } from "../../components/CSNProvider.jsx";
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import PreviewIcon from '@mui/icons-material/Preview';
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import { CalculateOutlined } from "@mui/icons-material";


const AddOrderLoadingPO = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate()
    const { token, user } = useUser();
    const { csn } = useCSN();
    const location = useLocation();
    const { orderType } = location.state || {};
    console.log("🚀 ~ AddOrderLoadingPO ~ orderType:", orderType)
    
    
    
    
    
    const [isLoading, setIsLoading] = useState(false);
    const [allCategory, SetAllCategory] = useState(null);
    const [allUOM, SetAllUOM] = useState(null);
    const [allStatus, SetAllStatus] = useState(null);
    const [allOEM, SetAllOEM] = useState(null);
    const [allGSTType, SetAllGSTType] = useState(null);
    const [openassign, setOpenAssign] = useState(false);
    const [selectedCategory, SetSelectedCategory] = useState(null);
    const [selectedGst, SetSelectedGst] = useState(null);
    const [selectedGstValue, SetSelectedGstValue] = useState(null);
    
    
    const [productDetails, SetProductDetails] = useState([]);
    
    const [user_type, setUserType] = useState(null);
    const [selectedProductMaster, setSelectedProductMaster] = useState(null);
    const [openProductDetails, setOpenProductDetails] = useState(false);
    const [openPaymentDetails, setOpenPaymentDetails] = useState(false);
    
    const [allProjectDetails, SetAllProjectDetails] = useState(null);
    const [allProjectMaster, SetAllProjectMaster] = useState(null);
    const [allaccountdata, SetAllAccountData] = useState([]);
    const [allUser, SetAllUser] = useState(null);
    const [stateCode, SetStateCode] = useState(null);
    console.log("🚀 ~ AddOrderLoadingPO ~ stateCode:", stateCode)
    const [allBranch, SetAllBranch] = useState(null);    
    const [open, setOpen] = useState(false);
    const [paymentopen, setPaymentOpen] = useState(false);
    
    const [popupLoading, SetPopupLoading] = useState(true);
    
    const [allSoldTo, SetAllSoldTo] = useState(null);

    const [formData, setFormData] = useState({
      user_type:'',
      order_type:'',
      order_loading_date: '',
      branch: '',
      bd_person: [],
      account: '',
      po_number: '',
      po_date: '',
      po_copy: null,
      client_po_copy: null,
      third_party_account: '',
      billing_address: '',
      billing_gst_number: '',
      shipping_address: '',
      shipping_gst_number: '',
      product_details: [],
      license_contract_manager_name: '',
      license_contract_manager_phone: '',
      license_contract_manager_email_id: '',
      proposed_license_activation_date: null,
      proposed_order_loading_date:null,
      manual_cif_form: null,
      selling_payment_terms: null,
      purchase_payment_terms: null,
      license_activation_date: null,
      license_certificate: null,
      sales_invoice_number: '',
      sales_invoice_date: '',
      sales_invoice_amount_ex_gst: '',
      sales_gst_amount: '',
      tota_sales_invoice_amount_inc_gst: '',
      sales_invoice:null,

      purchase_from:'',
      purchase_invoice_number: '',
      purchase_invoice_date: '',
      purchase_invoice_amount_ex_gst: '',
      purchase_gst_amount: '',
      total_purchase_invoice_amount_inc_gst: '',
      purchase_invoice:null,

      balance_amount: '',
      payment_delayed_by_days: null,
      branch_interest_per_month: '',
      payment:[],
      remarks:''
    });

    console.log("________ =======================================____________-formData",formData.branch);
    

    const [productMaster, setProductMaster] = useState('');
    const [productMasterLabel, setProductMaster_label] = useState('');
    
    const [quantity, setQuantity] = useState('');
    const [sellingAmount, setSellingAmount] = useState('');
    const [sgstAmount, setSGSTAmount] = useState('');
    const [cgstAmount, setCGSTAmount] = useState('');
    const [igstAmount, setIGSTAmount] = useState('');
    const [purchaseAmount, setPurchaseAmount] = useState('');
    const [totalSellingAmount, setTotalSellingAmount] = useState(0);
    const [totalPurchaseAmount, setTotalPurchaseAmount] = useState(0);
    const [totalAcvAmount, setTotalAcvAmount] = useState(0);
    const [dependency, SetDependency] = useState(false);

    const [payments, setPayments] = useState([]);
    const [paymentNo, setPaymentNo] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');
    const [paymentReceivedAmount, setPaymentReceivedAmount] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [paymentRemarks, setPaymentRemarks] = useState('');

    const [showLicenceDate, setShowLicenceDate] = useState('');

    const [accountPopup, showAccountPopup] = useState(false);
    const [backPopup, showBackPopup] = useState(false);

    const [allThirdPartyAccount, SetAllThirdPartyAccount] = useState([]);
    const [GSTPercent, setGSTPercent] = useState(null);
    

    const transformApiResponse = (data) => {
        return data.map(account => ({
          "id": account.id,
          "csn": account?.csn ?? null,
          "email": account?.contract_manager[0]?.email ?? null,
          "phone": account?.contract_manager[0]?.phone ?? null,
          "name": account?.name ?? "",
          "industryGroup": account?.industryGroup ?? null,
          "industrySegment": account?.industrySegment ?? null,
          "industrySubSegment": account?.industrySubSegment ?? null,
          "address1": account?.address1 ?? null,
          "city": account?.city ?? null,
          "status": account?.status ?? null,
          "contract_status": account?.contract_status ?? null,
          "buyingReadinessScore": account?.buyingReadinessScore ?? null,
          "branch":account?.branch_name ?? null,
          "branch_object":account?.branch ?? null,
          "user_assign":account?.user_assign_firstname ?? null,
          "renewal_person":account?.renewal_person_firstname ?? null,
          // "renewal_person_object":account?.renewal_person ?? null,
          // "user_assign_object":account?.user_assign ?? null,
        }));
      };
    
    console.log("🚀 ~ AddOrderLoadingPO ~ formData:", formData)


    useEffect(() => {
      if (token){
      if (formData.branch){
        console.log("_________________working_____________________");
        if (formData.order_type) {
        handleBranch(formData.branch);
        setFormData(prevData => ({
          ...prevData,
          bd_person: [] // Clear bd_person when branch changes
        }));
        setFormData(prevData => ({
          ...prevData,
          account: '' // Clear bd_person when branch changes
        }));
        }
      else{
        if (user.user_type !== 'Primaryadmin'){
        toast.error("Please select order type first and then select branch", {
            position: toast.POSITION.TOP_RIGHT,
        });
      }
      }
        // handleBranch(formData.branch, { name: 'branch' });
      }
      if (formData.bd_person){
        handleBDPerson(formData.bd_person)
      }
      // eslint-disable-next-line
      }
    }, [token, formData.branch, formData.order_type]);

    useEffect(() => {
      if (token){
        console.log("__________________start if");
      if (formData.bd_person){
        console.log("__________________if");
        
        handleBDPerson(formData.bd_person)
      }
      // eslint-disable-next-line
      }
    }, [token, formData.bd_person]);

    

    const handleBranch = (electedOption) => {
      console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ handleBranch ~ electedOption:", electedOption)

      if (electedOption === null){
        console.log("________________________________________null proper");
        setFormData(prevData => ({
          ...prevData,
          bd_person: '', // Array of selected IDs
        }));
        setFormData(prevData => ({
          ...prevData,
          account: '', // Array of selected IDs
        }));
        setFormData(prevData => ({
          ...prevData,
          branch: '', // Array of selected IDs
        }));
        SetAllUser(null);
        SetAllAccountData([])
        
      }


      if (electedOption?.value){
      handleSelectChange(electedOption, { name: 'branch' })
      }
  
      axios.get(BACKEND_BASE_URL + `v1/api/get_bd_person_by_branch/${electedOption?.value ? `${electedOption?.value}` : `${electedOption}`}/${formData.order_type}`, { headers })
        .then((response) => {
        if (response.status === 200) {
            SetAllUser(response?.data.bd_person);
            SetStateCode(response?.data.state_code)
            setError(null);
            toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
            });
        }
        })
        .catch(() => {
        setError(error?.response.data.Response.message);
        
        setIsLoading(false)
        toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
        });
        
        });
        
    }
  
    const handleBDPerson = (electedOption) => {
      console.log("🚀🚀🚀🚀🚀🚀🚀🚀bd🚀🚀🚀 ~ handleBranch ~ electedOption:", electedOption)
      console.log("🚀🚀🚀🚀🚀🚀🚀🚀bd🚀🚀🚀 ~ handleBranch ~ electedOption: vlaue", electedOption.value)
  
      if (electedOption.value){
      handleMultipleSelectChange(electedOption, { name: 'bd_person' })
      }
      if (electedOption.length !== 0){
        axios.get(BACKEND_BASE_URL + `v1/api/get_accounts_by_bd_person/${electedOption?.value ? `${electedOption?.value}` : `${electedOption}`}/${formData.order_type}`, { headers })
        .then((response) => {
        if (response.status === 200) {
            const transformedData = transformApiResponse(response?.data.accounts);
            SetAllAccountData(transformedData);
            const thirdPartytransformedData = transformApiResponse(response?.data.third_party_acccounts);
            SetAllThirdPartyAccount(thirdPartytransformedData);
            setError(null);
            toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
            });
        }
        })
        .catch(() => {
        setError(error?.response.data.Response.message);
        
        setIsLoading(false)
        toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
        });
        
        });
      }
      else{
        setFormData(prevData => ({
          ...prevData,
          bd_person: '', // Array of selected IDs
        }));
        setFormData(prevData => ({
          ...prevData,
          account: '', // Array of selected IDs
        }));
        SetAllAccountData([])
      }
    }
    
    useEffect(() => {
        if (token){
        GetAllProjectMaster();
        GetAllBranch();
        GeteAllSoldTo()
        // eslint-disable-next-line
        }
    }, [token]);

  
    useEffect(() => {
      if (token){
      GetAllProjectDetails();
      }
    }, [token, dependency]);
    
    useEffect(() => {
        CalculateBalanced();
    }, [formData.total_purchase_invoice_amount_inc_gst, payments]);
    

    const GetAllProjectDetails = () => {
      axios.get(BACKEND_BASE_URL + `v1/product/product_details/`, { headers })
        .then((response) => {
          if (response.status === 200) {
            SetAllProjectDetails(response?.data?.product_details);
            setError(null);
            toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          setError(error?.response.data.Response.message);
          
          setIsLoading(false)
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          
        });
    };

    const GetAllProjectMaster = () => {
      axios.get(BACKEND_BASE_URL + `v1/product/product_master/`, { headers })
        .then((response) => {
          console.log("🚀 ~ .then ~ response:", response)
          if (response.status === 200) {
            SetAllProjectMaster(response?.data?.product_master);
            setError(null);
            toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch(() => {
          setError(error?.response.data.Response.message);
          
          setIsLoading(false)
          toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
          });
          
        });
    };

    const GetAllBranch = () => {
    // setStatus("All Status");
    axios.get(BACKEND_BASE_URL + `v1/api/get_all_branch/`, { headers })
        .then((response) => {
        if (response.status === 200) {
            if (user.user_type === "Primaryadmin"){
              SetAllBranch(response?.data?.Branch);
              setFormData(prevData => ({
                ...prevData,
                branch: response?.data?.Branch[0].id, // Array of selected IDs
              }));
            }
            else{
              SetAllBranch(response?.data?.Branch);
            }
            const data = response?.data?.Branch.map(item => {
            const name = item.branch_name;
            const value = item.id;
            return {
                label: name,
                value: value,
            };
            });
            const uniqueLabels = new Set();
            setError(null);
            toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error("Error occured while displaying  sdasDSDASF accounts.", {
            position: toast.POSITION.TOP_RIGHT,
            });
        }
        })
        .catch(() => {
        setError(error?.response.data.Response.message);
        
        toast.error("Error occured while displaying  DSFDSFDS F accounts.", {
            position: toast.POSITION.TOP_RIGHT,
        });
        
        });
    };

    const GeteAllSoldTo = () => {
      // setStatus("All Status");
      axios.get(BACKEND_BASE_URL + `v1/api/get_sold_to/`, { headers })
          .then((response) => {
          if (response.status === 200) {
              SetAllSoldTo(response?.data?.sold_to);
              const data = response?.data?.sold_to.map(item => {
              const name = item.name;
              const value = item.id;
              return {
                  label: name,
                  value: value,
              };
              });
              const uniqueLabels = new Set();
              setError(null);
              toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
              });
          } else {
              toast.error("Error occured while displaying  sdasDSDASF accounts.", {
              position: toast.POSITION.TOP_RIGHT,
              });
          }
          })
          .catch(() => {
          setError(error?.response.data.Response.message);
          
          toast.error("Error occured while displaying  DSFDSFDS F accounts.", {
              position: toast.POSITION.TOP_RIGHT,
          });
          
          });
      };


    useEffect(() => {
      if (token){
        // GetBDPerson()
      // ExportAccounts();
      // ThirdPartyAccounts();
      // eslint-disable-next-line
      }
    }, [token]);


    

    const ExportAccounts = () => {
    console.log("___________________done ");
    setIsLoading(true)
    // setStatus("All Status");
    const apiUrl = `${BACKEND_BASE_URL}v1/api/get_export_accounts/${csn ? `${csn}` : ''}`;
    axios.get(apiUrl, { headers })
        .then((response) => {
        if (response.status === 200) {
            const transformedData = transformApiResponse(response?.data.accounts);
            SetAllAccountData(transformedData);
            setIsLoading(false)
            setError(null);
            // setSearchBranch(null)
            // setLastUpdated(response?.data?.last_updated)
            toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
            });
        }
        })
        .catch(() => {
        setIsLoading(false)
        setError(error?.response.data.Response.message);
        toast.error("Error occured while displaying accounts.", {
            position: toast.POSITION.TOP_RIGHT,
        });
        });
    };

    // const ThirdPartyAccounts = () => {
    //   console.log("___________________done ");
    //   setIsLoading(true)
    //   // setStatus("All Status");
    //   const apiUrl = `${BACKEND_BASE_URL}v1/api/get_third_party_accounts/${csn ? `${csn}` : ''}`;
    //   axios.get(apiUrl, { headers })
    //       .then((response) => {
    //       if (response.status === 200) {
    //           const transformedData = transformApiResponse(response?.data.accounts);
    //           SetAllThirdPartyAccount(transformedData);
    //           setIsLoading(false)
    //           setError(null);
    //           // setSearchBranch(null)
    //           // setLastUpdated(response?.data?.last_updated)
    //           toast.success("Data Loaded", {
    //           position: toast.POSITION.TOP_RIGHT,
    //           });
    //       } else {
    //           toast.error("Error occured while displaying accounts.", {
    //           position: toast.POSITION.TOP_RIGHT,
    //           });
    //       }
    //       })
    //       .catch(() => {
    //       setIsLoading(false)
    //       setError(error?.response.data.Response.message);
    //       toast.error("Error occured while displaying accounts.", {
    //           position: toast.POSITION.TOP_RIGHT,
    //       });
    //       });
    // };
    

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({
          ...formData,
          [name]: files[0],
        });
    };
    

    const handleMultipleSelectChange = (selectedOptions, actionMeta) => {
      const { name } = actionMeta;
      
      setFormData(prevData => ({
        ...prevData,
        [name]: selectedOptions ? selectedOptions.map(option => option.value) : []
      }));
    };

    const handleProductDetailsChange = (selectedOptions) => {
      const selectedIds = selectedOptions.map(option => option.value);
      setFormData(prevData => ({
        ...prevData,
        product_details: selectedIds, // Array of selected IDs
      }));
    };

    
    const headers = {
       'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
    };

    useEffect(() => {
      setFormData(prevFormData => ({
          ...prevFormData,
          user_type: orderType,
      }));
  }, [orderType]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
      const { name } = actionMeta;
      if (name === 'account'){
        showAccountPopup(true);
      }
      setFormData(prevData => ({
          ...prevData,
          [name]: selectedOption?.value || ''
      }));
    };

    const handleOrderSelectChange = (e) => {
      const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });

    };


    const handleConfirmAccount = (confirm) => {
      if (confirm) {
        // setFormData({ ...formData, account: selectedAccount.value });
      } else {
        // setSelectedAccount(null);
        setFormData(prevData => ({
          ...prevData,
          ['account']: ''
      }));
      }
      showAccountPopup(false);
    };


    const handleBackButtonClick = () => {
      showBackPopup(true);  // Show the back confirmation popup
    };
  

    const handleConfirmBack = (confirm) => {
      if (confirm) {
        localStorage.removeItem('productDetails');
        localStorage.removeItem('payments');
        navigate('/order_loading_po');
      }
      showBackPopup(false);
    };

    const handleSelectLicenceChange = (e) => {
      const { name, value } = e.target;
      setShowLicenceDate(value)
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    const GetGSTPercent = () => {
      if (formData.billing_gst_number) {
        console.log("_____________-from",formData.billing_gst_number);
        const gstStateCode = formData.billing_gst_number.substring(0, 2);
        console.log(typeof(gstStateCode),"🚀++++++++++++++++++++++ ~ AddProductDetails ~ gstStateCode:", gstStateCode)
        console.log(typeof(stateCode),"🚀++++++++++++++++++++++ ~ AddProductDetails ~ stateCode:", stateCode)
        if (parseInt(gstStateCode) === parseInt(stateCode)) {
          console.log("++++++++++++++++++++++ if ");
          
          setGSTPercent(9)
        } else {
          console.log("++++++++++++++++++++++ else ");
          setGSTPercent(18)
        }
        
      }
    }
  
  
    useEffect(() => {
      GetGSTPercent()
    }, [formData.billing_gst_number, stateCode]);

    const AddProductDetails = () => {
      setOpenProductDetails(true);
      
      console.log("__________ Add category __________");
    };


    const AddPaymentDetails = () => {
      setOpenPaymentDetails(true);
    };


    const ShowProductDetails = () => {
      setOpen(true)
    };


    const ShowPaymentDetails = () => {
      setPaymentOpen(true)
    };

    
    const handleNavigation = () => {
      const newPath = 'add_product_master'; // Replace with your desired path
      window.open(newPath, '_blank'); // Open in a new tab
    };

    


    const handleSubmit = (e) => {
      e.preventDefault();
      setIsSubmitting(true);

      const formDataToSend = new FormData();
      
      Object.keys(formData).forEach((key) => {
        // Skip file fields for now, handle them separately
        if (!['po_copy', 'manual_cif_form', 'client_po_copy', 'license_certificate', 'sales_invoice', 'purchase_invoice'].includes(key)) {
            if (key === 'product_details') {
              console.log("______________if key ",localStorage.getItem('productDetails'));
              
                // Retrieve product_details from local storage
                const savedProductDetails = JSON.parse(localStorage.getItem('productDetails')) || [];
                console.log("🚀 ~ Object.keys ~ savedProductDetails:", savedProductDetails)

                // Check if product_details is an array
                if (Array.isArray(savedProductDetails) && savedProductDetails.length > 0) {
                    formDataToSend.append(key, JSON.stringify(savedProductDetails));
                } else {
                    console.error('product_details should be an array and not empty');
                }
            } else if (key === 'payment') {
                // Retrieve payments from local storage
                const savedPayments = JSON.parse(localStorage.getItem('payments')) || [];
                console.log("🚀 ~ Object.keys ~ savedPayments:", savedPayments)

                // Check if payments is an array
                if (Array.isArray(savedPayments) && savedPayments.length > 0) {
                    formDataToSend.append(key, JSON.stringify(savedPayments));
                } else {
                    console.error('payments should be an array and not empty');
                }
            }else if (key === 'bd_person') {
              // Handle bd_person as a list (array)
              if (Array.isArray(formData[key]) && formData[key].length > 0) {
                  formDataToSend.append(key, JSON.stringify(formData[key]));
              } else {
                  console.error('bd_person should be an array and not empty');
              }
          } else {
                    // Add non-file fields to FormData if they have a value
                    if (formData[key] !== null && formData[key] !== undefined && formData[key] !== '') {
                        formDataToSend.append(key, formData[key]);
                    }
            }
        }
        });

        // Handle file fields
        ['po_copy', 'manual_cif_form', 'client_po_copy', 'license_certificate', 'sales_invoice', 'purchase_invoice'].forEach(fileKey => {
            if (formData[fileKey] instanceof File) {
                formDataToSend.append(fileKey, formData[fileKey]);
            }
        });

      console.log("_________formDataToSend",formDataToSend);
  
      axios
      .post(BACKEND_BASE_URL + `v1/product/order_loading_po/`, formDataToSend, { headers })
      .then((response) => {
          console.log("🚀 ~ .then ~ response:", response)
          if (response.status === 201) {
              navigate("/order_loading_po_list")
          setError(null);
          toast.success("Product Master created Successfully", {
              position: toast.POSITION.TOP_RIGHT,
          });
          localStorage.removeItem('productDetails');
          localStorage.removeItem('payments');
          } else {
          toast.error("Error occured while displaying accountsfdfdsfds.", {
              position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false);
          }
      })
      .catch((error) => {
          toast.error(error.response.data?.error, {
          position: toast.POSITION.TOP_RIGHT,
          });
          setIsSubmitting(false);
      });
    

    };
    
    const [currentId, setCurrentId] = useState(1); // Start with ID 1
    const handleAddProductDetails = () => {
      const productDetail = {
          id: currentId,  
          product_master: productMaster,
          product_master_label:productMasterLabel,
          quantity: quantity,
          selling_amount: sellingAmount,
          total_selling_amount_exc_gst: totalSellingAmount,
          purchase_amount: purchaseAmount,
          total_purchase_amount_exc_gst: totalPurchaseAmount,
          total_acv_amount_exc_gst: totalAcvAmount,
          sgst_amount: sgstAmount,
          cgst_amount: cgstAmount,
          igst_amount: igstAmount

      };
  
      let existingContracts = JSON.parse(localStorage.getItem('productDetails')) || [];
      existingContracts.push(productDetail);
      localStorage.setItem('productDetails', JSON.stringify(existingContracts));
      SetProductDetails(existingContracts);
  
      // Increment the ID for the next entry
      setCurrentId(currentId + 1);
  
      setOpenProductDetails(false);
      resetForm();
  };

  const [paymentId, setPaymentId] = useState(1); // Start with ID 1
  const handleAddPaymentDetail = () => {
    const paymentDetail = {
        id: paymentId,  // Use the auto-incremented ID
        payment_no: paymentNo,
        payment_method: paymentMethod,
        payment_status: paymentStatus,
        payment_received_amount: paymentReceivedAmount,
        payment_date: paymentDate,
        remarks: paymentRemarks
    };

    let existingPayments = JSON.parse(localStorage.getItem('payments')) || [];
    existingPayments.push(paymentDetail);
    localStorage.setItem('payments', JSON.stringify(existingPayments));
    setPayments(existingPayments);

    // Increment the ID for the next entry
    setPaymentId(paymentId + 1);
    setOpenPaymentDetails(false);
    // Reset form fields
    resetPaymentForm()

    CalculateBalanced()

  };

  const CalculateBalanced = () => {
    const payments = JSON.parse(localStorage.getItem('payments')) || [];

    if (formData.total_purchase_invoice_amount_inc_gst){
      if (payments){
      const totalPaymentReceived = payments.reduce((sum, payment) => {
          const amount = parseFloat(payment.payment_received_amount) || 0;
          return sum + amount;
      }, 0);

      const remainingAmount = formData.total_purchase_invoice_amount_inc_gst - totalPaymentReceived;

      // Update formData with the remaining amount
        setFormData(prevFormData => ({
          ...prevFormData,
          balance_amount: remainingAmount
          }));
      }else{
        setFormData(prevFormData => ({
          ...prevFormData,
          balance_amount: formData.total_purchase_invoice_amount_inc_gst
          }));
      }
    }
    }



    

    const handleProjectMaster = (project_master_id, project_master_label ) => {
      setProductMaster(project_master_id);
      setProductMaster_label(project_master_label);
      setOpenAssign(true);
      axios.get(BACKEND_BASE_URL + `v1/product/product_master/${project_master_id}`, { headers })
        .then((response) => {
          if (response.status === 200) {
            setSelectedProductMaster(response.data.product_master || []);  // Set default value as array
  
            setError(null);
            toast.success("Data Loaded", {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error("Error occurred while fetching account data.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
        .catch((error) => {
          setError(error?.response?.data?.message);
          toast.error("Error occurred while fetching account data.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    };


    // const AddCategory = (value) => {
    //   SetModelValue(value)
    //   setOpenAssign(true);
    //   console.log("__________ Add category __________");
    // };

    // const AddGstType = (value) => {
    //   SetModelValue(value)
    //   setOpenGstAssign(true);
    //   console.log("__________ Add category __________");
    // };

  const branchOptions = allBranch?.map(branch => ({
      value: branch.id,
      label: branch.branch_name
  })) || [];

  console.log("🚀 ~ userOptions ~ allUser:", allUser)
  const userOptions = allUser?.map(user => ({
    value: user.id,
    label: user.email
  })) || [];

  const accountOptions = allaccountdata?.map(account => ({
      value: account.id,
      // label: account.name,
      label: `${account.name} (${account.csn})`,
  })) || [];

  const thirdPartyAccountOptions = allThirdPartyAccount?.map(account => ({
    value: account.id,
    // label: account.name,
    label: `${account.name} (${account.csn})`,
  })) || [];
  console.log("🚀 ~ thirdPartyAccountOptions ~ thirdPartyAccountOptions:", thirdPartyAccountOptions)

  const projectDetailoptions = allProjectDetails?.map(detail => ({
    value: (detail.id),
    label: detail.product_master,
  })) || [];

  const projectMasteroptions = allProjectMaster?.map(item => ({
    value: item.id,
    label: item.name,
  })) || [];

  const soldToOptions = allSoldTo?.map(sold_to => ({
    value: sold_to.id,
    label: `${sold_to.name} (${sold_to.csn})`
  })) || [];


  useEffect(() => {
    if (productMaster){
      calculateTotals();
    }
}, [sellingAmount, purchaseAmount, quantity]);

  const calculateTotals = () => {
    console.log("Selling Amount:", sellingAmount);
    console.log("Quantity:", quantity);

    if (productMaster) {
        const totalSellingAmount = parseFloat(quantity) * parseFloat(sellingAmount);
        console.log("🚀 ~ calculateTotals ~ totalSellingAmount:", totalSellingAmount);
        const totalPurchaseAmount = parseFloat(quantity) * parseFloat(purchaseAmount);
        console.log("🚀 ~ calculateTotals ~ totalPurchaseAmount:", totalPurchaseAmount);
        const totalAcvAmount = parseFloat(quantity) * (selectedProductMaster?.acv_price || 1);
        console.log("🚀 ~ calculateTotals ~ totalAcvAmount:", totalAcvAmount);
        setTotalSellingAmount(totalSellingAmount);
        setTotalPurchaseAmount(totalPurchaseAmount);
        setTotalAcvAmount(totalAcvAmount);
        if (GSTPercent === 18){
          const gst_value = parseFloat(sellingAmount) * 18 / 100
          setSGSTAmount(0);
          setCGSTAmount(0);
          setIGSTAmount(gst_value);
        } 
        else{
          const gst_value = parseFloat(sellingAmount) * 9 / 100
          setSGSTAmount(gst_value);
          setCGSTAmount(gst_value);
          setIGSTAmount(0);
        }
        
    }
    
};

  const resetForm = () => {
    setProductMaster('');
    setQuantity('');
    setSellingAmount('');
    setPurchaseAmount('');
    setTotalSellingAmount(0);
    setTotalPurchaseAmount(0);
    setTotalAcvAmount(0);
  };

  const resetPaymentForm = () => {
    console.log("_________-clear form");
    
    setPaymentNo('');
    setPaymentMethod('');
    setPaymentStatus('');
    setPaymentReceivedAmount('');
    setPaymentDate('');
    setPaymentRemarks('');
  }

  const product_master_Columns = [
    { field: "id", headerName: "ID" },
    { field: "product_master", headerName: "Product Master" },
    { field: "quantity", headerName: "Quantity" },
    { field: "selling_amount", headerName: "Selling Amount" },
    { field: "total_selling_amount_exc_gst", headerName: "Total Selling Amount" },
    { field: "purchase_amount", headerName: "Purchase Amount" },
    { field: "total_purchase_amount_exc_gst", headerName: "Total Purchase Amount" },
    { field: "total_acv_amount_exc_gst", headerName: "Total ACV Amount" },
    { field: "sgst_amount", headerName: "SGST Amount", flex: 1 },
    { field: "cgst_amount", headerName: "CGST Amount", flex: 1 },
    { field: "igst_amount", headerName: "IGST Amount", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
          <button
              className="text-red-600"
              onClick={() => handleDeleteRow(params.row.id)}
          >
              Delete
          </button>
      ),
    },
  ];

  const product_master_local_storage = [
    {
      field: 'srNo',
      headerName: 'SR No',
      width: 100,
      valueGetter: (params) => params.api.getRowIndex(params.id) + 1, // Generate SR No based on row index
    },
    { field: "product_master_label", headerName: "Product Master", width:300,
      renderCell: (params) => (
        <Tooltip title={params.value || ''}>
          <span className="whitespace-normal break-words overflow-hidden line-clamp-3">
          <div>
          {/* <button
            onClick={() => handleOpenModel(params?.row.id)}
            className="action-button bg-white text-black px-3 py-1 rounded"
          >
            {params.value}
          </button> */}
            {params.value}

        </div>
          </span>
        </Tooltip>
      ),
     },
    { field: "quantity", headerName: "Quantity" },
    { field: "selling_amount", headerName: "Selling Amount" },
    { field: "total_selling_amount_exc_gst", headerName: "Total Selling Amount" },
    { field: "purchase_amount", headerName: "Purchase Amount" },
    { field: "total_purchase_amount_exc_gst", headerName: "Total Purchase Amount" },
    { field: "total_acv_amount_exc_gst", headerName: "Total ACV Amount" },
    { field: "sgst_amount", headerName: "SGST Amount"},
    { field: "cgst_amount", headerName: "CGST Amount" },
    { field: "igst_amount", headerName: "IGST Amount"},
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      renderCell: (params) => (
          <button
              className="text-red-600"
              onClick={() => handleDeleteRow(params.row.id)}
          >
              Delete
          </button>
      ),
    },
  ];

  const payment_columns = [
    { field: "payment_no", headerName: "Payment No", flex: 1 },
    { field: "payment_method", headerName: "Payment Method", flex: 1 },
    { field: "payment_status", headerName: "Payment Status", flex: 1 },
    { field: "payment_received_amount", headerName: "Received Amount", flex: 1 },
    { field: "payment_date", headerName: "Payment Date", flex: 1 },
    { field: "remarks", headerName: "Remarks", flex: 1 },
    {
        field: "actions",
        headerName: "Actions",
        sortable: false,
        renderCell: (params) => (
            <button
                className="text-red-600"
                onClick={() => handlePaymentDeleteRow(params.row.id)}
            >
                Delete
            </button>
        ),
    },
    ];


  const handleDeleteRow = (id) => {
    // Filter out the row with the matching id
    const updatedContracts = productDetails.filter(item => item.id !== id);

    // Update local storage and state
    localStorage.setItem('productDetails', JSON.stringify(updatedContracts));
    SetProductDetails(updatedContracts);
};

const handlePaymentDeleteRow = (id) => {
  // Filter out the row with the matching id
  const updatedContracts = payments.filter(item => item.id !== id);
  localStorage.setItem('payments', JSON.stringify(updatedContracts));
  setPayments(updatedContracts);
};

const paymentMethodOptions = [
  { value: 'NEFT', label: 'NEFT' },
  { value: 'RTGS', label: 'RTGS' },
  { value: 'Cheque', label: 'Cheque' },
];

const paymentStatusOptions = [
  { value: 'Paid', label: 'Paid' },
  { value: 'Partial Paid', label: 'Partial Paid' },
  { value: 'Pending', label: 'Pending' },
];

    
  return (
    <>
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        flexWrap="wrap"
      >
        <Header title="Order Loading PO" subtitle="welcome to you Team" />
      </Box>

      {/* <div className="text-right">   */}
      <div>
        <button
          className="border px-4 py-2 font-[700] text-white bg-blue-500 border-gray-300 rounded"
          onClick={handleBackButtonClick}  // Trigger back confirmation popup
        >
          Back
        </button>
      </div>
      <div className="max-w-[800px] mx-auto mt-8 pb-14 p-6 bg-[#f2f0f0] rounded shadow-md">
      <h2 className="text-2xl font-semibold mb-6">Order Loading PO {orderType === 'EndUser' ? 'EndUser Form' : orderType === 'ThirdParty' ? 'Third Party Form' : 'Form'}</h2>
      <form onSubmit={handleSubmit}>
    {/* User Type Field */}
    <div className="mb-4">
        <label htmlFor="user_type" className="block text-gray-700 text-sm font-bold mb-2">User Type</label>
        {/* <select
            id="user_type"
            name="user_type"
            value={orderType}
            onChange={(event) => {
              setUserType(event.target.value);
            }}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
        >
            <option value="">Select User Type</option>
            <option value="EndUser">EndUser</option>
            <option value="ThirdParty">ThirdParty</option>
        </select> */}
        <input
            type="text"
            id="user_type"
            name="user_type"
            value={formData.user_type}
            disabled
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
        />
    </div>

    {/* Order Loading Date Field */}
    <div className="mb-4">
        <label htmlFor="order_loading_date" className="block text-gray-700 text-sm font-bold mb-2">Order Loading Date</label>
        <input
            type="date"
            id="order_loading_date"
            name="order_loading_date"
            value={formData.order_loading_date}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
        />
    </div>

    <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" >Order Type</label>
        <select
          name="order_type"
          value={formData.order_type}
          onChange={handleOrderSelectChange}
          className="basic-single"
          
          >
          <option value="">Select...</option>
          <option value="New">New</option>
          <option value="Renewal">Renewal</option>
        </select>
      </div>

    {/* Branch Field */}
    {user.user_type}
    {user.user_type === 'Primaryadmin' ? (
      <div className="mb-4">
      <label htmlFor="branch" className="block text-gray-700 text-sm font-bold mb-2">Branch</label>
      <Select
          id="branch"
          name="branch"
          options={branchOptions}
          value={branchOptions[0]}  
          isDisabled={true} 
          className="basic-single"
          classNamePrefix="select"
          styles={{
              control: (base) => ({
                  ...base,
                  backgroundColor: 'lightgrey',  // Grey background for Primaryadmin
              }),
          }}
          placeholder="Mumbai"
      />
  </div>
      ) : (<>
        <div className="mb-4">
            <label htmlFor="branch" className="block text-gray-700 text-sm font-bold mb-2">Branch</label>
            <Select
                id="branch"
                name="branch"
                options={branchOptions}
                value={branchOptions.find(option => option.value === formData.branch)}
                onChange={(selectedOption) => handleBranch(selectedOption)}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select a Branch"
                isClearable={true} 
            />
        </div>
    </>
    )}
    {/* <div className="mb-4">
            <label htmlFor="branch" className="block text-gray-700 text-sm font-bold mb-2">Branch</label>
            <Select
                id="branch"
                name="branch"
                options={branchOptions}
                value={branchOptions.find(option => option.value === formData.branch)}
                onChange={(selectedOption) => handleBranch(selectedOption)}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select a Branch"
                isClearable={true} 
            />
        </div> */}

        <div className="mb-4">
        <label htmlFor="bd_person" className="block text-gray-700 text-sm font-bold mb-2">BD Person</label>
        <Select
              id="bd_person"
              name="bd_person"
              options={userOptions}
              value={userOptions.filter(option => formData.bd_person.includes(option.value))}
              onChange={(selectedOptions) => handleMultipleSelectChange(selectedOptions, { name: 'bd_person' })}
              className="basic-single"
              classNamePrefix="select"
              placeholder="Select a BD Person"
              required
              isClearable={true}
              isMulti={true}
            />

            {/* <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={userOptions}
                getOptionLabel={(option) => option.name}
                value={selectedBd_person}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Select BD Person" variant="outlined" />
                )}
                onChange={(event, newValue) => {
                  handleMultipleSelectChange(newValue, );
                }}
              /> */}
        </div>

        {/* Account Field */}
        <div className="mb-4">
            <label htmlFor="account" className="block text-gray-700 text-sm font-bold mb-2">Account</label>
            <Select
                id="account"
                name="account"
                options={accountOptions}
                // value={accountOptions.find(option => option.value === formData.account)}
                value={accountOptions.find(option => option.value === formData.account) || null}
                onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'account' })}
                className="basic-single"
                classNamePrefix="select"
                placeholder="Select an Account"
                isClearable={true} 
            />
        </div>

    {/* PO Number Field */}
    <div className="mb-4">
        <label htmlFor="po_number" className="block text-gray-700 text-sm font-bold mb-2">PO Number</label>
        <input
            type="text"
            id="po_number"
            name="po_number"
            value={formData.po_number}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
        />
    </div>

    {/* PO Date Field */}
    <div className="mb-4">
        <label htmlFor="po_date" className="block text-gray-700 text-sm font-bold mb-2">PO Date</label>
        <input
            type="date"
            id="po_date"
            name="po_date"
            value={formData.po_date}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
        />
    </div>

    {/* PO Copy Field */}
    <div className="mb-4">
        <label htmlFor="po_copy" className="block text-gray-700 text-sm font-bold mb-2">{orderType === 'EndUser' ? 'PO Copy' : orderType === 'ThirdParty' ? 'Third Party PO Copy' : 'Form'}</label>
        <input
            type="file"
            id="po_copy"
            name="po_copy"
            onChange={handleFileChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
    </div>

    { orderType === 'ThirdParty' ? (
      <>
      {/* End User PO Copy Field */}
      <div className="mb-4">
        <label htmlFor="client_po_copy" className="block text-gray-700 text-sm font-bold mb-2">Client PO Copy</label>
        <input
            type="file"
            id="client_po_copy"
            name="client_po_copy"
            onChange={handleFileChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
    </div>

    {/* Third Party Account Field */}
    <div className="mb-4">
        <label htmlFor="third_party_account" className="block text-gray-700 text-sm font-bold mb-2">Third Party Account</label>
        <Select
            id="third_party_account"
            name="third_party_account"
            options={thirdPartyAccountOptions}
            value={thirdPartyAccountOptions.find(option => option.value === formData.third_party_account)}
            onChange={(selectedOption) => handleSelectChange(selectedOption, { name: 'third_party_account' })}
            className="basic-single"
            classNamePrefix="select"
            placeholder="Select an Third Party Account"
            isClearable={true} 
        />
    </div>

      </>
    ):
    (<>
    </>)
    }
    
    {/* Billing Address Field */}
    <div className="mb-4">
        <label htmlFor="billing_address" className="block text-gray-700 text-sm font-bold mb-2">Billing Address</label>
        <textarea
            id="billing_address"
            name="billing_address"
            value={formData.billing_address}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
    </div>

    {/* Billing GST Number Field */}
    <div className="mb-4">
        <label htmlFor="billing_gst_number" className="block text-gray-700 text-sm font-bold mb-2">Billing GST Number</label>
        <input
            type="text"
            id="billing_gst_number"
            name="billing_gst_number"
            value={formData.billing_gst_number}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
    </div>

    {/* Shipping Address Field */}
    <div className="mb-4">
        <label htmlFor="shipping_address" className="block text-gray-700 text-sm font-bold mb-2">Shipping Address</label>
        <textarea
            id="shipping_address"
            name="shipping_address"
            value={formData.shipping_address}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
    </div>

    {/* Shipping GST Number Field */}
    <div className="mb-4">
        <label htmlFor="shipping_gst_number" className="block text-gray-700 text-sm font-bold mb-2">Shipping GST Number</label>
        <input
            type="text"
            id="shipping_gst_number"
            name="shipping_gst_number"
            value={formData.shipping_gst_number}
            onChange={handleChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
        />
    </div>

    <div className="mb-4">
      <label htmlFor="product_category" className="block text-gray-700 text-sm font-bold mb-2">
        Product Details
      </label>
      <div className="flex">
        {/* <Select
          id="product_details"
          name="product_details"
          options={projectDetailoptions}
          isMulti
          value={projectDetailoptions?.filter(option => formData.product_details.includes(option.value))}
          onChange={handleProductDetailsChange}
          className="w-full"
          classNamePrefix="select"
          placeholder="Select Product Details"
        /> */}
        <div onClick={() => ShowProductDetails()} style={{ cursor: 'pointer' }}>
          <AddIcon />  <PreviewIcon />
        </div>
      </div>
    </div>



    <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" >License Contract Manager Name</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="text"
          name="license_contract_manager_name"
          value={formData.license_contract_manager_name}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" >License Contract Manager Phone</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="tel"
          name="license_contract_manager_phone"
          value={formData.license_contract_manager_phone}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" >License Contract Manager Email ID</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="email"
          name="license_contract_manager_email_id"
          value={formData.license_contract_manager_email_id}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4">
        <label className="block text-gray-700 text-sm font-bold mb-2" >Proposed License Activation Date</label>
        <select
          name="proposed_license_activation_date"
          value={formData.proposed_license_activation_date}
          onChange={handleSelectLicenceChange}
          
          >
          <option value="">Select...</option>
          <option value="Immediate">Immediate</option>
          <option value="Particular Date">Particular Date</option>
        </select>
      </div>

      {/* <div className="mb-4"{(formData.proposed_license_activation_date === "Immediate") ? <></> : disabled}>  */}
      {/* <div className="mb-4"> */}
      {showLicenceDate === "Particular Date" ? 
      (<>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2">Proposed Order Loading Date</label>
          <input
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            type="date"
            name="proposed_order_loading_date"
            value={formData.proposed_order_loading_date}
            onChange={handleChange}
            // disabled={showLicenceDate !== "Particular Date"}  // Disable unless 'Particular Date' is selected
          />
        </div>
      </>) :
      <></>
      }

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Manual CIF Form</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="file"
          name="manual_cif_form"
          onChange={handleFileChange}
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Selling Payment Terms</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="number"
          name="selling_payment_terms"
          value={formData.selling_payment_terms}
          onChange={handleChange}
        />
      </div>

      <div className="mb-4"> 
        <label className="block text-gray-700 text-sm font-bold mb-2" >Remarks</label>
        <input
          className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
          type="text"
          name="remarks"
          value={formData.remarks}
          onChange={handleChange}
        />
      </div>

    {/* Submit Button */}
    <div className="mb-4">
                <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`bg-blue-500 text-white px-4 py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 ${
                        isSubmitting ? "opacity-50 cursor-not-allowed" : "hover:bg-blue-700"
                    }`}
                >
                    {isSubmitting ? "Submitting..." : "Submit"}
                </button>
            </div>
        </form>

    </div>
    </Box>

    <Modal
      open={openProductDetails}
      onClose={() => setOpenProductDetails(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ backgroundColor: "red" }}
    >
      <div className="relative -translate-x-2/4 bg-white h-[850px] max-w-[600px] w-full rounded-lg shadow-lg p-4 left-2/4 top-[18%] translate-y-[-18%]">
        <div className="absolute top-2 right-3 cursor-pointer">
          <MdClose
            className="cursor-pointer text-xl"
            onClick={() => { setOpenProductDetails(false);
            resetForm();
            }}
          />
        </div>
        <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
          Add Product Details
        </h3>
        <Box sx={{ paddingY: '30px' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Product Master:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
            <Box display="flex" alignItems="center">
            
              <Select
                id="product_master"
                name="product_master"
                options={projectMasteroptions}
                value={projectMasteroptions.find(option => option.value === productMaster)} 
                onChange={(selectedOption) => {
                  handleProjectMaster(selectedOption.value, selectedOption.label);
                  // setProductMaster(selectedOption.value);
                }}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Select a Product Master"
                isClearable={true} 
              />
                <div onClick={handleNavigation} style={{ cursor: 'pointer' }}>
                      <AddIcon /> 
                    </div>
              </Box>
              <div onClick={GetAllProjectMaster} style={{ cursor: 'pointer' }}>
                  <RefreshIcon /> 
              </div>
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Quantity:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <input
                type="number"
                id="quantity"
                name="quantity"
                value={quantity}
                onChange={(event) => {
                  setQuantity(event.target.value);
                }}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter quantity"
              />
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Selling Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <input
                type="number"
                id="selling_amount"
                name="selling_amount"
                value={sellingAmount}
                // onChange={handleChange}
                onChange={(event) => {
                  setSellingAmount(event.target.value);
                  // calculateTotals(); // Calculate totals when selling amount changes
                }}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter selling amount"
              />
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Purchase Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <input
                type="number"
                id="purchase_amount"
                name="purchase_amount"
                value={purchaseAmount}
                onChange={(event) => {
                  setPurchaseAmount(event.target.value);
                  // calculateTotals();
                }}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter purchase amount"
              />
            </Grid>
          </Grid>
          <br />

          {/* Total Selling Amount */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Total Selling Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>
                {totalSellingAmount || 0} {/* Display calculated total */}
              </Typography>
            </Grid>
          </Grid>
          <br />
          
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Total Purchase Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>
                {totalPurchaseAmount || 0} {/* Display calculated total */}
              </Typography>
            </Grid>
          </Grid>
          <br />

          {/* Total ACV Amount */}
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Total ACV Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>
                {totalAcvAmount || 0} {/* Display calculated total */}
              </Typography>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>SGST Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
            <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>
                {sgstAmount || 0} {/* Display calculated total */}
              </Typography>
              {/* <input
                type="number"
                id="sgst_amount"
                name="sgst_amount"
                value={sgstAmount}
                // onChange={handleChange}
                onChange={(event) => {
                  setSGSTAmount(event.target.value);
                  // calculateTotals(); // Calculate totals when selling amount changes
                }}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter SGST amount"
              /> */}
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>CGST Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
            <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>
                {cgstAmount || 0} {/* Display calculated total */}
              </Typography>
              {/* <input
                type="number"
                id="cgst_amount"
                name="cgst_amount"
                value={cgstAmount}
                // onChange={handleChange}
                onChange={(event) => {
                  setCGSTAmount(event.target.value);
                  // calculateTotals(); // Calculate totals when selling amount changes
                }}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter CGST amount"
              /> */}
            </Grid>
          </Grid>
          <br />

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>IGST Amount:</Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
            <Typography sx={{ fontSize: '18px', fontWeight: 400 }}>
                {igstAmount || 0} {/* Display calculated total */}
              </Typography>
              {/* <input
                type="number"
                id="igst_amount"
                name="igst_amount"
                value={igstAmount}
                // onChange={handleChange}
                onChange={(event) => {
                  setIGSTAmount(event.target.value);
                  // calculateTotals(); // Calculate totals when selling amount changes
                }}
                className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
                placeholder="Enter IGST amount"
              /> */}
            </Grid>
          </Grid>
          <br />

          <div className="max-w-[447px] w-full text-right mt-4">
            <button
              className="border px-4 py-2 font-bold text-white bg-blue-500 rounded"
              onClick={handleAddProductDetails}

            >
              Add
            </button>
          </div>
        </Box>
      </div>
    </Modal>
    <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            classes={{ backgroundColor: "red" }}
          >
            <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[1150px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
              <div className="h-full">
              
                <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">
                    <>
                      
                        <DataGrid
                          rows={productDetails}
                          columns={product_master_local_storage}
                          getRowId={(row) => row.id} 
                          components={{ Toolbar: GridToolbar }}
                        />
                    </>
                </div>
                <div className="border-t">
                  <div className="pt-4 text-right">
                  <button
                      className="border px-2 py-1 font-[700] text-white bg-green-500 border-gray-300 rounded"
                      onClick={() => AddProductDetails()} style={{ cursor: 'pointer' }}
                    >
                      Add
                    </button>
                    <button
                      className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                      onClick={() => {
                        setOpen(false);
                        // SetselectedAccount(null);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
    </Modal>

    <Modal
            open={paymentopen}
            onClose={() => setPaymentOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            classes={{ backgroundColor: "red" }}
          >
            <div className="absolute -translate-x-2/4 bg-white h-[636px] max-w-[1150px] w-full  rounded-lg shadow-[24] p-4 left-2/4 top-[18%] translate-y-[-18%]">
              <div className="h-full">
              
                <div className="p-4 my-4 h-[calc(100%_-_117px)] overflow-auto">
                    <>
                      
                        <DataGrid
                          rows={payments}
                          columns={payment_columns}
                          getRowId={(row) => row.id} 
                          components={{ Toolbar: GridToolbar }}
                        />
                    </>
                </div>
                <div className="border-t">
                  <div className="pt-4 text-right">
                  <button
                      className="border px-2 py-1 font-[700] text-white bg-green-500 border-gray-300 rounded"
                      onClick={() => AddPaymentDetails()} style={{ cursor: 'pointer' }}
                    >
                      Add
                    </button>
                    <button
                      className="border px-2 py-1 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                      onClick={() => {
                        setPaymentOpen(false);
                        // SetselectedAccount(null);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
    </Modal>

    <Modal
      open={openPaymentDetails}
      onClose={() => setOpenPaymentDetails(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      classes={{ backgroundColor: "red" }}
    >
      <div className="relative -translate-x-2/4 bg-white h-[670px] max-w-[600px] w-full rounded-lg shadow-lg p-4 left-2/4 top-[18%] translate-y-[-18%]">
        <div className="absolute top-2 right-3 cursor-pointer">
          <MdClose
            className="cursor-pointer text-xl"
            onClick={() => { setOpenPaymentDetails(false);
            resetPaymentForm();
            }}
          />
        </div>
        <h3 className="text-base font-semibold leading-6 border-b pb-3 text-gray-900">
          Add Payment Details
        </h3>
        <Box sx={{ paddingY: '30px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Payment No:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        id="payment_no"
                        name="payment_no"
                        value={paymentNo}
                        onChange={(event) => setPaymentNo(event.target.value)}
                        className="w-full"
                        placeholder="Enter payment number"
                    />
                </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Payment Method:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                <Select
                    id="payment_method"
                    name="payment_method"
                    options={paymentMethodOptions} // Array of payment method options
                    value={paymentMethodOptions.find(option => option.value === formData.payment_method)} // Selected payment method
                    onChange={(selectedOption) => setPaymentMethod(selectedOption ? selectedOption.value : '')}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select a Payment Method"
                    required
                    isClearable={true} 
                  />
                </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Payment Status:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                <Select
                    id="payment_status"
                    name="payment_status"
                    options={paymentStatusOptions} // Array of payment status options
                    value={paymentStatusOptions.find(option => option.value === formData.payment_status)} // Selected payment status
                    onChange={(selectedOption) => setPaymentStatus(selectedOption ? selectedOption.value : '')}
                    className="basic-single"
                    classNamePrefix="select"
                    placeholder="Select a Payment Status"
                    required
                    isClearable={true} 
                  />
                 </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Payment Received Amount:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        type="number"
                        id="payment_received_amount"
                        name="payment_received_amount"
                        value={paymentReceivedAmount}
                        onChange={(event) => setPaymentReceivedAmount(event.target.value)}
                        className="w-full"
                        placeholder="Enter received amount"
                    />
                </Grid>
            </Grid>
            <br />

            <Grid container spacing={2}>
                <Grid item xs={12} sm={3}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600 }}>Payment Date:</Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                    <TextField
                        type="date"
                        id="payment_date"
                        name="payment_date"
                        value={paymentDate}
                        onChange={(event) => setPaymentDate(event.target.value)}
                        className="w-full"
                        placeholder="Enter payment date"
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
            </Grid>
            <br />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                  <Typography sx={{ fontSize: '18px', fontWeight: 600 }}> Remarks:</Typography>
              </Grid>
              <Grid item xs={12} sm={9}>
                  <TextField
                      id="payment_remarks"
                      name="payment_remarks"
                      value={paymentRemarks}
                      onChange={(event) => setPaymentRemarks(event.target.value)}
                      className="w-full"
                      placeholder="Enter payment remarks"
                  />
              </Grid>
            </Grid>
            <br />

            <div className="max-w-[447px] w-full text-right mt-4">
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddPaymentDetail}
                >
                    Add
                </Button>
            </div>
        </Box>
      </div>
    </Modal>

    <Modal
        open={accountPopup}
        onClose={() => showAccountPopup(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="absolute -translate-x-2/4 bg-white h-[300px] max-w-[400px] w-full rounded-lg shadow-[24] p-4 left-2/4 top-[50%] translate-y-[-50%]">
          <div className="h-full flex flex-col justify-between">
            <h2 id="modal-modal-title" className="text-xl font-bold mb-4">Confirm Account Selection</h2>
            <p id="modal-modal-description">Do you confirm that Account CSN is selected correctly?</p>
            <div className="flex justify-end mt-4">
              <button
                className="border px-4 py-2 font-[700] text-white bg-green-500 border-gray-300 rounded mr-2"
                onClick={() => handleConfirmAccount(true)}
              >
                Yes
              </button>
              <button
                className="border px-4 py-2 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                onClick={() => handleConfirmAccount(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
    </Modal>

    <Modal
        open={backPopup}
        onClose={() => showBackPopup(false)}
        aria-labelledby="modal-back-title"
        aria-describedby="modal-back-description"
      >
        <div className="absolute -translate-x-2/4 bg-white h-[300px] max-w-[400px] w-full rounded-lg shadow-[24] p-4 left-2/4 top-[50%] translate-y-[-50%]">
          <div className="h-full flex flex-col justify-between">
            <h2 id="modal-back-title" className="text-xl font-bold mb-4">Confirm Navigation</h2>
            <p id="modal-back-description">Are you sure you want to go back? Any unsaved changes will be lost.</p>
            <div className="flex justify-end mt-4">
              <button
                className="border px-4 py-2 font-[700] text-white bg-green-500 border-gray-300 rounded mr-2"
                onClick={() => handleConfirmBack(true)}
              >
                Yes
              </button>
              <button
                className="border px-4 py-2 font-[700] text-white bg-gray-500 border-gray-300 rounded"
                onClick={() => handleConfirmBack(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
    </Modal>

    </>
  );
};

export default AddOrderLoadingPO;


