import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";


const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [userData, SetUserData] = useState([]);
  const [loading, SetLoading] = useState(true);
  const [error, setError] = useState(null);

  const {token} = useUser()

  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token){
    GetUserData();
    }
  }, [token]);


  const GetUserData = () => {
      axios.get(BACKEND_BASE_URL + `v1/get_user/`, {headers})
      .then((response) => {
        if (response.status === 200) {
          SetUserData(response?.data?.User);
          SetLoading(false);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }else {
            toast.error("Error occured while displaying accounts.", {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        })
      .catch(() => {
        setError(error?.response.data.Response.message);
        SetLoading(false);
        toast.error("Error occured while displaying accounts.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const getRowId = (row) => row.id;

  const columns = [
    { field: "id", headerName: "Id" },
    { field: "email", headerName: "Email", width: 200 },
    { field: "first_name", headerName: "First Name" },
    { field: "last_name", headerName: "Last Name" },
    { field: "phone", headerName: "Phone Number", width: 150 },
    { field: "designation", headerName: "Designation", width: 150 },
    {
      field: "user_type",
      headerName: "Access Llvel",
      width: 100,
      renderCell: ({ row: { user_type } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              user_type === "Admin"
                ? colors.greenAccent[600]
                : colors.greenAccent[800]
            }
            borderRadius="4px"
          >
            {user_type === "Admin" && <AdminPanelSettingsOutlinedIcon />}
            {user_type === "Superuser" && <SecurityOutlinedIcon />}
            {user_type === "Primaryadmin" && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {user_type}
            </Typography>
          </Box>
        );
      },
    },
    { 
      field: "last_login", 
      headerName: "Last Login Details", 
      width: 300,
      renderCell: ({ value }) => {
        const formattedDateTime = new Date(value).toLocaleString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
        return <Typography>{formattedDateTime}</Typography>;
      },
    },

  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="TEAM" subtitle="welcome to you Team" />
      </Box>
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
         <DataGrid rows={userData} columns={columns} getRowId={getRowId} />
      </Box>
    </Box>
  );
};

export default Team;
