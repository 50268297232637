import React from 'react'

const Loader = () => {
  return (
    <div className='fixed inset-0 bg-black bg-opacity-40 z-[1300] w-full h-full overflow-hidden flex justify-center items-center'>
        <div>
            <div className='w-14 h-14 animate-[spinner-0tkp9a_1s_infinite] rounded-[50%] border-[9px] border-solid border-[#ffffff_#0000]'></div>
        </div>
    </div>
  )
}

export default Loader