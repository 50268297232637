import React, { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { useUser } from "../../components/UserProvider.js";
import { Box } from "@mui/material";
import Header from "../../components/Header.jsx";
import ViewModal from "../../components/ViewModal/ViewModal.js";

const Templates = () => {
  const [originalContent, setOriginalContent] = useState("");
  const [editedContent, setEditedContent] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const { token, user } = useUser();

  useEffect(() => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    if (token) {
      axios
        .get(BACKEND_BASE_URL + `v1/api/get_template/90`, { headers })
        .then((response) => {
          setOriginalContent(response.data.content);
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  }, [token]);

  const handleContentChange = (event) => {
    setEditedContent(event.target.value);
  };

  const handleSubmit = () => {
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    axios
      .put(
        BACKEND_BASE_URL + `v1/api/templates/`,
        { content: editedContent },
        { headers }
      )
      .then((response) => {
        console.log("Template content updated successfully");
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const handleView = () => {
    setOpen(!open);
  };

  const closeModal = () => { 
    setOpen(false)
   }

  return (
    <>
      <Box m="20px">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Header title="Templates View" />
        </Box>
        <div className="mx-auto mt-30 p-6 max-w-[1000px] w-full bg-[#f2f0f0] rounded shadow-md">
          <div className="mb-4">
            <label
              htmlFor="message"
              className="block text-gray-700 text-sm font-bold mb-2"
            >
              Template
            </label>
            <textarea
              name="message"
              rows={20}
              className="w-full outline-none rounded-md"
              value={editedContent || originalContent}
              onChange={handleContentChange}
            />
          </div>
          <div className="flex items-center justify-end">
            <button
              type="button"

              className={`bg-${
                error ? "gray-300" : "blue-500"
              } text-white px-4 mr-3 py-2 rounded-md ${
                error
                  ? "cursor-not-allowed"
                  : "hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              }`}
              disabled={!!error}
              onClick={handleSubmit}
            >
              Submit
            </button>
            <button
              type="button"
              className={`bg-${
                error ? "gray-300" : "blue-500"
              } text-white px-4 py-2 rounded-md ${
                error
                  ? "cursor-not-allowed"
                  : "hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
              }`}
              disabled={!!error}
              onClick={handleView}
            >
              View
            </button>
          </div>
        </div>
      </Box>
      <ViewModal data={originalContent} open={open} closeModal={closeModal} />
    </>
  );
};

export default Templates;
