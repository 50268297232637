import React, { useEffect, useState } from "react";
import { BACKEND_BASE_URL } from "../../components/config/apiEnv.js";
import { DataGrid, GridToolbar} from "@mui/x-data-grid";
import { tokens } from "../../theme.js";
import { mockDataTeam } from "../../data/mockData.js";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import { useUser } from "../../components/UserProvider.js";
import { } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import { Box, Typography, useTheme, MenuItem, FormControl, Select, InputLabel } from "@mui/material";


const UploadProductMaster = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate()
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);


  const {token, user} = useUser()
  const [orderType, setOrderType] = useState("");

  const [file, setFile] = useState(null);
  const [allCategory, SetAllCategory] = useState([]);
  const [allUOM, SetAllUOM] = useState([]);
  const [allStatus, SetAllStatus] = useState([]);
  const [allOEM, SetAllOEM] = useState([]);
  const [allGSTType, SetAllGSTType] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedUOM, setSelectedUOM] = useState("");
  const [selectedOEM, setSelectedOEM] = useState("");
  const [selectedGSTType, setSelectedGSTType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");


  const headers = {
    Authorization: `Bearer ${token}`,
  };

  useEffect(() => {
    if (token) {
      fetchData('product_master_category', SetAllCategory);
      fetchData('product_master_uom', SetAllUOM);
      fetchData('product_master_oem', SetAllOEM);
      fetchData('product_master_gst_type', SetAllGSTType);
    }
  }, [token]);
  
  const fetchData = (endpoint, setData) => {
    axios
      .get(`${BACKEND_BASE_URL}v1/product/${endpoint}/`, { headers })
      .then((response) => {
        console.log(`🚀 ~ fetchData ~ ${endpoint} response:`, response);
        if (response.status === 200) {
          setData(response?.data);
          setError(null);
          toast.success("Data Loaded", {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          handleApiError("Error occurred while fetching data.");
        }
      })
      .catch((error) => {
        handleApiError(error?.response?.data?.Response?.message);
      });
  };
  
  const handleApiError = (message) => {
    setError(message || "An error occurred.");
    setIsLoading(false);
    toast.error(message || "Error occurred while displaying accounts.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };



  useEffect(() => {
    if (token){
    }
  }, [token]);


  const getRowId = (row) => row.id;

  const currDate = new Date().toLocaleDateString();
  const currTime = new Date().toLocaleTimeString();

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "text/csv") {
      setFile(selectedFile);
    } else {
      toast.error("Please upload a valid CSV file.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFile(null); // Reset the file state if the type is incorrect
    }
  };

  const handleDownloadCSV = async () => {
    try {
      const response = await axios.get(BACKEND_BASE_URL + `v1/product/download_product_master_csv_format/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob', // Important for file downloads
      });
  
      // Create a URL for the CSV file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'product_master_format.csv'); // Filename
      document.body.appendChild(link);
      link.click();
      toast.success("CSV format downloaded successfully!");
    } catch (error) {
      console.error("Error downloading CSV", error);
      toast.error("Failed to download CSV");
    }
  };
  
  

  const handleSubmit = async (e) => {
    setIsSubmitting(true);
    e.preventDefault();
    if (!file) {
      toast.error("Please select a CSV file to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("csv_file", file);
    formData.append("product_category", selectedCategory);
    formData.append("uom", selectedUOM);
    formData.append("oem", selectedOEM);
    formData.append("gst_type", selectedGSTType);

    setIsLoading(true);

    try {
      const response = await axios.post(
        `${BACKEND_BASE_URL}v1/product/upload_product_master/`,
        formData,
        {
          headers: {
            ...headers,
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        console.log("🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀🚀 ~ handleSubmit ~ response:", response)
        toast.success(response?.data?.message);
        resetForm();
        // Optionally reset the form or navigate to another page
      }
    } catch (error) {
      handleApiError(error?.response?.data?.Response?.message || "Failed to upload products.");
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };


  const resetForm = () => {
    setFile(null);
    setSelectedCategory("");
    setSelectedUOM("");
    setSelectedOEM("");
    setSelectedGSTType("");
    setSelectedStatus(""); // Include if you have a status field
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="Upload Product Master" subtitle="we are in the same team" />
      </Box>
      <Box sx={{display:"flex"  , gap:'10px' , justifyContent:'end'}}>
      <Box display="flex" marginBottom={2} justifyContent="end" alignItems="center">
          <button
            onClick={handleDownloadCSV}
            className="action-button bg-[#8dbe86] text-black px-3 py-1 rounded h-[52px] text-center text-lg"
            style={{ whiteSpace: 'nowrap' }}
          >
            Download CSV Format
          </button>       
        </Box>
        </Box>
        
      
      <Box
        m="8px 0 0 0"
        height="80vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >

    <div className="max-w-[800px] mx-auto mt-8 pb-14 p-6 bg-[#f2f0f0] rounded shadow-md">
    <h2 className="text-2xl font-semibold mb-6">Bulk Product Master Form</h2>
    <form onSubmit={handleSubmit}>
        {error && (
        <div style={{ color: 'red', marginTop: '8px' }}>
            {error}
        </div>
        )}
        
        <div className="mb-4">
        <label
            htmlFor="csv_file"
            className="block text-gray-700 text-sm font-bold mb-2"
        >
            CSV File
        </label>
        <input
            type="file"
            id="csv_file"
            accept=".csv"
            onChange={handleFileChange}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
        />
        </div>

        <div className="mb-4">
        <label
            htmlFor="product_category"
            className="block text-gray-700 text-sm font-bold mb-2"
        >
            Product Category
        </label>
        <div className="flex">
            <select
            id="product_category"
            name="product_category"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
            >
             <option value="">Select Category</option>
            {allCategory?.map((category) => (
                <option key={category.id} value={category.id}>{category.name}</option>
            ))}
            </select>
            {/* <div onClick={() => AddCategory("category")} style={{ cursor: 'pointer' }}>
            <AddIcon />
            </div>  */}
        </div>
        </div>

        <div className="mb-4">
        <label
            htmlFor="uom"
            className="block text-gray-700 text-sm font-bold mb-2"
        >
            UOM
        </label>
        <div className="flex">
            <select
            id="uom"
            name="uom"
            value={selectedUOM}
            onChange={(e) => setSelectedUOM(e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
            >
            <option value="">Select UOM</option>
            {allUOM?.map((uom) => (
                <option key={uom.id} value={uom.id}>{uom.name}</option>
            ))}
            </select>
            
        </div>
        </div>

        <div className="mb-4">
        <label
            htmlFor="oem"
            className="block text-gray-700 text-sm font-bold mb-2"
        >
            OEM
        </label>
        <div className="flex">
            <select
            id="oem"
            name="oem"
            value={selectedOEM}
            onChange={(e) => setSelectedOEM(e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
            >
            <option value="">Select OEM</option>
            {allOEM?.map((oem) => (
                <option key={oem.id} value={oem.id}>{oem.name}</option>
            ))}
            </select>
        
        </div>
        </div>

        <div className="mb-4">
        <label
            htmlFor="gst_type"
            className="block text-gray-700 text-sm font-bold mb-2"
        >
            GST Type
        </label>
        <div className="flex">
            <select
            id="gst_type"
            name="gst_type"
            value={selectedGSTType}
            onChange={(e) => setSelectedGSTType(e.target.value)}
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:border-blue-500"
            required
            >
            <option value="">Select GST Type</option>
            {allGSTType?.map((gst) => (
                <option key={gst.id} value={gst.id}>{gst.name}</option>
            ))}
            </select>
            
        </div>
        </div>

        <button
        type="submit"
        disabled={isSubmitting}
        className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          {isSubmitting ? "Updating..." : "Upload Bulk"}
        
        </button>
    </form>
    </div>


      </Box>
    </Box>
  );
};

export default UploadProductMaster;
